import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js/dist/tween.umd";

import ApiModel from "../Models/ApiModel";
import Platform from "./Platform";

const PEOPLECOLOR = 0xcccccc;
const IMAGEREGEX = /(\.jpg|\.jpeg|\.png)$/i;
const VIDEOREGEX = /(\.mp4)$/i;
const FLOORBASEREGEX = /\d?.?place.*base.*/g;

let experience = null;
let scene = null;
let project = null;
let resources = null;
let camera = null;

export default class Floor extends ApiModel {
  constructor({ exhibition }) {
    super();

    ({ experience } = exhibition);
    ({ scene } = experience);
    ({ project } = experience);
    ({ camera } = experience);
    ({ resources } = experience);

    this.exhibition = exhibition;
    this.isReady = false;
    this.texture = null;
    this.platforms = [];
    this.labelRaycaster = new THREE.Raycaster();
    this.swipeEnable = true;

    if (this.isPlatform) {
      this.allPlatformsGroup = new THREE.Group();
      this.allPlatformsGroup.name = "allPlatformsGroup";
      scene.add(this.allPlatformsGroup);
    }

    if (!this.isStandaloneBoothType) {
      resources.loadFloorModel(this.floorModelFilePath).then(texture => {
          this.texture = texture;
          this.setModel();
          this.setLinearEncoding()
          // this.setPeople();
          this.setPeopleVisibility(
            project.get("config")?.arePeopleVisible || false
          );
          this.setBackwallVisual();
        });

    }
   
    if (!this.exhibition.editMode && !experience.isTouchEnabled && this.isPlatform) {
      this.exhibition.experience.container.addEventListener(
        "pointerdown",
        this.onPointerDown.bind(this)
      );
      this.exhibition.experience.container.addEventListener(
        "pointerup",
        this.onPointerUp.bind(this)
      );
    }

    if (!this.exhibition.editMode && experience.isTouchEnabled && this.isPlatform) {
      this.exhibition.experience.container.addEventListener(
        "touchstart",
        this.onTouchStart.bind(this)
      );
      this.exhibition.experience.container.addEventListener(
        "touchend",
        this.onTouchEnd.bind(this)
      );
    }
  }

  get floor() {
    return project.boothTemplate.floor;
  }

  get platformData() {
    return project.platforms;
  }

  get isPlatform() {
    return project.boothTemplate.type === "platform";
  }

  get isStandaloneBoothType() {
    return project.boothTemplate.name === "Standalone";
  }

  get floorModelFilePath() {
    return this.floor.floorModel;
  }

  get backwallVisual() {
    return project.backwallVisual;
  }

  get backwallScreen() {
    return this.model.getObjectByName("backwall_-_screen");
  }

  get visualType() {
    if (!this.backwallVisual) return "none";

    if (IMAGEREGEX.exec(this.backwallVisual.name)) return "image";
    else if (VIDEOREGEX.exec(this.backwallVisual.name)) return "video";
    else return "unsupported";
  }

  get activePlatforms() {
    return this.platforms.filter(p => p.platformData.isActive);
  }

  get platformCount() {
    return this.activePlatforms.length;
  }

  get platformRadius() {
    return experience.project.get("boothTemplate").get("floorRadius")[
      this.platformCount
    ];
  }

  get rotationToRightPlatform() {
    return (
      this.allPlatformsGroup.rotation.y +
      (2 * Math.PI) / this.activePlatforms.length
    );
  }

  get rotationToLeftPlatform() {
    return (
      this.allPlatformsGroup.rotation.y -
      (2 * Math.PI) / this.activePlatforms.length
    );
  }

  get people () {
    return this.model.getObjectByName("menschen");
  }

  onPointerDown(event) {
    this.startClientX = event.clientX;
  }

  onPointerUp(event) {
    let platformWorldRotation;
    if (
      this.swipeEnable &&
      Math.abs(this.startClientX - event.clientX) > 10 &&
      this.exhibition.zoomLevel === 1
    ) {
      this.exhibition.floor.platforms.forEach(platform => {
        platform.buttonLabel.scaleDown();
      });
      this.swipeEnable = false;
      if (this.startClientX < event.clientX) {
        platformWorldRotation = this.rotationToRightPlatform;
      } else {
        platformWorldRotation = this.rotationToLeftPlatform;
      }
      this.animateRotation(platformWorldRotation);
    }
  }

  onTouchStart(event) {
    this.startClientX = event.changedTouches[0].clientX;
  }

  onTouchEnd(event) {
    let platformWorldRotation;
    if (
      this.swipeEnable &&
      Math.abs(this.startClientX - event.changedTouches[0].clientX) > 10 &&
      this.exhibition.zoomLevel === 1
    ) {
      this.exhibition.floor.platforms.forEach(platform => {
        platform.buttonLabel.scaleDown();
      });
      this.swipeEnable = false;
      if (this.startClientX < event.changedTouches[0].clientX) {
        platformWorldRotation = this.rotationToRightPlatform;
      } else {
        platformWorldRotation = this.rotationToLeftPlatform;
      }
      this.animateRotation(platformWorldRotation);
    }
  }

  coverFlow(direction) {
    this.swipeEnable = false;
    this.exhibition.floor.platforms.forEach(platform => {
      platform.buttonLabel.scaleDown();
    });
    let platformWorldRotation;
    if (direction === "right") {
      platformWorldRotation = this.rotationToRightPlatform;
    } else {
      platformWorldRotation = this.rotationToLeftPlatform;
    }
    this.animateRotation(platformWorldRotation);
  }

  animateRotation(platformWorldRotation) {
    new TWEEN.Tween(this.allPlatformsGroup.rotation)
      .to({ y: platformWorldRotation }, 2000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        this.exhibition.trigger('coverflow-is-finish')
        this.swipeEnable = true;
        const currentPlatform = this.findNearestPlatform();
        currentPlatform.buttonLabel.scaleUp();
      })
      .start();
  }

  setModel() {
    const { position, rotationY, scaling } = this.floor;

    this.model = this.texture.scene;
    this.model.scale.set(scaling, scaling, scaling);
    this.model.rotation.y = rotationY;
    this.model.position.set(...position);
    this.model.name = "floor";
    
    this.setFloor();

    project.exhibits.forEach((e) => {
      e.addPlaceholder();
    })

    
    if (this.isPlatform) {
      // this.model.traverse(child => {
      //   if (child.material?.map?.image) {
      //     child.material.map.encoding = THREE.LinearEncoding;
      //   }
      // });

      this.createPlatforms();
      return;
    }
    scene.add(this.model);
  }

  setFloor() {
    this.model.renderOrder = 1;
    this.model.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.material.depthWrite = true;
        const match = child.name.match(FLOORBASEREGEX);
        if (match) {
          // child.receiveShadow = true;
          child.material.transparent = true;
          if(this.isPlatform){
           child.material.opacity = 0.95; 
          }else {
            child.material.opacity = 0.85; 
          }
          
        }
      }
    });
  }

  setLinearEncoding() {
    this.model.traverse((child) => {
      if (child.material?.map?.image) {
        child.material.map.colorSpace = THREE.SRGBColorSpace;
      }
    });
  }

  // setPeople() {
  //   this.people = this.model.getObjectByName("menschen");

  //   // if (this.people) {
  //   //   this.people.traverse(child => {
  //   //     child.castShadow = true;
  //   //     child.receiveShadow = true;
  //   //     child.material = new THREE.MeshStandardMaterial({
  //   //       color: PEOPLECOLOR,
  //   //     });
  //   //   });
  //   // }
  // }

  setPeopleVisibility(isVisible) {
    if (!this.people) return;
  
    this.people.visible = isVisible;
  }

  async setBackwallVisual() {
    if (!this.backwallScreen || this.visualType === "none") {
      this.triggerLoaded();
      return;
    }

    if (this.visualType === "unsupported") {
      console.error("Floor has invalid visual type.");
      return;
    }

    this.originalMaterial = this.backwallScreen?.material?.clone();

    if (this.visualType === "image") {
      this.setImageToBackwall();
    }

    if (this.visualType === "video") {
      this.setVideoToBackwall();
    }
  }

  setVideoToBackwall() {
    this.videoSrc = document.createElement("video");
    this.videoSrc.crossOrigin = "anonymous";
    this.videoSrc.muted = true;
    this.videoSrc.volume = "0";
    this.videoSrc.onloadedmetadata = "this.muted = true";
    this.videoSrc.autoplay = true;
    this.videoSrc.loop = true;
    this.videoSrc.playsinline = true;
    this.videoSrc.style = "display:none";
    this.videoSrc.src = this.api.getFileSource(this.backwallVisual.fileId);
    this.videoSrc.addEventListener("canplay", () => {
      const videoTexture = new THREE.VideoTexture(this.videoSrc);

      videoTexture.flipY = false;
      this.backwallScreen.material = new THREE.MeshBasicMaterial({
        map: videoTexture,
      });
      this.videoSrc.play();

      if (!this.isReady) {
        this.triggerLoaded();
      }
    });
  }

  async setImageToBackwall() {
    this.backwallVisualTexture = await resources.loadBackwallVisualTexture(
      this.backwallVisual.fileId
    );

    this.backwallVisualTexture.flipY = false;
    this.backwallScreen.material = new THREE.MeshBasicMaterial({
      map: this.backwallVisualTexture,
    });

    this.triggerLoaded();
  }

  createPlatforms() {
    this.platformData.forEach(platform => {
      const platformObject = new Platform(platform, this.exhibition);
      this.platforms.push(platformObject);
    });
    let activePlatform = this.platforms[0];

    setTimeout(() => {
      this.addFamilyToGroup();
    }, 1500);
  }

  addFamilyToGroup() {
    this.allPlatformsGroup.position.x = -this.platformRadius;
    this.allPlatformsGroup.rotation.y = 0;

    this.platforms.forEach(platform => {
      this.allPlatformsGroup.add(platform.platformGroup);
    });
    // experience.project.exhibits.forEach(exhibit => {
    //   if (exhibit.placeName.includes("central")) {
    //     this.allPlatformsGroup.add(exhibit.exhibitGroup);
    //   }
    // });
    this.managePlatformLabels();
  }

  rotateToPlatform(clickedPlatform) {
    if (this.exhibition.editMode) {
      let factor;
      this.activePlatforms.forEach((element, idx) => {
        element.buttonLabel.scaleDown();
        if (element.platformData.name === clickedPlatform.platformData.name) {
          factor = idx;
        }
      });

      const platformWorldRotation =
        factor * ((2 * Math.PI) / this.activePlatforms.length);

      if (platformWorldRotation > 0) {
        const currentRotation = this.allPlatformsGroup.rotation.y;
        const steps =
          (currentRotation - platformWorldRotation) /
          ((2 * Math.PI) / this.activePlatforms.length);
        const duration = Math.abs(parseInt(steps)) * 1000 + 1000;
        new TWEEN.Tween(this.allPlatformsGroup.rotation)
          .to({ y: platformWorldRotation }, duration)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onComplete(() => {
            clickedPlatform.manageZoom();
          })
          .start();
      } else {
        clickedPlatform.manageZoom();
      }
    } else {
      clickedPlatform.manageZoom();
    }
  }

  movePlacholder(exhibit, object, position) {
    this.platforms.forEach(platform => {
      if (platform.platformData.name === exhibit.platformName) {
        exhibit.moveDown(object, position);
      }
    });
  }

  reset() {
    project.exhibits.forEach(exhibit => {
      exhibit.resetPlatformExhibit(this.exhibition.editMode);
    });

    this.platforms.forEach(p => {
      if (p.platformData.isActive) {
        p.reset();
      }
    });
  }

  resetAllPlatformGroup() {
    new TWEEN.Tween(this.allPlatformsGroup.rotation)
      .to({ y: 0 }, 2000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        const nPlatform = this.findNearestPlatform();
        nPlatform.buttonLabel.scaleUp();
      })
      .start();
  }

  managePlatformLabels() {
    const nPlatform = this.findOriginPlatform();
    this.activePlatforms.forEach(p => {
      p.buttonLabel.scaleDown();
    });
    nPlatform.buttonLabel.scaleUp();
  }

  findOriginPlatform() {
    const distances = [];
    const origin = new THREE.Vector3(0, 0, 0);
    let target = new THREE.Vector3()
    this.activePlatforms.forEach(p => {
      const distance = origin.distanceTo(p.platformGroup.getWorldPosition(target));
      distances.push({ distance, p });
    });

    const originDistance = Math.min(...distances.map(e => e.distance));
    const originPlatform = distances.find(e => e.distance === originDistance);
    return originPlatform.p;
  }

  findNearestPlatform() {
    const distances = [];
    let target = new THREE.Vector3()
    this.activePlatforms.forEach(p => {
      const distance = camera.instance.position.distanceTo(
        p.platformGroup.getWorldPosition(target)
      );
      distances.push({ distance, p });
    });
    const nearestDistance = Math.min(...distances.map(e => e.distance));
    const nearestPlatform = distances.find(e => e.distance === nearestDistance);
    return nearestPlatform.p;
  }

  triggerLoaded() {
    this.isReady = true;
    this.exhibition.trigger("asset-loaded");
  }

  removeBackwallVisual() {
    if (this.videoSrc) {
      this.videoSrc.remove();
    }
    this.backwallScreen.material.dispose();
    this.backwallScreen.material.copy(this.originalMaterial);
  }

  update() {}
}
