import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../../../shared/styles.scss";
import custom from "./hotspot-settings-tab.scss";

import Hotspot from "../../../../experience/Exhibition/Hotspot";

import "../hotspot-list/hotspot-list";
import "../hotspot-create/hotspot-create";
import "../../exhibit-settings/platform-list/platform-list";

import "@shs/ui-marcom/src/js/button";
import "@shs/ui-marcom/src/js/textfield";
import "@shs/ui-marcom/src/js/textarea";

class HotspotSettings extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      selectedPlatform: { type: Object, attribute: false },
      activeTab: { type: Object, attribute: false },
      newHotspotLabel: { type: String },
      newHotspotContent: { type: String },
      newHotspotEditingUrl: { type: String },
      newHotspotLink: { type: String },
      newHotspotType: { type: String },
      animationName: {type: String},
      newHotspotAutoCreateContentPage: { type: Boolean },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["setLoading", "showModal", "showAlert", "setExperienceControls"];
  }

  constructor() {
    super();
    this.selectedPlatform = "default";
    this.newHotspotAutoCreateContentPage = true;
  }

  async firstUpdated() {
    this.project.on("updated", () => {
      this.requestUpdate();
    });

    this.project
      .get("experience")
      .get("exhibition")
      .on("updated", () => {
        if (this.activeTab.isActive) {
          this.setExperienceControls(this.addHotspotControl);
        }

        if (
          this.project
            .get("experience")
            .get("exhibition")
            .get("zoomLevel") === 2
        ) {
          this.project
            .get("experience")
            .get("exhibition")
            .get("floor")
            .platforms.forEach(platform => {
              if (platform.isZoomed) {
                this.setSelectedPlatformName(platform);
              }
            });
        }
        this.setSelectedPlatformName();
        this.requestUpdate();
      });

    this.project
      .get("experience")
      .get("exhibition")
      .on("hotspot-clicked", hotspot => this.onHotspotClick(hotspot));
  }

  connectedCallback() {
    super.connectedCallback();
    this.setSelectedPlatformName();
    this.setExperienceControls(this.addHotspotControl);
    this.project.trigger("updated");
  }

  disconnectedCallback() {
    this.setExperienceControls(null);
    this.project.trigger("updated");

    super.disconnectedCallback();
  }

  get addHotspotControl() {
    if (
      this.project.boothTemplate.type === "platform" &&
      this.project
        .get("experience")
        .get("exhibition")
        .get("zoomLevel") === 2
    ) {
      return {
        center: html`
          <uimc-button
            icon="plus"
            primary
            .onClick=${() => {
              this.showModal(
                "Add a new hotspot",
                this.addHotspotDialog,
                "",
                "Position the hotspot",
                "Cancel",
                "large",
                () => {
                  this.addHotspotForPositioning();
                }
              );
            }}
            >Add new hotspot</uimc-button
          >
        `,
      };
    }

    if (this.project.boothTemplate.type !== "platform") {
      return {
        center: html`
          <uimc-button
            icon="plus"
            primary
            .onClick=${() => {
              this.showModal(
                "Add a new hotspot",
                this.addHotspotDialog,
                "",
                "Position the hotspot",
                "Cancel",
                "large",
                () => {
                  this.addHotspotForPositioning();
                }
              );
            }}
            >Add new hotspot</uimc-button
          >
        `,
      };
    }
    return null;
  }

  get addHotspotDialog() {
    return html`
      <hotspot-create
        base-path=${this.basePath}
        .project=${this.project}
        .zoomItem=${this.project
          .get("experience")
          .get("exhibition")
          .get("zoomItem")}
        zoomLevel=${this.project
          .get("experience")
          .get("exhibition")
          .get("zoomLevel")}
        .onSelect=${type => {
          this.newHotspotType = type;
        }}
        .onAnimationSelect=${name => {
          this.animationName = name;
        }}
        .onInput=${(key, value) => {
          switch (key) {
            case "label":
              this.newHotspotLabel = value;
              break;

            case "content":
              this.newHotspotContent = value;
              break;

            case "tooltip":
              this.newHotspotContent = value;
              break;

            case "link":
              this.newHotspotContent = value;
              break;

            case "editingUrl":
              this.newHotspotEditingUrl = value;
              break;

            case "autoCreateContentPage":
              this.newHotspotAutoCreateContentPage = value;
              break;

            default:
              break;
          }
        }}
      ></hotspot-create>
    `;
  }

  get hotspots() {
    const exhibition = this.project.get("experience").get("exhibition");

    if (
      this.project
        .get("experience")
        .get("exhibition")
        .get("zoomLevel") === 1
    ) {
      return exhibition.firstLevelHotspots;
    }

    return exhibition.secondLevelHotspots;
  }

  onHotspotClick(hotspot) {
    if (hotspot.get("contentType") === "zoom") {
      this.switchZoomLevel(hotspot, false);
    }
  }

  addHotspotForPositioning() {
    this.setExperienceControls(null);
    this.project.trigger("updated");

    const newHotspot = new Hotspot(
      {
        contentType: this.newHotspotType,
        labelValue: this.newHotspotLabel,
        content: this.newHotspotContent,
        animationName: this.animationName,
        editingUrl: this.newHotspotEditingUrl,
        level: this.project
          .get("experience")
          .get("exhibition")
          .get("zoomLevel"),
        position: [0, 0, 0],
        labelPosition: "right",
        nearestExhibitPlace: null,
      },
      null,
      this.project.get("experience"),
      this.newHotspotAutoCreateContentPage
    );

    newHotspot.createContainer();
    newHotspot.on("created", response => {
      this.showAlert(
        "Created",
        `Hotspot "${newHotspot.get("labelValue")}" has been created. ${
          response.message
        }`,
        response.type
      );

      this.setExperienceControls(this.addHotspotControl);
      newHotspot.get("exhibit").updateHotspots();
      this.project.trigger("updated");
    });

    this.newHotspotContent = null;
    this.newHotspotLabel = null;
    this.newHotspotType = null;
    this.animationName = null;
    this.newHotspotLink = null;
    this.newHotspotEditingUrl = null;
    this.newHotspotAutoCreateContentPage = true;
    // // TODO: created success event?
  }

  switchZoomLevel(hotspot, applyZoom = true) {
    let exhibit = null;
    if (hotspot) {
      exhibit = hotspot?.get("exhibit");
    }

    if (applyZoom) {
      this.project
        .get("experience")
        .get("exhibition")
        .switchLevel(exhibit);
    }

    this.project.trigger("updated");
  }

  setSelectedPlatformName(chosenPlatform) {
    if (chosenPlatform) {
      this.selectedPlatformName =
        chosenPlatform.platformData.hotspot.labelValue;
    } else {
      this.project
        .get("experience")
        .get("exhibition")
        .get("floor")
        .platforms.forEach(platform => {
          if (platform.platformData.name === this.selectedPlatform) {
            this.selectedPlatformName =
              platform.platformData.hotspot.labelValue;
          }
        });
    }
  }

  bubbleSelectedPlatformEvent(selectedPlatform) {
    const selectedPlatformEvent = new CustomEvent("selected-platform-event", {
      detail: selectedPlatform.detail,
    });

    this.dispatchEvent(selectedPlatformEvent);
  }

  handleBackToMainLevelClick() {
    this.project
      .get("experience")
      .get("exhibition")
      .switchLevel();
    this.bubbleResetSelectedPlatformEvent();
  }

  bubbleResetSelectedPlatformEvent() {
    const resetSelectedPlatformEvent = new CustomEvent(
      "reset-selected-platform-event",
      {}
    );

    this.dispatchEvent(resetSelectedPlatformEvent);
  }

  render() {
    return html`
      <div class="hotspot-settings">
        <!-- Show hotspots on overview zoom level -->
        ${this.project
          .get("experience")
          .get("exhibition")
          .get("zoomLevel") === 1 &&
        this.project.get("exhibits")?.length >= 2 &&
        this.project.boothTemplate.type !== "platform"
          ? html`
              <h4 class="margin-top-0">Main level</h4>
              <p>
                You can add hotspots to two levels. Currently you are at the
                main level. To add a hotspot use the orange button in the 3D
                view on the left hand side.
              </p>
            `
          : null}

        <!-- Show hotspots on detail zoom level -->
        ${this.project
          .get("experience")
          .get("exhibition")
          .get("zoomLevel") === 2 && this.project.get("exhibits")?.length >= 2
          ? html`
              <h4 class="margin-top-0">
                ${this.selectedPlatformName
                  ? html`
                      ${this.selectedPlatformName}
                    `
                  : html`
                      Second level
                    `}
              </h4>

              <p>
                You are on the zoom level of hotspot
                ${this.project
                  .get("experience")
                  .get("exhibition")
                  .get("zoomItem")
                  .get("labelValue")}.
                To add a hotspot on this level use the button in the 3D view on
                the left hand side.
              </p>
              ${this.project.boothTemplate.type !== "platform"
                ? html`
                    <uimc-button
                      class="display-block margin-bottom-1"
                      icon="arrow-left-small"
                      .onClick=${() => {
                        this.switchZoomLevel();
                      }}
                    >
                      Back to first level
                    </uimc-button>
                  `
                : html`
                    <uimc-button
                      class="display-block margin-bottom-1"
                      size="small"
                      icon="arrow-left-small"
                      @click=${() => {
                        this.handleBackToMainLevelClick();
                      }}
                      >Back to overview</uimc-button
                    >
                  `}
            `
          : null}
        ${this.hotspots.length || this.project.boothTemplate.type === "platform"
          ? html`
              <hotspot-list
                base-path=${this.basePath}
                .project=${this.project}
                .hotspots=${this.hotspots}
                .switchZoomLevel=${this.switchZoomLevel.bind(this)}
              ></hotspot-list>
            `
          : html`
              <p>
                <i>
                  No
                  ${this.project.get("exhibits")?.length >= 2
                    ? html`
                        level
                        ${this.project
                          .get("experience")
                          .get("exhibition")
                          .get("zoomLevel")}
                      `
                    : null}
                  hotspots configured.
                </i>
              </p>
            `}

        <!-- Platform template -->
        ${this.project.boothTemplate.type === "platform" &&
        this.project
          .get("experience")
          .get("exhibition")
          .get("zoomLevel") === 1
          ? html`
              <platform-list
                base-path="${this.basePath}"
                .project=${this.project}
                .activeTab=${this.activeTab}
                @selected-platform-event=${event =>
                  this.bubbleSelectedPlatformEvent(event)}
              ></platform-list>
            `
          : null}
      </div>
    `;
  }
}

customElements.define("hotspot-settings", HotspotSettings);
