import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";

import Api from "../../api/api-helper";

import EventEmitter from "./EventEmitter";

export default class Resources extends EventEmitter {
  constructor({ experience }) {
    super();

    // Options
    this.boothTemplate = experience.project.boothTemplate;
    this.exhibits = experience.project.exhibits;

    // Setup
    this.items = { exhibits: {} };
    this.font = null;

    this.setLoadingManager();
    this.setLoaders();

    this.loadObjects();
  }

  setLoadingManager() {
    this.loadingManager = new THREE.LoadingManager();

    this.loadingManager.onError = error => {
      // trigger error events
      // eslint-disable-next-line no-console
      console.error(error);
    };
  }

  setLoaders() {
    this.loaders = {};
    this.loaders.gltfLoader = new GLTFLoader(this.loadingManager);
    this.loaders.textureLoader = new THREE.TextureLoader(this.loadingManager);
    this.loaders.fontLoader = new FontLoader(this.loadingManager);
    this.loaders.svgLoader = new SVGLoader(this.loadingManager);
    this.loaders.rgbeLoader = new RGBELoader(this.loadingManager);
  }

  async loadObjects() {
    await this.loadFont();
    this.trigger("init");
  }

  async loadFloorModel(fileHash) {
    if (!fileHash) {
      return
    }
    return this.loaders.gltfLoader.loadAsync(Api.getFileSource(fileHash));
  }

  async loadTextureAlpha(fileHash) {
    if (!fileHash) {
      return
    }
    return this.loaders.textureLoader.loadAsync(Api.getFileSource(fileHash));
  }

  async loadBackwallVisualTexture(fileHash) {
    if (!fileHash) {
      return
    }
    return this.loaders.textureLoader.loadAsync(Api.getFileSource(fileHash));
  }

  async loadSVG(fileHash) {
    if (!fileHash) {
      return
    }
    return this.loaders.svgLoader.loadAsync(Api.getFileSource(fileHash));
  }

  async loadAOModel(model) {
    if (!model.model.aoModel) {
      return;
    }

    const resource = await this.loaders.gltfLoader.loadAsync(
      Api.getFileSource(model.model.aoModel)
    );
    return({ model: resource, name: model.name });
  }

  async loadExhibit(exhibit) {
    if (!exhibit.get("model")) {
      return;
    }
    const { model, textures } = exhibit.get("model");

    const resource = await this.loaders.gltfLoader.loadAsync(
      Api.getFileSource(model)
    );

    if (textures.length) {
      const alpha = textures.find(texture =>
        texture.filename.includes("alpha")
      );
      const color = textures.find(texture =>
        texture.filename.includes("color")
      );
      const textureAlpha = await this.loaders.textureLoader.loadAsync(
        Api.getFileSource(alpha.id)
      );
      const textureColor = await this.loaders.textureLoader.loadAsync(
        Api.getFileSource(color.id)
      );

      exhibit.set("textureAlpha", textureAlpha);
      exhibit.set("textureColor", textureColor);
    }

    exhibit.set("resource", resource);
    this.trigger("exhibit-loaded");
  }

  async loadKeyVisualTexture(fileHash) {
    if (!fileHash) {
      return
    }
    return this.loaders.textureLoader.loadAsync(Api.getFileSource(fileHash));
  }

  async loadFont() {
    this.font = await this.loaders.fontLoader.loadAsync(
      "assets/fonts/helvetiker_regular.typeface.json"
    );
    this.trigger("loaded", "font");
  }
  
  async loadRGBEImage() {
    this.rgbeImage = await this.loaders.rgbeLoader.loadAsync(
      Api.getFileSource(this.boothTemplate.staticAsset.iblImage)
    );
  }
}
