import { LitElement, html, css } from "lit-element";

import Label from "@shs/ui-marcom/src/js/label";

import shared from "../../shared/styles.scss";
import custom from "./custom-label.scss";

class CustomLabel extends Label {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }
}

customElements.define("custom-label", CustomLabel);
