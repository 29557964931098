import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js/dist/tween.umd";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'

const SIGNCOLOR = 0x02787d;
const OPACITY = 1;

let experience = null;
let camera = null;
let scene = null;
let font = null;
let project = null;

export default class Sign {
  constructor({ exhibit }) {
    ({ experience } = exhibit.exhibition);
    ({ camera } = experience);
    ({ scene } = experience);
    ({ project } = experience);
    ({ font } = experience.resources);
    this.exhibit = exhibit;
    this.createSign();
  }

  get isPlatform() {
    return project.boothTemplate.type === "platform";
  }

  createSign() {
    this.group = new THREE.Group();
    const boxMesh = this.createBoxMesh();
    const textMeshFront = this.createTextMesh([-35, -40, 75]);

    if (this.isPlatform) {
      const textMeshBack = this.createTextMesh([-35, -40, -76]);
      const textMeshLeft = this.createTextMesh([76, -40, 36]);
      textMeshLeft.rotation.y = Math.PI / 2;
      const textMeshRight = this.createTextMesh([-76, -40, -36]);
      textMeshRight.rotation.y = -Math.PI / 2;
      this.group.add(textMeshBack, textMeshLeft, textMeshRight);
    }
    const position = this.exhibit.get("position");
    const name = this.exhibit.get("name");
    const { signs } = project.get("boothTemplate");

    this.group.position.y = 75;
    this.group.rotation.y = 0;

    this.group.name = `${name}TargetHelper`;
    this.group.add(textMeshFront, boxMesh);

    this.exhibit.project.platforms.forEach(platform => {
      if (platform.name === this.exhibit.platformName && !platform.isActive) {
        this.setVisiblitity(false);
        this.group.scale.set(0, 0, 0);
      }
    });

    if (this.exhibit.model) {
      this.setVisiblitity(false);
      this.group.scale.set(0, 0, 0);
    }

    camera.on("controlsChanged", () => {
      this.group.rotation.y = camera.controls.getAzimuthalAngle();
    });
  }

  createBoxMesh() {
    const boxGeometry = new THREE.BoxGeometry(150, 150, 150);
    const boxMaterial = new THREE.MeshStandardMaterial({
      color: SIGNCOLOR,
      transparent: true,
      opacity: OPACITY,
    });
    const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);

    return boxMesh;
  }

  createTextMesh(position) {
    const textGeometry = new TextGeometry(
      `${this.exhibit.get("sign")}`,
      {
        font,
        size: 80,
        height: 0.8,
      }
    );
    const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
    const textMesh = new THREE.Mesh(textGeometry, textMaterial);
    textMesh.position.set(...position);

    return textMesh;
  }

  setVisiblitity(bool) {
    this.group.visible = bool;
  }

  scaleDown() {
    new TWEEN.Tween(this.group.scale)
      .to({ x: 0, y: 0, z: 0 }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  }

  scaleUp(delay) {
    new TWEEN.Tween(this.group.scale)
      .to({ x: 1, y: 1, z: 1 }, 1000)
      .delay(delay)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  }

  dispose() {
    this.group.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();

        for (const key in child.material) {
          const value = child.material[key];

          // Test if there is a dispose function
          if (value && typeof value.dispose === "function") {
            value.dispose();
          }
        }
      }
    });

    scene.remove(this.group);
  }
}
