import { html, unsafeCSS } from "lit";
import { classMap } from "lit/directives/class-map";
import { ifDefined } from "lit/directives/if-defined";
import Textfild from "@shs/ui-marcom/src/js/textfield";
import styles from "./custom-textfield.scss";

export default class CustomTextfield extends Textfild {
  static get styles() {
    return [super.styles, unsafeCSS(styles)];
  }

  static get properties() {
    return {
      ...super.properties,
      validate: {
        attribute: true,
        type: Boolean,
      },
      validation: {
        type: Boolean,
      },
      type: {
        attribute: true,
        type: String,
      },
      pattern: {
        attribute: true,
        type: String,
      },
      min: {
        attribute: true,
        type: Number,
      },
      max: {
        attribute: true,
        type: Number,
      },
      maxlength: {
        attribute: true,
        type: Number,
      },
      minlength: {
        attribute: true,
        type: Number,
      },
      required: {
        attribute: true,
        type: Boolean,
      },
      onInvalid: {
        attribute: false,
      },
    };
  }

  constructor() {
    super();
    this.validation = false;
    this.validate = false;
    this.type = "text";
    this.pattern = null;
    this.min = null;
    this.max = null;
    this.maxlength = null;
    this.minlength = null;
    this.required = false;
    this.onInvalid = () => {};
  }

  firstUpdated() {
    super.firstUpdated();
  }

  _onInput(event) {
    super._onInput(event);

    if (this.validate) {
      this.checkValidation();
    }
  }

  checkValidation() {
    this.validation = this.shadowRoot
      .querySelector(`#${this.id}`)
      .checkValidity();

    if (this.validation && this.value.length) {
      this.isInvalid = false;
      this.isValid = true;
      return;
    }

    if (!this.validation && this.value.length) {
      this.isInvalid = true;
      this.isValid = false;
      
      this.onInvalid();
      return;
    }

    this.isInvalid = null;
    this.isValid = null;
  }

  render() {
    return html`
      <div
        class=${classMap({
          textfield: true,
          "textfield-with-prefix": true,
          "is-active": this.isActive,
          "has-focus": this.hasFocus,
          "has-hint": this.hintText,
          "is-valid": this.isValid && this.isValid !== null,
          "is-invalid": this.isInvalid && this.isInvalid !== null,
          "is-disabled": this.isDisabled,
        })}
      >
        <input
          id=${this.id}
          type=${this.type}
          pattern=${ifDefined(this.pattern ? this.pattern : undefined)}
          min=${ifDefined(this.min ? this.min : undefined)}
          max=${ifDefined(this.max ? this.max : undefined)}
          maxlength=${ifDefined(this.maxlength ? this.maxlength : undefined)}
          minlength=${ifDefined(this.minlength ? this.minlength : undefined)}
          ?required=${this.required}
          class="textfield__input"
          @focus=${() => {
            this._onFocus();
          }}
          @blur=${() => {
            this._onBlur();
          }}
          @input=${event => this._onInput(event)}
          ?disabled=${this.isDisabled}
          placeholder=${ifDefined(this.placeholder)}
          value=${ifDefined(this.value)}
        />
        <label class="textfield__label" for=${this.id}> ${this.label} </label>
      </div>
      ${this.hintText
        ? html`
            <small class="textfield__hint"> ${this.hintText} </small>
          `
        : null}
    `;
  }
}

window.customElements.define("custom-textfield", CustomTextfield);
