import axios from "axios";
import { getHotspot, deleteHotspots } from "./hotspot.js";
import { createKeVisual } from "./keyvisual.js";

import Exhibit from "../experience/Exhibition/Exhibit.js";
import Hotspot from "../experience/Exhibition/Hotspot.js";

const selection = [
  "*",
  "hotspots.hotspots_id.*",
  "keyvisual.id",
  "keyvisual.name",
  "keyvisual.preview.id",
  "keyvisual.preview.filename_download",
  "keyvisual.file.id",
  "keyvisual.file.filename_download",
  "keyvisual.file.type",
  "model.*",
  "model.textures.directus_files_id.id",
  "model.textures.directus_files_id.filename_download",
];

async function getExhibit({ id, projectInstance }, url, options) {
  const exhibitUrl = new URL(`/items/exhibits/${id}`, url);
  exhibitUrl.searchParams.append("fields", selection.join(","));

  const res = await axios.get(exhibitUrl, options);
  const apiExhibit = res.data.data;

  const instance = new Exhibit(res.data.data, projectInstance);

  instance.set("hotspots", getHotspots(instance));
  instance.set("keyVisual", getKeyVisualObject(apiExhibit.keyvisual));

  return instance;
}

function getHotspots(exhibitInstance) {
  let hotspots = exhibitInstance.get("hotspots").map(h => h.hotspots_id);
  return hotspots.map(h => {
    return new Hotspot(getHotspot(h), exhibitInstance, null);
  });
}

async function updateExhibit(id, fields, url, options) {
  const requestUrl = new URL(`/items/exhibits/${id}`, url);

  const res = await axios.patch(requestUrl, fields, options);
  return res;
}

async function deleteExhibitsHotspot(hotspot, url, options) {
  const hotspotId = hotspot.get("id");

  const allHotspotIds = hotspot
    .get("exhibit")
    .get("hotspots")
    .map(h => h.id);

  const hotspotRes =
    hotspot.level === 1
      ? await deleteHotspots(allHotspotIds, url, options)
      : await deleteHotspots([hotspotId], url, options);

  return { hotspotRes };
}

async function addKeyVisual(exhibitId, keyVisualData, url, options) {
  let res = await createKeVisual(keyVisualData, url, options);
  const newKeyVisual = res.data.data;

  res = await updateExhibit(
    exhibitId,
    { keyvisual: newKeyVisual.id },
    url,
    options
  );

  return {
    id: newKeyVisual.id,
    name: newKeyVisual.name,
    fileId: newKeyVisual.file,
    previewId: newKeyVisual.preview,
    fileType: newKeyVisual.file.type,
  };
}

function getKeyVisualObject(apiKeyVisualObject) {
  if (!apiKeyVisualObject) return null;

  return {
    id: apiKeyVisualObject.id,
    name: apiKeyVisualObject.name,
    fileId: apiKeyVisualObject.file.id,
    previewId: apiKeyVisualObject.preview.id,
    fileType: apiKeyVisualObject.file.type,
  };
}

export {
  getExhibit,
  updateExhibit,
  addKeyVisual,
  deleteExhibitsHotspot,
  getKeyVisualObject,
};
