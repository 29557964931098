import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../shared/styles.scss";
import custom from "./app-header.scss";

import Api from "../../api/api-helper";

import "@shs/ui-marcom/src/js/menu";
import "@shs/ui-marcom/src/js/menu-item";
import "../../custom-uimc/custom-application-bar/custom-application-bar";
import "../../custom-uimc/custom-user-identifier/custom-user-identifier";

class AppHeader extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      currentUser: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.currentUser = null;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["currentUser", "showAlert"];
  }

  firstUpdated() {
    this.api = Api.getInstance();
  }

  get currentRoute() {
    return this.router?.lastRouteResolved().url;
  }

  /* eslint-disable camelcase */
  get user() {
    if (!this.currentUser?.first_name) {
      return null;
    }

    const { first_name, last_name } = this.currentUser;
    return {
      firstname: first_name || "Unknown",
      lastname: last_name || "Unknown",
      company: "Siemens Healthineers",
    };
  }

  async logout() {
    await this.api.logout();
    this.currentUser = null;
    this.router.navigate("user-login");
  }

  async changePassword() {
    this.router.navigate("change-password");
  }

  render() {
    return html`
      <header class="app-header">
        <custom-application-bar app-identifier="${SIMPLEX.APP_TITLE}">
          ${this.user
            ? html`
                <custom-user-identifier
                  slot="user-identifier"
                  .user=${this.user}
                >
                  <uimc-menu>
                    <uimc-menu-item @click=${() => this.changePassword()}
                      >Change password</uimc-menu-item
                    >
                    <uimc-menu-item @click=${() => this.logout()}
                      >Logout</uimc-menu-item
                    >
                  </uimc-menu>
                </custom-user-identifier>
              `
            : null}
        </custom-application-bar>
      </header>
    `;
  }
}

customElements.define("app-header", AppHeader);
