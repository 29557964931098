import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js/dist/tween.umd";

import ApiModel from "../Models/ApiModel";

import Sign from "./Sign";
import AnimationController from "../Utils/AnimationController";

import "./components/video-tag/video-tag";

const IMAGEREGEX = /(\.jpg|\.jpeg|\.png)$/i;
const VIDEOREGEX = /(\.mp4)$/i;

let experience = null;
let scene = null;
let resources = null;

export default class Exhibit extends ApiModel {
  constructor(exhibit, project) {
    super(exhibit);

    this.project = project;
    this.exhibition = null;
    this.isReady = false;
    this.aoModelResource = null;
  }

  get steleScreen() {
    return this.resource.scene.getObjectByName("stele-screen");
  }

  get visualType() {
    if (!this.keyVisual) {
      return null;
    }

    if (IMAGEREGEX.exec(this.keyVisual.name)) {
      return "image";
    }
    if (VIDEOREGEX.exec(this.keyVisual.name)) {
      return "video";
    }
    return "unsupported";
  }

  get isPlatform() {
    return this.project.boothTemplate.type === "platform";
  }

  get isStandaloneBoothType() {
    return this.project.boothTemplate.name === "Standalone";
  }

  get platformOrigin() {
    const currentPlatform = this.project.platforms.find(
      (platform) => this.platformName === platform.name
    );
    return currentPlatform.frontPosition;
  }

  get platformOfExhibit() {
    const platform = this.project.platforms.find(
      (p) => p.name === this.platformName
    );
    return platform;
  }

  async init({ exhibition }) {
    ({ experience } = exhibition);
    ({ scene } = experience);
    ({ resources } = experience);

    this.exhibition = exhibition;

    this.exhibitGroup = new THREE.Group();
    this.exhibitGroup.name = `placeholder-${this.exhibit.name}`;
    this.exhibitGroup.scale.set(1, 1, 1);
    this.exhibitGroup.position.set(...this.position);

    await resources.loadExhibit(this);

    if (
      !(this.sign instanceof Sign) &&
      experience.get("exhibition").get("editMode")
    ) {
      this.setSign();
    }
    this.setModelOnPlace();

    if (
      this.exhibit.placename.includes("Platform") &&
      this.exhibition.zoomLevel === 1 &&
      !this.exhibit.placename.includes("central")
    ) {
      this.setExhibitUnvisible();
    }
  }

  setModelOnPlace() {
    if (this.model) {
      this.setModel();
      this.setTextures();
      this.setKeyVisual();
      this.setShadow();
      this.setLinearEncoding();
    } else {
      this.triggerLoaded();
    }
  }

  setSign() {
    this.sign = new Sign({ exhibit: this });
    this.exhibitGroup.add(this.sign.group);
  }

  async setAoModel() {
    await resources.loadAOModel(this).then((aoModelData) => {
      if (!aoModelData) return;
      this.aoModelResource = aoModelData.model
      if (!this.aoModelResource) return;

      this.aoModelMetaData = this.model.meta.aoModel;
      this.aoModelResource.scene.position.set(...this.aoModelMetaData.position);
      if (this.isPlatform) { 
        this.aoModelResource.scene.position.y = this.aoModelMetaData.position[1] + 1.3;
      }
      this.aoModelResource.scene.rotation.y = this.rotationY;
      this.aoModelResource.scene.scale.set(...this.aoModelMetaData.scale);
      this.aoModelResource.scene.name = aoModelData.name + "-aoModelObject";

      this.aoModelResource.scene.traverse(child => {
        if (child instanceof THREE.Mesh) {
          child.material.depthWrite = false;
        }})

      this.aoModelResource.scene.renderOrder = 1;

      this.exhibitGroup.add(this.aoModelResource?.scene);
    });
  }

  setModel() {
    if (!this.resource) {
      return;
    }

    const { name } = this.model;
    this.resource.scene.rotation.y = this.rotationY;
    this.resource.scene.name = name;
    this.resource.scene.scale.set(
      this.model.scale,
      this.model.scale,
      this.model.scale
    );
    this.setDepthWrite();

    if (
      this.exhibition.zoomLevel === 1 &&
      this.exhibit.placename.includes("Platform") &&
      !this.exhibit.placename.includes("central")
    ) {
      this.exhibitGroup.visible = false;
    }

    if (this.isPlatform && !this.platformOfExhibit.isActive) {
      this.exhibitGroup.visible = false;
    }
    this.exhibitGroup.add(this.resource.scene);

    if (this.model.meta?.animations && this.resource.animations.length) { 
      this.animationController = []
      this.hasAnimation = true;
      this.resource.animations.map(a => {
        const acontroller = new AnimationController(
          this.resource,
          this.model.meta.animations[0]
        ); 
        acontroller.loopOnce();
        this.animationController.push(acontroller) 
      }) 
    }else {
      this.animationController = null
    }

    this.setAoModel().then(() => {
      if (this.isStandaloneBoothType) {
        scene.add(this.exhibitGroup);
      }
      this.triggerLoaded()
    });
  }

  addPlaceholder() {
    if (!this.exhibit.placename.includes("central")) {
      scene.add(this.exhibitGroup);
    } else {
      experience.exhibition.floor.allPlatformsGroup?.add(this.exhibitGroup);
    }
  }

  setRotation(angle) {
    this.exhibitGroup.rotation.y = angle;
    this.set("rotationY", angle);
  }

  setMovement(value) {
    let origin;
    if (this.isPlatform) {
      origin = new THREE.Vector3(
        this.platformOrigin[0] + 1,
        30,
        this.platformOrigin[2] + 1
      );
    } else {
      origin = new THREE.Vector3(0, 30, 0);
    }
    const { positionx: x, positiony: y, positionz: z } = this.exhibit;
    const exhibitposition = new THREE.Vector3(x, y, z);

    const newExhibitPosition = new THREE.Vector3();
    newExhibitPosition.lerpVectors(exhibitposition, origin, value / 400);
    const position = [newExhibitPosition.x, y, newExhibitPosition.z];
    this.exhibitGroup.position.set(...position);
    this.set("position", position);
  }

  setPositionXZ() {
    this.exhibitGroup.position.x = this.position[0];
    this.exhibitGroup.position.z = this.position[2];
  }

  setTextures() {
    const textureColor = this.get("textureColor");
    const textureAlpha = this.get("textureAlpha");

    if (!textureColor || !textureAlpha) {
      return;
    }

    const material = new THREE.MeshStandardMaterial({ transparent: true });
    const mesh = this.get("resource").scene.getObjectByName("stele-base");

    textureColor.flipY = false;
    textureAlpha.flipY = false;

    material.map = textureColor;
    material.alphaMap = textureAlpha;
    mesh.material = material;

    this.originalMaterial = this.steleScreen?.material?.clone();
  }

  setExhibitVisible() {
    if (this.model && this.placeName.includes("central")) {
      this.exhibitGroup.visible = true;
    } else if (this.sign.group) {
      this.sign.setVisiblitity(true);
      this.sign.scaleUp(0);
    }
  }

  setExhibitUnvisible() {
    if (this.model) {
      this.exhibitGroup.visible = false;
    }

    if (this.sign.group) {
      this.sign.scaleDown();
    }
  }

  async selectModel(model) {
    this.set("model", model);

    if (this.sign.group) {
      this.sign.scaleDown();
    }

    await resources.loadExhibit(this);
    this.setModelOnPlace();

    this.trigger("updated");
  }

  async setKeyVisual() {
    if (!this.visualType) {
      this.triggerLoaded();
      return;
    }
    if (this.visualType === "unsupported") {
      throw new Error("Exhibit has invalid visual type.");
    }

    if (this.visualType === "image") {
      this.keyVisualTexture = await resources.loadKeyVisualTexture(
        this.keyVisual.fileId
      );

      this.keyVisualTexture.flipY = false;
      this.steleScreen.material = new THREE.MeshBasicMaterial({
        map: this.keyVisualTexture,
      });

      this.triggerLoaded();
    }

    if (this.visualType === "video") {
      this.videoSrc = document.createElement("video");
      this.videoSrc.crossOrigin = "anonymous";
      this.videoSrc.muted = true;
      this.videoSrc.volume = "0";
      this.videoSrc.onloadedmetadata = "this.muted = true";
      this.videoSrc.autoplay = true;
      this.videoSrc.loop = true;
      this.videoSrc.playsinline = true;
      this.videoSrc.style = "display:none";
      this.videoSrc.src = this.api.getFileSource(this.keyVisual.fileId);
      this.videoSrc.addEventListener("canplay", () => {
        const videoTexture = new THREE.VideoTexture(this.videoSrc);

        videoTexture.flipY = false;
        this.steleScreen.material = new THREE.MeshBasicMaterial({
          map: videoTexture,
        });

        this.videoSrc.play();
        this.triggerLoaded();
      });
    }
  }

  scaleVertical(delay) {
    if (this.resource) {
      this.resource.scene.scale.x = 0;
      this.resource.scene.scale.y = 0;
      new TWEEN.Tween(this.resource.scene.scale)
        .to({ x: this.model.scale, y: this.model.scale }, 2000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .delay(delay)
        .start();
    }

    if (this.aoModelResource) {
      this.aoModelResource.scene.scale.x = 0;
      this.aoModelResource.scene.scale.y = 0;
      new TWEEN.Tween(this.aoModelResource.scene.scale)
        .to({ x: this.model.meta.aoModel.scale, y: this.model.meta.aoModel.scale }, 2000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .delay(delay)
        .start();
    }
  }

  resetPlatformExhibit() {
    this.hotspots.forEach((hotspot) => {
      hotspot.scaleDown();
    });

    this.moveDown(this.exhibitGroup, this.position[1]);

    this.restartVideo();
  }

  restartVideo() {
    if (this.videoSrc) {
      this.videoSrc.currentTime = 0;
      this.videoSrc.play();
    }
  }

  triggerLoaded() {
    this.isReady = true;
    this.exhibition.trigger("asset-loaded");
  }

  async removeKeyVisual() {
    if (!this.get("keyVisual")) {
      return;
    }

    const status = await this.api.deleteKeyVisualById(this.get("keyVisual").id);

    if (this.videoSrc) {
      this.videoSrc.remove();
    }
    this.steleScreen.material.dispose();
    this.steleScreen.material.copy(this.originalMaterial);

    this.set("keyVisualTexture", null);
    this.set("keyVisual", null);
    // eslint-disable-next-line consistent-return
    return status;
  }

  setShadow() {
    this.resource.scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
      }
    });
  }

  setLinearEncoding() {
    this.resource.scene.traverse((child) => {
      if (child.material?.map?.image) {
        child.material.map.colorSpace = THREE.SRGBColorSpace;
      }
    });
  }

  setDepthWrite() {
    this.resource.scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material.depthWrite = true;
      }
    });
  }

  createHotspots() {
    this.hotspots.map((hotspot) => hotspot.init({ exhibit: this }));
  }

  updateHotspots() {
    this.get("hotspots").forEach((hotspot) => {
      hotspot.update();
    });
  }

  addHotspot(hotspot) {
    this.createExhibitHotspot(hotspot.id, this.id);
    this.get("hotspots").push(hotspot);
  }

  moveUp(object) {
    if (object) {
      new TWEEN.Tween(object.position)
        .to({ y: 7000 }, 2000)
        .delay(1000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
    }
  }

  moveDown(object, y) {
    if (object) {
      new TWEEN.Tween(object.position)
        .to({ y: y }, 2000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
    }
  }

  normalize(payload) {
    this.exhibit = payload;

    Object.assign(this, {
      id: payload.id,
      name: payload.name,
      placeName: payload.placename,
      platformName: payload.platformname,
      headline: payload.headlinelabel,
      sign: payload.sign,
      position: [payload.positionx, payload.positiony, payload.positionz],
      rotationY: payload.rotationy,
      sliderCalcMove: payload.slidercalcmove,
      secondLevel: {
        camPosition: [
          payload.campositionx,
          payload.campositiony,
          payload.campositionz,
        ],
        controlTarget: [
          payload.controltargetx,
          payload.controltargety,
          payload.controltargetz,
        ],
        sidebarTarget: [
          payload.sidebartargetx,
          payload.sidebartargety,
          payload.sidebartargetz,
        ],
        minAzimuthAngle: payload.minazimuthangle,
        maxAzimuthAngle: payload.maxazimuthangle,
      },
      model: payload.model ? this.api.getModel(payload.model) : null,
      resource: undefined,
      keyVisual: payload.keyVisual,
      keyVisualTexture: undefined,
      hotspots: payload.hotspots,
      placeholder: undefined,
      textureAlpha: undefined,
      textureColor: undefined,
    });
  }

  serialize() {
    const [x, y, z] = this.get("position");

    const payload = {
      name: this.get("name"),
      rotationy: this.get("rotationY"),
      positionx: x,
      positiony: y,
      positionz: z,
      model: this.get("model")?.id || null,
    };

    if (!this.get("keyVisual")) {
      payload.keyvisual = null;
    }

    return payload;
  }

  async persist() {
    const payload = this.serialize();

    const res = await this.api.updateExhibitById(this.id, payload);

    return res.status;
  }

  async flush() {
    this.dispose();

    const boothTemplate = this.project.get("boothTemplate");
    const exhibitPlace = boothTemplate.exhibits.find(
      (exhibit) => exhibit.name === this.get("placeName")
    );
    this.set("position", exhibitPlace.position);
    this.set("rotationY", exhibitPlace.rotationY);
    this.set("model", null);
    this.set("keyVisual", null);
    this.set("keyVisualTexture", null);
    this.set("textureColor", null);
    this.set("textureAlpha", null);
    this.set("resource", null);

    this.sign.setVisiblitity(true);
    this.sign.scaleUp(0);
    const status = await this.persist();

    if (status === 200) {
      this.get("hotspots").forEach((hotspot) => hotspot.flush());
    }

    return status;
  }

  removeChildren(parentObject) {
    parentObject.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();

        Object.keys(child.material).forEach((key) => {
          if (
            child.material[key] &&
            typeof child.material[key].dispose === "function"
          ) {
            child.material[key].dispose();
          }
        });
      }
    });
  }

  dispose() {
    this.exhibitGroup.traverse((child) => {
      if(child.name.includes("-aoModelObject")) {
        this.removeChildren(child);
        this.exhibitGroup.remove(child);
      }
    });
    this.removeChildren(this.resource.scene);
    this.exhibitGroup.remove(this.resource.scene);
  }
}
