import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../shared/styles.scss";
import custom from "./project-list.scss";

import Api from "../../api/api-helper";

import "@shs/ui-marcom/src/js/application-bar";
import "@shs/ui-marcom/src/js/button";
import "@shs/ui-marcom/src/js/collection";
import "@shs/ui-marcom/src/js/collection-item";
import "@shs/ui-marcom/src/js/menu";
import "@shs/ui-marcom/src/js/menu-item";
import "../../components/project-list-item/project-list-item";
import "../../components/app-header/app-header";
import "../../components/app-footer/app-footer";
import "../../components/filter-area/filter-area";
import "../../custom-uimc/custom-dropdown/custom-dropdown";
import "../../components/project-list-sort/project-list-sort";
import "../../components/project-list-sort-order/project-list-sort-order";
import "../../components/functional-panel/functional-panel";
import { handleInvalidRequest, hasAuthToken } from "../../api/helper";

class ProjectList extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      appVersion: { attribute: "app-version" },
      router: { type: Object, attribute: false },
      projects: { type: Object, attribute: false },
      project: { type: Object, attribute: false },
      filterOptions: { type: Object, attribute: false },
      sortOptions: { type: Object, attribute: false },
      paginationLimit: { type: Object, attribute: false },
      currentUser: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.api = Api.getInstance();
    this.project = [];
    this.projects = [];
    this.filterOptions = {
      basic: "all-projects",
      search: null,
      businessLine: null,
      template: null,
      currentUserId: null,
    };
    this.sortOptions = {
      type: "last-changed",
      order: "desc",
    };
    this.paginationLimit = 10;
    this.paginationInterval = 10;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["setLoading", "showAlert", "showModal", "currentUser"];
  }

  async firstUpdated() {
    this.refreshProjectList(true);
  }

  setFilterOptions(filterOptions) {
    const oldFilterOptionsSearch = this.filterOptions.search;

    this.filterOptions = filterOptions;
    this.filterOptions.currentUserId = this.currentUser.id;
    this.paginationLimit = this.paginationInterval;

    if (oldFilterOptionsSearch !== filterOptions.search) {
      this.refreshProjectList(false);
    } else {
      this.refreshProjectList(true);
    }
  }

  setSortOptions(updatedSortOptions) {
    this.sortOptions = updatedSortOptions;
    this.refreshProjectList(true);
  }

  async delete(project) {
    const status = await this.project.flush();

    if (status === 200) {
      this.refreshProjectList(true);
      this.showAlert(
        "Deleted",
        `Project "${project.name}" has been deleted.`,
        "success"
      );
    }

    if (status >= 400) {
      this.showAlert(
        "Deletion failed",
        `Delete 
        project "${project.name}" has been failed.`,
        "error"
      );
    }
  }

  async archiveOrDearchive(project) {
    if (project.status === "published") {
      const status = await this.project.flush();

      if (status === 200) {
        this.refreshProjectList(true);
        this.showAlert(
          "Archived",
          `Project "${project.name}" has been archived.`,
          "success"
        );
      }

      if (status >= 400) {
        this.showAlert(
          "Archiving failed",
          `Archiving 
          project "${project.name}" has been failed.`,
          "error"
        );
      }
    } else if (project.status === "archived") {
      const status = await this.project.dearchive();
      if (status === 200) {
        this.refreshProjectList(true);
        this.showAlert(
          "Dearchived",
          `Project "${project.name}" has been dearchived.`,
          "success"
        );
      }
      if (status >= 400) {
        this.showAlert(
          "Dearchiving failed",
          `Dearchiving
          project "${project.name}" has been failed.`,
          "error"
        );
      }
    }
  }

  async setProjectPrivacy(project) {
    if (project.privacy === "public") {
      const status = await this.project.setProjectToPrivate();

      if (status === 200) {
        this.refreshProjectList(true);
        this.showAlert(
          "Set to private",
          `Project "${project.name}" has been set to private.`,
          "success"
        );
      }

      if (status >= 400) {
        this.showAlert(
          "Set to private failed",
          `Set project "${project.name}" to private has been failed.`,
          "error"
        );
      }
    } else if (project.privacy === "private") {
      const status = await this.project.setProjectToPublic();
      if (status === 200) {
        this.refreshProjectList(true);
        this.showAlert(
          "Set to public",
          `Project "${project.name}" has been set to public.`,
          "success"
        );
      }
      if (status >= 400) {
        this.showAlert(
          "Set to public failed",
          `Set project "${project.name}" to public has been failed.`,
          "error"
        );
      }
    }
  }

  async refreshProjectList(isLoadingSpinnerShown) {
    if (isLoadingSpinnerShown === null) {
      isLoadingSpinnerShown = true;
    }

    if (isLoadingSpinnerShown) {
      this.setLoading(true);
    }
    try {
      this.projects = await this.api.projects(
        this.filterOptions,
        this.sortOptions,
        this.paginationLimit
      );
    } catch (error) {
      if (!hasAuthToken) {
        handleInvalidRequest(this.router, this.showAlert);
      }
    } finally {
      if (isLoadingSpinnerShown) {
        this.setLoading(false);
      }
    }
  }

  increasePaginationLimit() {
    this.paginationLimit += this.paginationInterval;
    this.refreshProjectList(true);
    this.scrollToLastPaginationProjectItem();
  }

  scrollToLastPaginationProjectItem() {
    const paginationElement = this.shadowRoot.querySelector(
      ".project-list__pagination"
    );
    paginationElement.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  handleProjectActionEvent(event) {
    const { eventType } = event.detail;

    if (eventType === "delete") {
      this.delete(this.project);
    }
    if (eventType === "archive") {
      this.archiveOrDearchive(this.project);
    }
    if (eventType === "privacy") {
      this.setProjectPrivacy(this.project);
    }
    if (eventType === "copy") {
      this.copy(this.project);
    }
  }

  async copy(project) {
    this.setLoading(true);
    const { status, data: newProjectId } = await project.copy();

    this.setLoading(false);
    this.router.navigate(`/edit-project/${newProjectId}`);

    if (status === 200) {
      this.refreshProjectList(true);
      this.showAlert(
        "Copied",
        `Project "${project.name}" has been copied.`,
        "success"
      );
    }

    if (status >= 400) {
      this.showAlert(
        "Copying failed",
        `Copying 
          project "${project.name}" has been failed.`,
        "error"
      );
    }
  }

  handleSortTypeEvent(event) {
    this.setSortOptions({
      ...this.sortOptions,
      type: event.detail,
    });
  }

  handleSortOrderEvent(event) {
    this.setSortOptions({
      ...this.sortOptions,
      order: event.detail,
    });
  }

  get usersFirstnameString() {
    if (!this.currentUser.first_name) {
      return "";
    }
    return `, ${this.currentUser.first_name}`;
  }

  render() {
    return html`
      <app-header .router=${this.router}></app-header>

      <main class="padding-1">
        <div class="project-list__header">
          <h1 class="margin-top-0">
            Welcome to the ${SIMPLEX.APP_TITLE}${this.usersFirstnameString}
          </h1>
        </div>

        <div class="project-list__collection-wrapper">
          <div class="project-list__collection-filter padding-top-3">
            <functional-panel headline="Edit your project list">
              <uimc-button
                @click=${() => {
                  this.router.navigate(`create-project`);
                }}
                primary
                >Create project</uimc-button
              >
            </functional-panel>
            <functional-panel headline="Filter your projects">
              <filter-area
                @filter-options-event=${event =>
                  this.setFilterOptions(event.detail.filterOptions)}
              ></filter-area>
            </functional-panel>
          </div>
          <div
            class="project-list__collection-list padding-left-1 padding-right-1"
          >
            ${this?.projects?.data?.length > 0
              ? html`
                  <div class="row">
                    <div class="col-6">
                      <h5 class="padding-bottom-1">
                        ${this.projects?.meta?.filter_count
                          ? this.projects?.meta?.filter_count
                          : this.projects.data.length}
                        ${this.projects.data.length <= 1
                          ? "Project"
                          : "Projects"}
                      </h5>
                    </div>
                    <div
                      class="col-6 display-flex align-items-end justify-content-end"
                    >
                      <project-list-sort
                        class="margin-right-1"
                        @sort-type-event=${event =>
                          this.handleSortTypeEvent(event)}
                        base-Path=${this.basePath}
                        .sortType=${this.sortOptions.type}
                      ></project-list-sort>
                      <project-list-sort-order
                        @sort-order-event=${event =>
                          this.handleSortOrderEvent(event)}
                        base-Path=${this.basePath}
                        .sortOrder=${this.sortOptions.order}
                      ></project-list-sort-order>
                    </div>
                  </div>

                  <uimc-collection>
                    ${this.projects?.data?.map(
                      project => html`
                        <project-list-item
                          @project-action-event=${event => {
                            this.project = project;
                            this.handleProjectActionEvent(event);
                          }}
                          base-Path=${this.basePath}
                          .router=${this.router}
                          .projects=${this.projects}
                          .project=${project}
                        ></project-list-item>
                      `
                    )}
                  </uimc-collection>
                  ${this.projects?.meta?.filter_count >
                  this.projects.data.length
                    ? html`
                        <div
                          class="project-list__pagination display-flex justify-content-center padding-top-1"
                        >
                          <uimc-button
                            @click=${() => this.increasePaginationLimit()}
                            >Load more</uimc-button
                          >
                        </div>
                      `
                    : null}
                `
              : html`
                  <h5 class="margin-top-1">No projects found!</h5>
                `}
          </div>
        </div>
      </main>

      <app-footer></app-footer>
    `;
  }
}

customElements.define("project-list", ProjectList);
