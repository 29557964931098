import { LitElement, html, css } from "lit";
import { classMap } from "lit/directives/class-map";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../../../shared/styles.scss";
import custom from "./general-settings-tab.scss";


import Api from "../../../../api/api-helper";

import "@shs/ui-marcom/src/js/dropdown";
import "@shs/ui-marcom/src/js/menu";
import "@shs/ui-marcom/src/js/menu-item";
import "@shs/ui-marcom/src/js/card";

import "../../../../custom-uimc/custom-textfield/custom-textfield";
import "../../../../custom-uimc/custom-button/custom-button";
import "../../../../custom-uimc/custom-toggle-switch/custom-toggle-switch";
import "../../../../custom-uimc/custom-tooltip-button/custom-tooltip-button";
import "../../../../custom-uimc/custom-searchfield/custom-searchfield";
import "../../../../custom-uimc/custom-select/custom-select";
import "../../../../custom-uimc/custom-tag/custom-tag";

import "../../../edit-box/edit-box";
import "../../file-upload/file-upload";
import { hasAuthToken, handleInvalidRequest } from "../../../../api/helper";

class GeneralSettingsList extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      router: { type: Object },
      businessLines: { type: Array, attribute: false },
      experienceBackground: { type: String, attribute: false },
      sharedWith: { type: Array, attribute: false },
      filteredUserList: { type: Array, attribute: false },
      newEmailsToShareWith: { type: Array, attribute: false },
      isSearchfieldInvalid: { type: Boolean, attribute: true },

      activeEditBox: { type: String, attribute: false },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showAlert", "showOverlay", "setLoading", "showModal"];
  }

  constructor() {
    super();

    this.businessLines = [];
    this.sharedWith = [];
    this.pendingEmailsToShareWith = [];
    this.userList = [];
    this.filteredUserList = [];
    this.activeEditBox = null;

    this.api = Api.getInstance();
  }

  async firstUpdated() {
    this.setLoading(true);
    try {
      this.businessLines = await this.api.businessLines();
      this.sharedWith = await this.api.getSharedWithUsersList(this.project.id);
      this.userList = await this.api.getUserList();
      this.filteredUserList = this.userList;

      this.project.on("updated", () => {
        this.requestUpdate();
      });

      this.experienceBackground = this.project.get("config")?.background;
    } catch (error) {
      if (!hasAuthToken) {
        handleInvalidRequest(this.router, this.showAlert);
      }
    } finally {
      this.setLoading(false);
    }
  }

  get businessLineItems() {
    return this.businessLines.map(b => {
      const businessLine = {
        name: b.name,
        value: b.id,
      };
      if (this.project.get("businessLine")?.id === b.id) {
        businessLine.isSelected = true;
      }
      return businessLine;
    });
  }

  get filteredUserListItems() {
    return this.filteredUserList.map(user => {
      return user.email;
    });
  }

  get backgrounds() {
    return this.api.getBackgroundColors();
  }

  get upload() {
    return html`
      <file-upload
        base-path=${this.basePath}
        .project=${this.project}
        .experience=${this.experience}
        header="Add a visual for backwall with 16:9 screen"
        description="Please select a file for upload. Visuals can be pictures (JPEG, PNG) or videos (MP4) and need an aspect ratio of 16:9. The maximum file size for an image is 500KB and for a video 5MB."
        .currentFile=${this.api.getFileSource(
          this.project.get("backwallVisual")?.previewId
        )}
        fileType=${this.project.get("backwallVisual")?.fileType}
        .onRemove=${() => this.removeBackwallVisual()}
        .onUpload=${keyVisualData => this.uploadBackwallImage(keyVisualData)}
      ></file-upload>
    `;
  }

  async uploadBackwallImage(keyVisualData) {
    await this.project.setBackwallVisual(keyVisualData);
  }

  async removeBackwallVisual() {
    this.setLoading(true);

    try {
      const status = await this.project.removeBackwallVisual();

      if (status === 200) {
        this.showAlert(
          "Removed",
          `Backwall visual has been removed.`,
          "success"
        );
      }
    } catch (error) {
      if (!hasAuthToken()) {
        handleInvalidRequest(this.router, this.showAlert);
      } else {
        this.showAlert(
          "Removing failed",
          `Renmoving backwall visual has failed.`,
          "error"
        );
      }
    }

    this.setLoading(false);
  }

  setTitle(title) {
    this.project.set("name", title);
  }

  setDescription(description) {
    this.project.set("description", description);
  }

  setBusinessLine(item) {
    const businessLine = this.businessLines.find(b => {
      return b.id === item;
    });

    this.project.set("businessLine", businessLine);
  }

  setColor(color) {
    const config = { ...this.project.get("config") };
    config.background = color.name;
    this.project.set("config", config);

    this.save();
  }

  toggleAutoRotation() {
    const currentRotationSpeed = this.project.get("controls").rotationSpeed;
    if (currentRotationSpeed !== 0) {
      this.project
        .get("experience")
        .get("camera")
        .setAutorotation(false, 0);
      this.project.get("controls").rotationSpeed = 0;
    } else {
      this.project
        .get("experience")
        .get("camera")
        .setAutorotation(
          true,
          this.project.get("boothTemplate").get("control").rotationSpeed
        );

      this.project.get("controls").rotationSpeed = this.project
        .get("boothTemplate")
        .get("control").rotationSpeed;
    }

    this.save();
  }

  toggleAvatar() {
    const config = this.project.get("config");
    if (config?.arePeopleVisible) {
      this.project
        .get("experience")
        .get("exhibition")
        .get("floor")
        .setPeopleVisibility(false);
      config.arePeopleVisible = false;
    } else {
      this.project
        .get("experience")
        .get("exhibition")
        .get("floor")
        .setPeopleVisibility(true);
      config.arePeopleVisible = true;
    }

    this.project.set("config", config);
    this.save();
  }

  async save() {
    this.setLoading(true);

    try {
      const status = await this.project.persist();

      if (status === 200) {
        this.showAlert(
          "Saved",
          `Project "${this.project.get("name")}" has been saved.`,
          "success"
        );
      }
    } catch (error) {
      if (!hasAuthToken()) {
        handleInvalidRequest(this.router, this.showAlert);
      } else {
        this.showAlert(
          "Saving failed",
          `Saving project "${this.project.get("name")}" has failed.`,
          "error"
        );
      }
    } finally {
      this.activeEditBox = null;

      this.setLoading(false);
    }
  }

  async shareProject(email) {
    let emailExists = false;
    let existingUserId = null;

    this.userList.forEach(user => {
      if (user.email === email) {
        emailExists = true;
        existingUserId = user.id;
      }
    });

    if (emailExists) {
      // share project by id
      this.sharedWith = await this.api.shareProjectWithUser(
        this.project.id,
        existingUserId,
        "userId"
      );
    }
    if (!emailExists) {
      // share project by email
      this.sharedWith = await this.api.shareProjectWithUser(
        this.project.id,
        email,
        "userEmail"
      );
    }
    this.requestUpdate();
  }

  unshareProject(sharedUser) {
    if (sharedUser.directus_users_id?.pending) {
      // delete email in sharedWith array
      this.sharedWith.forEach((s, i) => {
        if (s.directus_users_id.email === sharedUser.directus_users_id.email) {
          this.sharedWith.splice(i, 1);
        }
      });

      // delete email in pending emails array
      this.pendingEmailsToShareWith.forEach((em, emIndex) => {
        if (sharedUser.directus_users_id.email === em) {
          this.pendingEmailsToShareWith.splice(emIndex, 1);
        }
      });
    }

    if (!sharedUser.directus_users_id?.pending) {
      this.api.unshareProjectWithUser(sharedUser).then(result => {
        this.sharedWith = result;
      });
    }

    if (this.pendingEmailsToShareWith.length > 0) {
      this.pendingEmailsToShareWith.forEach(n => {
        this.sharedWith.push({
          directus_users_id: {
            first_name: "",
            last_name: "",
            email: n,
            pending: true,
          },
        });
      });
    }

    this.requestUpdate();
  }

  filterMailAddress(input) {
    this.filteredUserList = [];
    this.userList.forEach(item => {
      if (item.email.toLowerCase().includes(input)) {
        this.filteredUserList.push(item);
      }
    });
  }

  addEmailToShareWith(emailInput) {
    this.pendingEmailsToShareWith.push(emailInput);
    this.sharedWith.push({
      directus_users_id: {
        first_name: "",
        last_name: "",
        email: emailInput,
        pending: true,
      },
    });
    this.filterMailAddress("");
    this.requestUpdate();
  }

  sendNotificationEmails() {
    this.pendingEmailsToShareWith.forEach(email => {
      this.shareProject(email);
    });
    this.pendingEmailsToShareWith = [];
  }

  handleSearchfieldEnter(enterValue) {
    if (!this.isSearchfieldInvalid) {
      this.addEmailToShareWith(enterValue);
    }
  }

  setCameraAnimationPositions() {
    this.project
        .get("experience")
        .get("camera")
        .setIntroAniEditMode(true);
  }

  get selectDialog() {
    return html`
      <exhibit-list
        base-path="${this.basePath}"
        .project=${this.project}
        list-type="positions"
      >
      </exhibit-list>
    `;
  }

  render() {
    return html`
      <div class="general-settings">
        <div class="row">
          <div class="col-12">
            <h4 class="margin-top-0">Project</h4>
            <h5 class="margin-top-0">Title</h5>

            <p class="display-flex align-items-center justify-content-between ">
              ${this.project.get("name")
                ? html`
                    ${this.project.get("name")}
                  `
                : html`
                    <i>No project name available.</i>
                  `}

              <custom-tooltip-button
                tooltip="Edit project title"
                type="control"
                icon="edit"
                ?disabled=${this.activeEditBox !== null}
                .onClick=${() => {
                  this.activeEditBox = "TITLE";
                }}
              >
                Edit title
              </custom-tooltip-button>
            </p>

            ${this.activeEditBox === "TITLE"
              ? html`
                  <edit-box
                    class="margin-bottom-1"
                    description="Type in the project name and press save button to store the setting."
                    .onSubmit=${() => this.save()}
                  >
                    <custom-textfield
                      theme="gray"
                      .onChange=${title => {
                        this.setTitle(title);
                      }}
                      label="Project title"
                      value="${this.project.get("name")}"
                    ></custom-textfield>
                  </edit-box>
                `
              : null}

            <h5 class="margin-top-0">Description</h5>
            <p class="display-flex align-items-center justify-content-between">
              ${this.project.get("description")
                ? html`
                    ${this.project.get("description")}
                  `
                : html`
                    <i>No project description available.</i>
                  `}
              <custom-tooltip-button
                tooltip="Edit project description"
                type="control"
                icon="edit"
                ?disabled=${this.activeEditBox !== null}
                .onClick=${() => {
                  this.activeEditBox = "DESCRIPTION";
                }}
              >
                Edit description
              </custom-tooltip-button>
            </p>
            ${this.activeEditBox === "DESCRIPTION"
              ? html`
                  <edit-box
                    class="margin-bottom-1"
                    description="Type in the project description and press save button to store the setting."
                    .onSubmit=${() => this.save()}
                  >
                    <custom-textfield
                      theme="gray"
                      .onChange=${description => {
                        this.setDescription(description);
                      }}
                      label="Project description"
                      value="${this.project.description}"
                    ></custom-textfield>
                  </edit-box>
                `
              : null}

            <h5 class="margin-top-0">Business line</h5>
            <p class="display-flex align-items-center justify-content-between">
              ${this.project.get("businessLine")?.name
                ? html`
                    ${this.project.get("businessLine")?.name}
                  `
                : html`
                    <i>No business area/line available.</i>
                  `}

              <custom-tooltip-button
                tooltip="Edit business area/line"
                type="control"
                icon="edit"
                ?disabled=${this.activeEditBox !== null}
                .onClick=${() => {
                  this.activeEditBox = "BUSINESSLINE";
                }}
              >
                Edit businessLine
              </custom-tooltip-button>
            </p>
            ${this.activeEditBox === "BUSINESSLINE"
              ? html`
                  <edit-box
                    class="margin-bottom-1"
                    description="Select the business line of the project and press save button to store the setting."
                    .onSubmit=${() => this.save()}
                  >
                    <custom-select
                      theme="gray"
                      .onChange=${item => {
                        this.setBusinessLine(item);
                      }}
                      label="Business area/line"
                      .items="${this.businessLineItems}"
                    >
                    </custom-select>
                  </edit-box>
                `
              : null}

            <h5 class="margin-bottom-1">
              Share project
            </h5>
            <custom-searchfield
              class="share-searchfield margin-bottom-1 display-block"
              placeholder="Share with user (email address)"
              .data=${this.filteredUserListItems}
              .onInput=${searchFieldValue => {
                this.filterMailAddress(searchFieldValue);
              }}
              .onEnter=${enterValue => {
                this.handleSearchfieldEnter(enterValue);
              }}
              ?isInvalid=${this.isSearchfieldInvalid}
              hint-text="Add email address and press enter to share"
              prefix="none"
            ></custom-searchfield>
            ${this.sharedWith.length > 0
              ? this.sharedWith.map(s => {
                  return html`
                    <custom-tag
                      selected
                      @click=${() => {
                        this.unshareProject(s);
                      }}
                    >
                      ${s.directus_users_id.first_name &&
                      s.directus_users_id.last_name
                        ? `${s.directus_users_id.first_name} ${s.directus_users_id.last_name}`
                        : s.directus_users_id.email}
                      ${s.directus_users_id.pending ? "(pending)" : null}
                    </custom-tag>
                  `;
                })
              : null}
          </div>
          <div class="col-12 display-flex justify-content-end">
            <custom-button
              size="small"
              primary
              ?disabled=${this.pendingEmailsToShareWith.length <= 0}
              @click=${() => {
                this.sendNotificationEmails();
              }}
              >Share</custom-button
            >
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            ${this.project.get("boothTemplate").type !== "platform"
              ? html`
                  <h4 class="margin-top-0">Booth design</h4>
                `
              : null}
            ${this.project.get("boothTemplate").type === "booth"
              ? html`
                  <h5 class="margin-top-0">Avatars</h5>
                  <custom-toggle-switch
                    class="display-block margin-bottom-1"
                    ?disabled=${this.activeEditBox !== null}
                    ?checked=${this.project.get("config")?.arePeopleVisible}
                    .onChange=${() => {
                      this.toggleAvatar();
                    }}
                  >
                    Add people to the booth
                  </custom-toggle-switch>

                  <h5 class="margin-bottom-1">Background</h5>
                  <div class="row">
                    ${this.backgrounds.map(background => {
                      return html`
                        <div class="col-12 col-lg-6 col-xl-4">
                          <uimc-card
                            rimless
                            class="cursor-pointer"
                            @click=${() => {
                              this.setColor(background);
                            }}
                          >
                            <uimc-aspect-ratio
                              slot="card-image"
                              formats='{"sm": "16x9"}'
                            >
                              <img src="${this.basePath}${background?.img}" />
                            </uimc-aspect-ratio>
                            <div
                              class="display-flex align-items-center justify-content-between"
                            >
                              <p class="margin-bottom-0">
                                ${background?.label}
                              </p>
                              <span
                                class="${classMap({
                                  invisible:
                                    this.project?.config?.background !==
                                    background.name,
                                })}"
                              >
                                <i class="icon-check icon--success"></i>
                              </span>
                            </div>
                          </uimc-card>
                        </div>
                      `;
                    })}
                  </div>

                  <h5 class="margin-bottom-1">Backwall</h5>
                  <p>
                    ${!this.project.get("backwallVisual")
                      ? html`
                          <custom-button
                            primary
                            icon="plus"
                            size="small"
                            ?disabled=${this.activeEditBox !== null}
                            .onClick=${() => {
                              this.showOverlay(this.upload);
                            }}
                            >Add visual
                          </custom-button>
                        `
                      : html`
                          <custom-button
                            size="small"
                            icon="edit"
                            ?disabled=${this.activeEditBox !== null}
                            .onClick=${() => {
                              this.showOverlay(this.upload);
                            }}
                          >
                            Change visual</custom-button
                          >
                        `}
                  </p>
                `
              : null}
            ${this.project.get("boothTemplate").type === "standalone" ||
            this.project.get("boothTemplate").type === "environment"
              ? html`
                  <h5 class="margin-bottom-1">Auto rotation</h5>
                  <p>
                    <custom-toggle-switch
                      ?disabled=${this.activeEditBox !== null}
                      ?checked=${this.project.controls.rotationSpeed !== 0}
                      .onChange=${() => {
                        this.toggleAutoRotation();
                      }}
                    >
                      Rotate exhibit automatically
                    </custom-toggle-switch>
                  </p>
                `
              : null}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            ${this.project.get("boothTemplate").type !== "platform"
              ? html`
                  <h5 class="margin-bottom-1">Set end position of intro camera fly</h5>
                  <p>
                    <custom-button
                      primary
                      size="small"
                      .onClick=${() => {
                        this.showModal(
                          "Set end position of intro camera fly",
                          this.selectDialog,
                          null,
                          "Choose target",
                          "Cancel",
                          "large",
                          () => this.setCameraAnimationPositions()
                        );
                      }} 
                    >
                      Edit camera
                    </custom-button>
                  </p>
                `
              : null}
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define("general-settings", GeneralSettingsList);
