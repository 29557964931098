import { LitElement, html, css } from "lit-element";
import { ConsumerMixin } from "lit-element-context";

import { handleInvalidRequest, hasAuthToken } from "../../../../api/helper";

import "../../../../custom-uimc/custom-select/custom-select";

import shared from "../../../../shared/styles.scss";
import custom from "./contact-list-item.scss";

class ContactListItem extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      option: { type: Object },
      index: { type: Number },
      activeEditBox: { type: String, attribute: true },
      onDeleteOption: { type: Object },
      setActiveEditBox: { type: Object },
    };
  }

  constructor() {
    super();
    this.activeEditBox = "";
    this.setActiveEditBox = () => {};
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showModal", "showAlert", "setLoading"];
  }

  firstUpdated() {
    this.project.on("updated", () => {
      this.requestUpdate();
    });

    this.contactTypes = [
      {
        value: "Appointment",
        name: "Appointment",
      },
      {
        value: "E-Mail",
        name: "E-Mail",
      },
    ];
  }

  onInput(key, value) {
    switch (key) {
      case "contactTitle":
        this.option.title = value;
        break;

      case "contactType":
        this.option.type = value;
        break;

      case "meetingType":
        this.option.meetingType = value;
        break;
      default:
        break;
    }
  }

  get meetingTypesItems() {
    return this.contactTypes?.map(c => {
      const type = {
        value: c.value,
        name: c.name,
      };
      if (c?.value === this.option.type) {
        type.isSelected = true;
      }
      return type;
    });
  }

  async save() {
    this.project.contact.dynamicContent.options[this.index] = {
      type: this.option.type,
      title: this.option.title,
      meetingType: this.option.meetingType,
    };
    this.setActiveEditBox("");

    this.setLoading(true);
    try {
      const status = await this.project.persistContact();

      if (status === 200) {
        this.showAlert(
          "Saved",
          `Project contact widget has been saved.`,
          "success"
        );
      }
    } catch (error) {
      if (!hasAuthToken()) {
        handleInvalidRequest(this.router, this.showAlert);
      } else {
        this.showAlert(
          "Saving failed",
          `Saving project contact widget has failed.`,
          "error"
        );
      }
    } finally {
      this.setLoading(false);
      this.project.trigger("updated");
    }
  }

  render() {
    return html`
      <div class="contact-list-item">
        <div class="display-flex flex-row flex-wrap">
          <div class="display-flex width-100">
            <span class="margin-right-1">
              ${this.option.title}
            </span>
            <span class="margin-left-auto">
              <custom-button
                type="control"
                icon="edit"
                ?disabled=${!this.project.get("config").contactEnabled}
                .onClick=${() => {
                  this.setActiveEditBox("EDIT", this.index);
                }}
              ></custom-button>
            </span>
            <span class="margin-left-1">
              <custom-button
                type="control"
                icon="delete"
                ?disabled=${!this.project.get("config").contactEnabled}
                .onClick=${() => {
                  this.onDeleteOption(this.option);
                }}
              ></custom-button>
            </span>
          </div>

          ${this.activeEditBox === `EDIT_${this.index}`
            ? html`
                <edit-box
                  class="margin-top-1"
                  description=${"Type in the title and the meeting type, choose a type and press the save button to store the setting."}
                  .onSubmit=${async () => this.save()}
                >
                  <p>
                    <custom-textfield
                      theme="gray"
                      label="Contact option title"
                      value="${this.option.title}"
                      .onChange=${contactTitle => {
                        this.onInput("contactTitle", contactTitle);
                      }}
                    ></custom-textfield>
                  </p>
                  <p>
                    <custom-select
                      theme="gray"
                      label="Contact option type"
                      .items=${[...this.meetingTypesItems]}
                      .onChange=${contactType => {
                        this.onInput("contactType", contactType);
                      }}
                    >
                    </custom-select>
                  </p>
                  <p class="margin-bottom-0" select>
                    <custom-textfield
                      theme="gray"
                      label="Type of meeting"
                      value="${this.option.meetingType}"
                      .onChange=${meetingType =>
                        this.onInput("meetingType", meetingType)}
                    ></custom-textfield>
                  </p>
                </edit-box>
              `
            : null}
        </div>
      </div>
    `;
  }
}

customElements.define("contact-list-item", ContactListItem);
