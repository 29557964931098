import { unsafeCSS, html } from "lit";
import { classMap } from "lit/directives/class-map";
import Overlay from "@shs/ui-marcom/src/js/overlay";

import custom from "./custom-overlay.scss";

export default class CustomOverlay extends Overlay {
  static get properties() {
    return {
      ...super.properties,
      size: {
        type: String,
        attribute: true,
      },
      onClose: {
        type: Object,
        attribute: false,
      },
    };
  }

  static get styles() {
    return [super.styles, unsafeCSS(custom)];
  }

  constructor() {
    super();
    this.onClose = () => {};
  }

  _hideOverlay() {
    super._hideOverlay();
    this.onClose();
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener("keydown", this._handleEsc.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.querySelector("body").classList.remove("overflow-hidden");
    document.removeEventListener("keydown", this._handleEsc.bind(this));
  }

  _handleEsc(event) {
    if (event.code === "Escape") {
      this._hideOverlay();
    }
  }

  render() {
    return html`
      <div
        class="${classMap({
          overlay: true,
          "is-open": this.isOpen,
        })}"
      >
        <div
          class="overlay__container ${classMap({
            "overlay__container--large": this.size === "large",
            "overlay__container--sidebar": this.size === "sidebar",
          })}"
        >
          <button
            type="button"
            class="button button--control icon-close overlay__close"
            @click=${this._hideOverlay}
          ></button>
          <div class="overlay__body">
            <slot></slot>
          </div>
        </div>
      </div>
    `;
  }
}

window.customElements.define("custom-overlay", CustomOverlay);
