import { html } from "lit";
import UserIdentifier from "@shs/ui-marcom/src/js/user-identifier";

class CustomUserIdentifier extends UserIdentifier {
  firstUpdated() {
    const children = this._getChildren();
    for (const menu of children) {
      menu.onClick = () => {
        this._onClose();
      };
    }
    document.addEventListener("click", e => {
      const target = e.composedPath()[0];

      if (!this.shadowRoot.contains(target)) {
        this._onClose();
      }
    });
  }
}

customElements.define("custom-user-identifier", CustomUserIdentifier);
