import axios from "axios";
import { getExhibit } from "./exhibit";
import { createKeVisual } from "./keyvisual";
import {
  getLightsArray,
  getControlsObject,
  getCameraObject,
  getBoothtemplateById,
} from "./boothtemplate";
import Project from "../experience/Models/Project";
import Build from "../experience/Models/Build";
import { getPlatformArray } from "./platforms";
import { getHotspotCopy } from "./hotspot";
import { getContactTemplate } from "./contact";

const projectListFields = [
  "id",
  "name",
  "description",
  "status",
  "businessline.id",
  "businessline.name",
  "businessline.label",
  "businessline.description",
  "businessline.sort",
  "boothtemplate.id",
  "boothtemplate.name",
  "boothtemplate.sort",
  "date_created",
  "date_updated",
  "user_created.id",
  "user_created.email",
  "user_created.first_name",
  "user_created.last_name",
  "privacy",
  "sharedwith.*",
];

function getFilterParams(filterOptions) {
  let combinedFilterParams = {};
  let basicFilterParams = {};
  let searchFilterParams = {};
  let businessLineFilterParams = {};
  let boothTemplateFilterParams = {};

  if (filterOptions.basic) {
    switch (filterOptions.basic) {
      case "all-projects":
        basicFilterParams = {
          _or: [
            {
              _and: [
                { privacy: { _eq: "private" } },
                { status: { _eq: "published" } },
              ],
            },
            {
              _and: [
                { privacy: { _eq: "public" } },
                { status: { _eq: "published" } },
              ],
            },
          ],
        };
        break;
      case "my-projects":
        basicFilterParams = {
          _and: [
            { status: { _eq: "published" } },
            {
              user_created: {
                id: { _eq: filterOptions.currentUserId },
              },
            },
          ],
        };
        break;
      case "private-projects":
        basicFilterParams = {
          _and: [
            { privacy: { _eq: "private" } },
            { status: { _eq: "published" } },
            {
              user_created: {
                id: { _eq: filterOptions.currentUserId },
              },
            },
          ],
        };
        break;
      case "projects-shared-with-me":
        basicFilterParams = {
          sharedwith: {
            directus_users_id: {
              id: { _eq: filterOptions.currentUserId },
            },
          },
        };
        break;
      case "archived-projects":
        basicFilterParams = { status: { _eq: "archived" } };
        break;
      default:
        basicFilterParams = { status: { _eq: "published" } };
        break;
    }
  }

  if (filterOptions.search) {
    searchFilterParams = {
      _or: [
        { name: { _contains: filterOptions.search } },
        { description: { _contains: filterOptions.search } },
      ],
    };
  }

  if (filterOptions.businessLine) {
    businessLineFilterParams = {
      businessline: { id: { _eq: filterOptions.businessLine } },
    };
  }

  if (filterOptions.boothTemplate) {
    boothTemplateFilterParams = {
      boothtemplate: { id: { _eq: filterOptions.boothTemplate } },
    };
  }

  combinedFilterParams = {
    _and: [
      basicFilterParams,
      searchFilterParams,
      businessLineFilterParams,
      boothTemplateFilterParams,
    ],
  };

  return JSON.stringify(combinedFilterParams);
}

function getSortParams(sortOptions) {
  let orderParam = "";

  switch (sortOptions.order) {
    case "desc":
      orderParam = "-";
      break;
    case "asc":
      orderParam = "";
      break;
    default:
      orderParam = "-";
  }

  switch (sortOptions.type) {
    case "last-changed":
      return `${orderParam}date_updated`;
    case "project-title":
      return `${orderParam}name`;
    case "project-owner":
      return `${orderParam}user_created`;
    case "business-line":
      return ``;
    case "booth-template":
      return ``;
    default:
      return `${orderParam}date_updated`;
  }
}

function sortByBusinesslineOrBoothTemplate(projects, sortOptions) {
  if (sortOptions.type === "business-line" && sortOptions.order === "asc") {
    projects.data.sort((a, b) => a.businessLine.sort - b.businessLine.sort);
  }
  if (sortOptions.type === "business-line" && sortOptions.order === "desc") {
    projects.data.sort((a, b) => b.businessLine.sort - a.businessLine.sort);
  }

  if (sortOptions.type === "booth-template" && sortOptions.order === "asc") {
    projects.data.sort((a, b) => a.boothTemplate.sort - b.boothTemplate.sort);
  }
  if (sortOptions.type === "booth-template" && sortOptions.order === "desc") {
    projects?.data.sort((a, b) => b.boothTemplate.sort - a.boothTemplate.sort);
  }

  return projects.data;
}

async function getProjects(
  url,
  options,
  filterOptions,
  sortOptions,
  paginationLimit
) {
  let filter = { status: { _eq: "published" } };

  if (filterOptions.basic) {
    filter = getFilterParams(filterOptions);
  }

  const requestUrl = new URL("/items/projects", url);
  requestUrl.searchParams.append("fields", projectListFields.join(","));
  requestUrl.searchParams.append("filter", filter);
  requestUrl.searchParams.append("sort", getSortParams(sortOptions));
  requestUrl.searchParams.append("limit", paginationLimit);
  requestUrl.searchParams.append("meta", "*");

  const res = await axios.get(requestUrl, options);
  const apiProjects = res.data.data;

  const projects = {};
  projects.meta = res.data.meta;

  projects.data = apiProjects.map(project => {
    return new Project({
      id: project.id,
      name: project.name,
      status: project.status,
      description: project.description,
      businessLine: project.businessline,
      boothTemplate: project.boothtemplate,
      userCreated: project.user_created,
      privacy: project.privacy,
      sharedWith: project.sharedwith.map(s => s.directus_users_id),
      lastModified: project.date_updated || project.date_created,
    });
  });

  sortByBusinesslineOrBoothTemplate(projects, sortOptions);

  return projects;
}

const projectFields = [
  "id",
  "name",
  "businessline.id",
  "businessline.name",
  "businessline.label",
  "businessline.description",
  "description",
  "boothtemplate",
  "backwallvisual.id",
  "backwallvisual.name",
  "backwallvisual.preview.id",
  "backwallvisual.file.id",
  "backwallvisual.file.type",
  "exhibits.exhibits_id.id",
  "platforms.project_platforms_id.*",
  "platforms.project_platforms_id.hotspot.*",
  "platforms.project_platforms_id.spotlight.*",
  "lights.project_lights_id.*",
  "controls.project_controls_id.*",
  "camera.*",
  "builds.directus_files_id.id",
  "builds.directus_files_id.title",
  "builds.directus_files_id.uploaded_on",
  "config",
  "contact.*",
  "date_created",
  "date_updated",
];

async function getProject(projectId, url, options) {
  const requestUrl = new URL(`/items/projects/${projectId}`, url);
  requestUrl.searchParams.append("fields", projectFields.join(","));

  const res = await axios.get(requestUrl, options);
  const apiProject = res.data.data;

  const instance = new Project({
    id: apiProject.id,
    name: apiProject.name,
    description: apiProject.description,
    businessLine: apiProject.businessline,
    boothTemplate: apiProject.boothtemplate,
    backwallVisual: apiProject.backwallvisual
      ? {
          id: apiProject.backwallvisual?.id,
          fileId: apiProject.backwallvisual?.file.id,
          previewId: apiProject.backwallvisual?.preview.id,
          name: apiProject.backwallvisual?.name,
          fileType: apiProject.backwallvisual?.file.type,
        }
      : null,
    builds: apiProject.builds,
    exhibits: apiProject.exhibits,
    platforms: apiProject.platforms,
    lights: getLightsArray(apiProject.lights.map(l => l.project_lights_id)),
    controls: getControlsObject(
      apiProject.controls.map(c => c.project_controls_id)
    ),
    camera: getCameraObject(apiProject.camera),
    config: apiProject.config,
    contact: apiProject.contact,
    lastModified: apiProject.date_updated,
  });

  const boothTemplate = await getBoothtemplateById(
    apiProject.boothtemplate,
    url,
    options
  );

  boothTemplate.exhibits = boothTemplate.exhibits.sort(
    (a, b) => a.sort - b.sort
  );
  boothTemplate.platforms = boothTemplate.platforms.sort(
    (a, b) => a.sort - b.sort
  );

  const exhibits = await getExhibits(instance, url, options);
  const exhibitsSorted = exhibits.sort((a, b) => {
    const aHeadline = a.headline.toUpperCase();
    const bHeadline = b.headline.toUpperCase();
    if (aHeadline < bHeadline) return -1;
    if (aHeadline > bHeadline) return 1;
    return 0;
  });

  const platforms = getPlatformArray(
    instance.platforms.map(p => p.project_platforms_id)
  );
  const builds = getBuilds(instance);

  instance.set("boothTemplate", boothTemplate);
  instance.set("exhibits", exhibitsSorted);
  instance.set("platforms", platforms);
  instance.set("builds", builds);

  return instance;
}

function getBuilds(projectInstance) {
  return projectInstance.builds.map(
    b => new Build(b.directus_files_id, projectInstance)
  );
}

async function createProject(projectObject, url, options) {
  const projectUrl = new URL(`/items/projects`, url);

  const { boothtemplate } = projectObject;
  projectObject.boothtemplate = projectObject.boothtemplate.id;
  projectObject.contact = await getContactTemplate("en_US", url, options);

  const res = await axios.post(projectUrl, projectObject, options);
  const newProject = res.data.data;

  await createProjectExhibits(
    boothtemplate.exhibits,
    newProject.id,
    url,
    options
  );
  await createProjectPlatforms(
    boothtemplate.platforms,
    newProject.id,
    url,
    options
  );

  await createProjectLights(boothtemplate.lights, newProject.id, url, options);
  await createProjectControls(
    boothtemplate.control,
    newProject.id,
    url,
    options
  );
  await createProjectCamera(boothtemplate.camera, newProject.id, url, options);

  return getProject(newProject.id, url, options);
}

async function updateProject(id, fields, url, options) {
  const projectUrl = new URL(`/items/projects/${id}`, url);

  const res = await axios.patch(projectUrl, fields, options);

  return res;
}

async function archiveProject(projectId, url, options) {
  const projectUrl = new URL(`/items/projects/${projectId}`, url);

  const res = await axios.patch(projectUrl, { status: "archived" }, options);

  return res;
}

async function setProjectPrivate(projectId, url, options) {
  const projectUrl = new URL(`/items/projects/${projectId}`, url);

  const res = await axios.patch(projectUrl, { privacy: "private" }, options);

  return res;
}

async function setProjectPublic(projectId, url, options) {
  const projectUrl = new URL(`/items/projects/${projectId}`, url);

  const res = await axios.patch(projectUrl, { privacy: "public" }, options);

  return res;
}

async function dearchiveProject(projectId, url, options) {
  const projectUrl = new URL(`/items/projects/${projectId}`, url);

  const res = await axios.patch(projectUrl, { status: "published" }, options);

  return res;
}

async function getProjectConfig(projectId, url, options) {
  const projectUrl = new URL(`/custom/config-export/${projectId}`, url);

  const res = await axios.get(projectUrl, options);

  return res.data.data;
}
async function getProjectBuild(projectId, url, options) {
  const projectUrl = new URL(`/project-export/${projectId}`, url);

  const res = await axios.get(projectUrl, options);

  return res;
}

async function deleteBuild(fileHash, url, options) {
  const projectUrl = new URL(`/files/${fileHash}`, url);

  const res = await axios.delete(projectUrl, options);

  return res;
}

async function addBackwallVisual(projectId, keyVisualData, url, options) {
  const res = await createKeVisual(keyVisualData, url, options);
  const newKeyVisual = res.data.data;
  await updateProject(
    projectId,
    { backwallvisual: newKeyVisual.id },
    url,
    options
  );

  return {
    id: newKeyVisual.id,
    name: newKeyVisual.name,
    fileId: newKeyVisual.file,
    previewId: newKeyVisual.preview,
    fileType: keyVisualData.fileType,
  };
}

async function updateControl(controlId, controlData, url, options) {
  const controlUrl = new URL(`/items/project_controls/${controlId}`, url);

  return axios.patch(controlUrl, controlData, options);
}

async function updateCamera(cameraId, cameraData, url, options) {
  const cameraUrl = new URL(`/items/project_cameras/${cameraId}`, url);

  const res = axios.patch(cameraUrl, cameraData, options);

  return res;
}

export {
  getProjects,
  getProject,
  createProject,
  copyProject,
  updateProject,
  updateControl,
  updateCamera,
  archiveProject,
  dearchiveProject,
  setProjectPrivate,
  setProjectPublic,
  getProjectConfig,
  getProjectBuild,
  deleteBuild,
  addBackwallVisual,
};

function getExhibits(projectInstance, url, options) {
  const epObject = projectInstance.get("exhibits").map(e => e.exhibits_id);
  const exhibits = epObject.map(e => {
    return getExhibit({ id: e.id, projectInstance }, url, options);
  });

  return Promise.all(exhibits);
}

async function createProjectExhibits(exhibits, projectId, url, options) {
  const exhibitsUrl = new URL(`/items/projects_exhibits`, url);

  const exhibitArray = [];
  exhibits.forEach(ep => {
    exhibitArray.push({
      projects_id: projectId,
      exhibits_id: {
        status: "published",
        name: ep.name,
        placename: ep.name,
        platformname: ep.platformName,
        headlinelabel: ep.headline,
        sign: ep.sign,
        positionx: ep.position[0],
        positiony: ep.position[1],
        positionz: ep.position[2],
        rotationx: 0,
        rotationy: ep.rotationY,
        rotationz: 0,
        slidercalcmove: ep.sliderCalcMove,
        campositionx: ep.secondLevel.camPosition[0],
        campositiony: ep.secondLevel.camPosition[1],
        campositionz: ep.secondLevel.camPosition[2],
        controltargetx: ep.secondLevel.controlTarget[0],
        controltargety: ep.secondLevel.controlTarget[1],
        controltargetz: ep.secondLevel.controlTarget[2],
        sidebartargetx: ep.secondLevel.sidebarTarget[0],
        sidebartargety: ep.secondLevel.sidebarTarget[1],
        sidebartargetz: ep.secondLevel.sidebarTarget[2],
        minazimuthangle: ep.secondLevel.minAzimuthAngle,
        maxazimuthangle: ep.secondLevel.maxAzimuthAngle,
      },
    });
  });

  return axios.post(exhibitsUrl, exhibitArray, options);
}

async function createProjectPlatforms(platforms, projectId, url, options) {
  const projectPlatformsUrl = new URL(`/items/projects_project_platforms`, url);

  const platformArray = await Promise.all(
    platforms.map(async p => {
      const newHotspot = getHotspotCopy(p.hotspot);

      return {
        projects_id: projectId,
        project_platforms_id: {
          status: "published",
          name: p.name,
          sort: p.sort,
          positionx: p.position[0],
          positiony: p.position[1],
          positionz: p.position[2],
          frontpositionx: p.frontPosition[0],
          frontpositiony: p.frontPosition[1],
          frontpositionz: p.frontPosition[2],
          hotspot: newHotspot,
          spotlight: p.spotLight.id,
          bridgeanidelay: p.bridgeAniDelay,
          bridgerotation: p.bridgeRotation,
          isactive: p.isActive,
          firstlevelscale: p.firstLevelScale,
          secondlevelscale: p.secondLevelScale,
        },
      };
    })
  );

  return axios.post(projectPlatformsUrl, platformArray, options);
}

async function createProjectLights(lights, projectId, url, options) {
  const projectsLightsUrl = new URL(`/items/projects_lights`, url);
  const lightsArray = [];

  lights.forEach(light => {
    lightsArray.push({
      projects_id: projectId,
      project_lights_id: {
        status: "published",
        name: light.name,
        type: light.type,
        positionx: light.position[0],
        positiony: light.position[1],
        positionz: light.position[2],
        targetx: light.target[0],
        targety: light.target[1],
        targetz: light.target[2],
        intensity: light.intensity,
        castshadow: light.castshadow,
        shadowcamfar: light.shadowCamFar,
        shadowcamtop: light.shadowCamTop,
        shadowcamright: light.shadowCamRight,
        shadowcambottom: light.shadowCamBottom,
        shadowcamleft: light.shadowCamLeft,
        width: light.width,
        height: light.height,
        instanceofcamera: light.instanceOfCamera,
      },
    });
  });

  return axios.post(projectsLightsUrl, lightsArray, options);
}

async function createProjectControls(
  boothtemplateControl,
  projectId,
  url,
  options
) {
  const projectControlsUrl = new URL(`/items/projects_controls`, url);

  const controlsArray = [
    {
      projects_id: projectId,
      project_controls_id: {
        status: "published",
        ispanenabled: boothtemplateControl.enablePan,
        maxazimuthangle: boothtemplateControl.maxAzimuthAngle,
        maxdistance: boothtemplateControl.maxDistance,
        maxpolarangle: boothtemplateControl.maxPolarAngle,
        minazimuthangle: boothtemplateControl.minAzimuthAngle,
        mindistance: boothtemplateControl.minDistance,
        minpolarangle: boothtemplateControl.minPolarAngle,
        name: boothtemplateControl.name,
        rotationspeed: boothtemplateControl.rotationSpeed,
        level: 1,
      },
    },
  ];

  if (boothtemplateControl.secondLevel) {
    controlsArray.push({
      projects_id: projectId,
      project_controls_id: {
        status: "published",
        ispanenabled: boothtemplateControl.secondLevel.enablePan,
        maxazimuthangle: boothtemplateControl.secondLevel.maxAzimuthAngle,
        maxdistance: boothtemplateControl.secondLevel.maxDistance,
        maxpolarangle: boothtemplateControl.secondLevel.maxPolarAngle,
        minazimuthangle: boothtemplateControl.secondLevel.minAzimuthAngle,
        mindistance: boothtemplateControl.secondLevel.minDistance,
        minpolarangle: boothtemplateControl.secondLevel.minPolarAngle,
        name: boothtemplateControl.secondLevel.name,
        rotationspeed: boothtemplateControl.rotationSpeed,
        level: 2,
      },
    });
  }

  return axios.post(projectControlsUrl, controlsArray, options);
}

// TODO: Intro position and Intro Target
async function createProjectCamera(
  boothtemplateCamera,
  projectId,
  url,
  options
) {
  let cameraObejct = {
    camera: {
      status: "published",
      name: boothtemplateCamera.name,
      positionx: boothtemplateCamera.position[0],
      positiony: boothtemplateCamera.position[1],
      positionz: boothtemplateCamera.position[2],
      lookatx: boothtemplateCamera.lookAt[0],
      lookaty: boothtemplateCamera.lookAt[1],
      lookatz: boothtemplateCamera.lookAt[2],
      intropositionx: boothtemplateCamera.introPosition[0],
      intropositiony: boothtemplateCamera.introPosition[1],
      intropositionz: boothtemplateCamera.introPosition[2],
      introtargetx: boothtemplateCamera.introTarget[0],
      introtargety: boothtemplateCamera.introTarget[1],
      introtargetz: boothtemplateCamera.introTarget[2],
      fov: boothtemplateCamera.fov,
      near: boothtemplateCamera.near,
      far: boothtemplateCamera.far,
    },
  };
  return updateProject(projectId, cameraObejct, url, options);
}

async function copyProject(projectId, url, options) {
  const requestUrl = new URL(`/project-copy/${projectId}`, url);

  return axios.get(requestUrl, options);
}
