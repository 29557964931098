import { LitElement, html, css } from "lit";

import shared from "../../shared/styles.scss";
import custom from "./custom-radio-group.scss";

class CustomRadioGroup extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  get children() {
    const slot = this.shadowRoot.querySelector("slot");

    return slot
      .assignedElements()
      .map(node => {
        if (node.nodeName === "UIMC-RADIO-BUTTON") {
          return node;
        }
        return node.querySelector("custom-radio-button");
      })
      .filter(node => node);
  }

  handleSlotChange() {
    const { children } = this;

    children.forEach(radioButton => {
      radioButton.onChange = currentItem => {
        this._onChange(currentItem);
      };
    });
  }

  _onChange(currentItem) {
    const { children } = this;

    // eslint-disable-next-line no-restricted-syntax
    children.forEach(radioButton => {
      if (radioButton !== currentItem) {
        radioButton.isChecked = false;
      }
    });

    if (typeof this.onChange === "function") {
      this.onChange(currentItem.value);
    }
  }

  render() {
    return html`
      <div class="uimc-radio-button-group">
        <slot @slotchange=${() => this.handleSlotChange()}></slot>
      </div>
    `;
  }
}

customElements.define("custom-radio-group", CustomRadioGroup);
