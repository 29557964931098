import { LitElement, html, css } from "lit";

import shared from "../../shared/styles.scss";
import custom from "./custom-tabs.scss";

import Tabs from "@shs/ui-marcom/src/js/tabs";

class CustomTabs extends Tabs {
  static get properties() {
    return {
      ...super.properties,
      onChange: {
        type: Object,
        attribute: false,
      },
    };
  }

  constructor() {
    super();
    this.onChange = () => {};
  }

  _selectItem(selectedItem) {
    super._selectItem(selectedItem);
    this.onChange(selectedItem);
  }
}

customElements.define("custom-tabs", CustomTabs);
