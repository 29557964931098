// eslint-disable-next-line import/no-cycle
import ApiModel from "./ApiModel";

export default class Build extends ApiModel {
  constructor(apiBuild, project) {
    super(apiBuild);

    this.project = project;
  }

  get filename() {
    if (!this.get("filename")) {
      return `${this.getProjectFileName(this.get("title"))}.zip`;
    }

    return this.get("filename");
  }

  get src() {
    return `${this.api.getFileSource(this.get("id"))}&download`;
  }

  set filename(filename) {
    this.set("filename", filename);
  }

  getProjectFileName(projectName) {
    return projectName
      .toLowerCase()
      .replace(/\s+-\s+/g, "-")
      .replace(/\s+/g, "-")
      .replace(/[(|)]/g, "_");
  }

  async flush() {
    const res = await this.api.deleteBuildByHash(this.get("id"));

    let builds = this.get("project").get("builds");
    builds = builds.filter(build => build.get("id") !== this.get("id"));

    this.get("project").set("builds", builds);

    return res.status;
  }
}
