/* eslint-disable no-underscore-dangle */
import { html, css } from "lit";
import { classMap } from "lit/directives/class-map";

import Searchfield from "@shs/ui-marcom/src/js/searchfield";

import shared from "../../shared/styles.scss";
import custom from "./custom-searchfield.scss";

class CustomSearchfield extends Searchfield {
  static get properties() {
    return {
      ...super.properties,
      onEnter: {
        attribute: false,
      },
      hintText: {
        type: String,
        attribute: "hint-text",
      },
      isInvalid: {
        type: Boolean,
        attribute: "invalid",
      },
      selectedItem: {
        type: Number,
        attribute: false,
      },
      isOpen: {
        type: Boolean,
        attribute: false,
      },
    };
  }

  static get styles() {
    return [super.styles, css(shared), css(custom)];
  }

  constructor() {
    super();
    this.isActive = false;
    this.hasFocus = false;
    this.disabled = false;
    this.size = "";
    this.data = [];
    this.placeholder = "";
    this.value = "";
    this.onInput = () => {};
    this.onChange = () => {};
    this.onEnter = () => {};
    this.prefix = "search";
    this.hintText = null;
    this.isInvalid = false;
    this.selectedItem = 0;
    this.isSelectedData = [];
    this.isArrowNavigationActive = false;
    this.isOpen = false;
  }

  firstUpdated() {
    document.addEventListener("click", (e) => {
      const path = e.composedPath();
      if (
        path.length > 0 &&
        !path[0].classList.value.includes("menu__item") &&
        !path[0].classList.value.includes("search-field__input")
      ) {
        this.dropFocus();
      }
    });
  }

  disconnectedCallback() {
    document.removeEventListener("click", null);
    super.disconnectedCallback();
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName === "data") {
        if (this.data) {
          this.setIsSelectedItemTrue(this.selectedItem);
        }
      }
    });
  }

  _onInput() {
    this.value = this.shadowRoot.querySelector(".search-field__input").value;
    if (typeof this.onInput === "function") {
      this.onInput(this.value);
    }

    this.setIsSelectedItemTrue(this.selectedItem);
  }

  _onChange() {
    this.value = this.shadowRoot.querySelector(".search-field__input").value;
    if (typeof this.onChange === "function") {
      this.onChange(this.value);
    }
  }

  _onEnter() {
    if (this.isArrowNavigationActive) {
      this.value = this.data[this.selectedItem];
      this.shadowRoot.querySelector(".search-field__input").value = this.value;
      this.selectedItem = 0;
    }

    this.value = this.shadowRoot.querySelector(".search-field__input").value;
    this.validateMail();

    if (typeof this.onChange === "function" && !this.isInvalid) {
      this.onEnter(this.value);
      this.onInput();
      this.value = "";
      this.shadowRoot.querySelector(".search-field__input").value = "";
      this.value = this.shadowRoot.querySelector(".search-field__input").blur();
      this.value = this.shadowRoot
        .querySelector(".search-field__input")
        .focus();
    }
  }

  setIsSelectedItemTrue(selectedItemIndex) {
    this.isSelectedData = [];

    this.data.forEach((item, index) => {
      if (index === selectedItemIndex) {
        this.isSelectedData.push({ isSelected: true });
      }
      if (index !== selectedItemIndex) {
        this.isSelectedData.push({ isSelected: false });
      }
    });
    this.requestUpdate();
  }

  setFocus() {
    this.isActive = true;
    this.hasFocus = true;
  }

  dropFocus() {
    this.isActive = false;
    this.hasFocus = false;
    this.isSelected = 0;
    this.isOpen = false;
    this.shadowRoot.querySelector(".search-field__autocomplete").scrollTo({
      top: 0,
    });
  }

  _onClick(item) {
    this.value = item;
    this.shadowRoot.querySelector(".search-field__input").value = item;
    this.shadowRoot.querySelector(".search-field__input").focus();
    this.shadowRoot
      .querySelector(".search-field__input")
      .setSelectionRange(this.value.length, this.value.length);
    this.isActive = false;
  }

  _onKeyDown(e) {
    switch (e.key) {
      case "Tab":
        this.shadowRoot.querySelector(".search-field__input").blur();
        this.dropFocus();
        break;

      case "Enter":
        e.preventDefault();
        this._onEnter();
        break;

      case "Escape":
        e.preventDefault();
        this.shadowRoot.querySelector(".search-field__input").blur();
        this.dropFocus();
        break;

      case "ArrowUp":
        e.preventDefault();
        this._onArrowKey("up");
        break;

      case "ArrowDown":
        e.preventDefault();
        this._onArrowKey("down");
        break;

      default:
        break;
    }
  }

  _onArrowKey(direction) {
    this.isArrowNavigationActive = true;
    switch (direction) {
      case "up":
        if (this.selectedItem > 0) {
          this.selectedItem--;
          this.setIsSelectedItemTrue(this.selectedItem);
          this.scrollToSelectedItem("up");
        }
        break;
      case "down":
        if (this.selectedItem < this.isSelectedData.length - 1) {
          this.selectedItem++;
          this.setIsSelectedItemTrue(this.selectedItem);
          this.scrollToSelectedItem("down");
        }
        break;
    }
  }

  scrollToSelectedItem(direction) {
    const scrollDownValue = 200;
    const scrollUpValue = -200;
    const selectedItemMod = this.selectedItem % 4;

    if (
      this.selectedItem !== 0 &&
      selectedItemMod === 0 &&
      direction === "down"
    ) {
      this.shadowRoot.querySelector(".search-field__autocomplete").scrollBy({
        top: scrollDownValue,
      });
    }

    if (selectedItemMod === 3 && direction === "up") {
      this.shadowRoot.querySelector(".search-field__autocomplete").scrollBy({
        top:
          this.selectedItem >= this.isSelectedData.length - 4
            ? (this.isSelectedData.length - 1 - this.selectedItem) * -50
            : scrollUpValue,
      });
    }
  }

  validateMail() {
    const mailRegex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

    if (this.value.match(mailRegex)) {
      this.isInvalid = false;
    } else {
      this.isInvalid = true;
    }
  }

  get resultsTemplate() {
    return html`
      <div class="search-field__autocomplete">
        <uimc-menu>
          ${this.data.map((item, i) => {
            return html`
              <uimc-menu-item
                ?selected=${this.isSelectedData[i]?.isSelected}
                @click=${() => {
                  this._onClick(item);
                }}
                >${item}</uimc-menu-item
              >
            `;
          })}
        </uimc-menu>
      </div>
    `;
  }

  render() {
    return html`
      <div
        class="${classMap({
          "search-field": true,
          textfield: true,
          "textfield--with-prefix": this.prefix !== "none",
          "is-active": this.isActive,
          "is-open": this.isOpen,
          "has-focus": this.hasFocus,
          "is-disabled": this.disabled,
          "is-invalid": this.isInvalid,
          "has-results": Array.isArray(this.data) && this.data,
        })}"
      >
        ${this.prefix === "none"
          ? null
          : html` <i class="textfield__prefix icon-search"></i> `}
        <input
          type="search"
          class="textfield__input search-field__input"
          spellcheck="false"
          autocomplete="off"
          placeholder=${this.placeholder}
          value=${this.value}
          @input=${() => {
            this._onInput();
          }}
          @change=${() => {
            this._onChange();
          }}
          @focus=${() => {
            this.setFocus();
          }}
          @keydown=${(e) => {
            this._onKeyDown(e);
          }}
        />
        ${this.hintText
          ? html`
              <small class="custom-searchfield__hint"> ${this.hintText} </small>
            `
          : null}
        ${Array.isArray(this.data) && this.data ? this.resultsTemplate : null}
      </div>
    `;
  }
}

customElements.define("custom-searchfield", CustomSearchfield);
