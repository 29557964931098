import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../shared/styles.scss";
import custom from "./project-list-item.scss";

import "@shs/ui-marcom/src/js/label";
import "../../custom-uimc/custom-label/custom-label";
import "../project-list-item-dropdown/project-list-item-dropdown";

class ProjectListItem extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      project: { type: Object, attribute: false },
      currentUser: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.project = [];
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["setLoading", "showAlert", "showModal", "currentUser"];
  }

  get sharedUsers() {
    const sharedUsers = [];

    this.project.get("sharedWith").forEach(sharedUser => {
      sharedUsers.push(sharedUser.directus_users_id);
    });

    return sharedUsers;
  }

  get userProjectPermissions() {
    return (
      this.sharedUsers.includes(this.currentUser.id) ||
      this.project.get("userCreated").id === this.currentUser.id ||
      ["SiemensAdmin", "Admin"].includes(this.currentUser?.role?.name)
    );
  }

  get lastModifiedDateString() {
    return new Date(this.project.get("lastModified")).toLocaleString("en-US", {
      dateStyle: "short",
      timeStyle: "short",
      hour12: false,
    });
  }

  bubbleProjectActionEvent(eventType, project) {
    const projectActionClickEvent = new CustomEvent("project-action-event", {
      detail: {
        eventType,
        project,
      },
    });
    this.dispatchEvent(projectActionClickEvent);
  }

  openUrlInNewTab(url) {
    const newTabUrl = `${this.router.root}/#/${url}`;
    window.open(newTabUrl, "_blank");
  }

  render() {
    return html`
      <uimc-collection-item>
        <div class="row">
          <div class="col-11">
            <span
              class="project-list-item__title"
              @click=${() => {
                if (this.userProjectPermissions) {
                  this.router.navigate(
                    `edit-project/${this.project.get("id")}`
                  );
                } else {
                  this.openUrlInNewTab(
                    `preview-project/${this.project.get("id")}`
                  );
                }
              }}
            >
              ${this.project.get("name")}
            </span>
            ${this.project.get("privacy") === "public"
              ? html`
                  <custom-label>Public</custom-label>
                `
              : null}
            ${this.project.get("privacy") === "private"
              ? html`
                  <custom-label theme="dark">Private</custom-label>
                `
              : null}
            ${this.project.get("status") === "archived"
              ? html`
                  <custom-label theme="outline">Archived</custom-label>
                `
              : null}
            ${this.project.get("sharedWith").length > 0
              ? html`
                  <custom-label theme="petrol">Shared</custom-label>
                `
              : null}
          </div>
          <div class="col-1">
            <project-list-item-dropdown
              @project-action-event=${event =>
                this.bubbleProjectActionEvent(
                  event.detail.eventType,
                  event.detail.project
                )}
              base-path=${this.basePath}
              .router=${this.router}
              .project=${this.project}
            ></project-list-item-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-1">
            ${this.project.get("businessLine").name}
          </div>
          <div class="col-4">
            ${this.project.get("userCreated").last_name &&
            this.project.get("userCreated").first_name
              ? `${this.project.get("userCreated").last_name}, ${
                  this.project.get("userCreated").first_name
                }`
              : html`
                  No owner name
                `}
          </div>
          <div class="col-3">
            ${this.project.get("boothTemplate")?.name}
          </div>
          <div class="col-3">
            ${this.lastModifiedDateString}
          </div>
          <div class="col-1"></div>
        </div>
      </uimc-collection-item>
    `;
  }
}

customElements.define("project-list-item", ProjectListItem);
