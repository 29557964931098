import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../shared/styles.scss";
import custom from "./app-footer.scss";

import ChangeLog from "../../../CHANGELOG.md";

import "../markdown-renderer/markdown-renderer";

class AppFooter extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
    };
  }

  static get inject() {
    return ["appVersion", "showOverlay"];
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  render() {
    return html`
      <footer
        class="app-footer padding-top-1 padding-bottom-1 padding-right-1 text-align-right"
      >
        <uimc-button
          type="link"
          icon="link-intern"
          size="small"
          .onClick=${() => {
            const log = html`
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div class="padding-left-2 padding-right-2">
                      <markdown-renderer md="${ChangeLog}"></markdown-renderer>
                    </div>
                  </div>
                </div>
              </div>
            `;
            this.showOverlay(log, "large");
          }}
          >Version ${this.appVersion}
        </uimc-button>
      </footer>
    `;
  }
}

customElements.define("app-footer", AppFooter);
