import { LitElement, html, css } from "lit";
import { classMap } from "lit/directives/class-map";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../shared/styles.scss";
import custom from "./create-project.scss";

import Api from "../../api/api-helper";

import "@shs/ui-marcom/src/js/application-bar";
import "@shs/ui-marcom/src/js/aspect-ratio";
import "@shs/ui-marcom/src/js/textarea";
import "@shs/ui-marcom/src/js/card";
import "../../custom-uimc/custom-button/custom-button";
import "../../custom-uimc/custom-select/custom-select";
import "../../custom-uimc/custom-textfield/custom-textfield";
import "../../components/app-header/app-header";
import "../../components/app-footer/app-footer";

import { handleInvalidRequest, hasAuthToken } from "../../api/helper";

class CreateProject extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      businessLines: { type: Array, attribute: false },
      boothTemplates: { type: Array, attribute: false },
      validTitle: { type: Boolean, attribute: false },

      title: { type: String, attribute: false },
      description: { type: String, attribute: false },
      businessLine: { type: Object, attribute: false },
      boothTemplate: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showModal", "showAlert", "setLoading"];
  }

  constructor() {
    super();
    this.validTitle = false;
    this.businessLines = [];
    this.boothTemplates = [];
    this.title = "";
    this.description = "";
    this.businessLine = null;
    this.boothTemplate = null;
  }

  async firstUpdated() {
    this.setLoading(true);

    try {
      this.api = Api.getInstance();
      this.businessLines = await this.api.businessLines();
      this.boothTemplates = await this.api.boothTemplates();
    } catch (error) {
      if (!hasAuthToken) {
        handleInvalidRequest(this.router, this.showAlert);
      }
    } finally {
      this.setLoading(false);
    }
  }

  get templateStandalones() {
    const standalones = [];
    this.boothTemplates.forEach(t => {
      if (t.type === "standalone" || t.type === "environment") {
        standalones.push(t);
      }
    });
    return standalones;
  }

  get templateBoothes() {
    const boothes = [];
    this.boothTemplates.forEach(t => {
      if (t.type === "booth") {
        boothes.push(t);
      }
    });

    return boothes;
  }

  get templatePlatforms() {
    const platforms = [];
    this.boothTemplates.forEach(t => {
      if (t.type === "platform") {
        platforms.push(t);
      }
    });
    return platforms;
  }

  setTitel(title) {
    this.title = title;
  }

  setDescription(description) {
    this.description = description;
  }

  setBoothTemplate(template) {
    this.boothTemplate = template;
  }

  setBusinessLine(item) {
    if (item) {
      this.businessLine = this.businessLines.find(businessLine => {
        return businessLine.id === item;
      });
    }
  }

  get businessLineItems() {
    return this.businessLines?.map(b => {
      const businessLine = {
        name: b.name,
        value: b.id,
      };

      if (b?.id === this?.businessLine?.id) {
        businessLine.isSelected = true;
      }
      return businessLine;
    });
  }

  async save() {
    let config = {};

    if (
      this.boothTemplate.get("name") !== "Standalone environment" &&
      this.boothTemplate.get("name") !== "Standalone"
    ) {
      config = {
        background: this.boothTemplate.background || "middle",
        arePeopleVisible: false,
        hasBackwallVisuals: "true",
      };
    }

    const model = {
      name: this.title,
      description: this.description,
      businessline: this.businessLine.id,
      boothtemplate: this.boothTemplate,
      config: JSON.stringify(config),
      status: "published",
    };

    this.setLoading(true);

    let project = null;

    try {
      project = await this.api.createNewProject(model);

      this.showAlert(
        "Created",
        `Project "${this.title}" has been created.`,
        "success"
      );
    } catch (error) {
      this.showAlert(
        "Saving failed",
        `Project "${this.title}" couldn't be created.`,
        "error"
      );
      this.setLoading(false);
    }

    this.router.navigate(`edit-project/${project.id}`);
  }

  render() {
    return html`
      <app-header .router=${this.router}></app-header>

      <main>
        <div class="create-project container">
          <div class="row">
            <div class="col-12">
              <h1>Create new project</h1>
              <p>
                To create a new project, please set a project title, a short
                description and select your business line or business area.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <p>
                <custom-textfield
                  id="projectTitleField"
                  pattern=".{5,}"
                  ?validate=${true}
                  label="Project title"
                  value=${this.title}
                  .onChange=${title => {
                    this.validTitle = true;
                    this.setTitel(title);
                  }}
                  .onInvalid=${() => {
                    this.validTitle = false;
                  }}
                ></custom-textfield>
              </p>
              <p>
                <custom-textfield
                  label="Project description"
                  value=${this.description}
                  .onChange=${description => {
                    this.setDescription(description);
                  }}
                ></custom-textfield>
              </p>
              <p>
                ${this.businessLineItems.length
                  ? html`
                      <custom-select
                        .onChange=${businessLine => {
                          this.setBusinessLine(businessLine);
                        }}
                        label="Business area/line"
                        info=""
                        .items="${this.businessLineItems}"
                      >
                      </custom-select>
                    `
                  : html`
                      <p>No business lines/areas could be found!</p>
                    `}
              </p>
            </div>
          </div>

          ${this.templatePlatforms?.length > 0
            ? html`
                <div class="row">
                  <div class="display-flex flex-row flex-wrap">
                    <h5 class="col-12">Platform templates</h5>
                    <p class="col-12 col-lg-9">
                      ${this.templatePlatforms[0].description}
                    </p>
                  </div>
                  <div class="display-flex flex-row flex-wrap width-100">
                    ${this.templatePlatforms.map(template => {
                      return html`
                        <div
                          class="col-12 col-md-6 col-lg-4 col-xl-3 margin-bottom-1"
                        >
                          <div class="width-100 height-100">
                            <uimc-card
                              @click=${() => this.setBoothTemplate(template)}
                            >
                              <uimc-aspect-ratio
                                slot="card-image"
                                formats='{"sm": "16x9"}'
                              >
                                <img
                                  src=${this.api.getFileSource(
                                    template.previewImage
                                  )}
                                />
                              </uimc-aspect-ratio>
                              <div class="display-flex flex-column height-100">
                                <div
                                  class="display-flex justify-content-between align-items-center margin-bottom-1"
                                >
                                  <h4
                                    class="card__title margin-top-0 margin-bottom-0"
                                  >
                                    ${template.name}
                                  </h4>
                                  <span
                                    class=${classMap({
                                      invisible:
                                        this.boothTemplate?.id !== template.id,
                                    })}
                                  >
                                    <i class="icon-check icon--success"></i>
                                  </span>
                                </div>
                                <div class="margin-top-auto">
                                  <custom-button
                                    class=${classMap({
                                      invisible:
                                        this.boothTemplate?.id === template.id,
                                    })}
                                    @click=${() =>
                                      this.setBoothTemplate(template)}
                                    >Select</custom-button
                                  >
                                </div>
                              </div>
                            </uimc-card>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                </div>
              `
            : null}
          ${this.templateStandalones?.length > 0
            ? html`
                <div class="row">
                  <div class="display-flex flex-row flex-wrap">
                    <h5 class="col-12">Standalone templates</h5>
                    <p class="col-12 col-lg-9">
                      ${this.templateStandalones[0].description}
                    </p>
                  </div>
                  <div class="display-flex flex-row flex-wrap width-100">
                    ${this.templateStandalones.map(template => {
                      return html`
                        <div
                          class="col-12 col-md-6 col-lg-4 col-xl-3 margin-bottom-1"
                        >
                          <div class="height-100 width-100">
                            <uimc-card
                              @click=${() => this.setBoothTemplate(template)}
                            >
                              <uimc-aspect-ratio
                                slot="card-image"
                                formats='{"sm": "16x9"}'
                              >
                                <img
                                  src=${this.api.getFileSource(
                                    template.previewImage
                                  )}
                                />
                              </uimc-aspect-ratio>
                              <div class="display-flex flex-column height-100">
                                <div
                                  class="display-flex justify-content-between align-items-center margin-bottom-1"
                                >
                                  <h4
                                    class="card__title margin-top-0 margin-bottom-0"
                                  >
                                    ${template.name}
                                  </h4>
                                  <span
                                    class=${classMap({
                                      invisible:
                                        this.boothTemplate?.id !== template.id,
                                    })}
                                  >
                                    <i class="icon-check icon--success"></i>
                                  </span>
                                </div>
                                <div class="margin-top-auto">
                                  <custom-button
                                    class=${classMap({
                                      invisible:
                                        this.boothTemplate?.id === template.id,
                                    })}
                                    @click=${() =>
                                      this.setBoothTemplate(template)}
                                    >Select</custom-button
                                  >
                                </div>
                              </div>
                            </uimc-card>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                </div>
              `
            : null}
          ${this.templateBoothes?.length > 0
            ? html`
                <div class="row">
                  <div class="display-flex flex-row flex-wrap">
                    <h5 class="col-12">Booth templates</h5>
                    <p class="col-12 col-lg-9">
                      ${this.templateBoothes[0].description}
                    </p>
                  </div>
                  <div class="display-flex flex-row flex-wrap width-100">
                    ${this.templateBoothes.map(template => {
                      return html`
                        <div
                          class="col-12 col-md-6 col-lg-4 col-xl-3 margin-bottom-1"
                        >
                          <div class="width-100 height-100">
                            <uimc-card
                              @click=${() => this.setBoothTemplate(template)}
                            >
                              <uimc-aspect-ratio
                                slot="card-image"
                                formats='{"sm": "16x9"}'
                              >
                                <img
                                  src=${this.api.getFileSource(
                                    template.previewImage
                                  )}
                                />
                              </uimc-aspect-ratio>
                              <div class="display-flex flex-column height-100">
                                <div
                                  class="display-flex justify-content-between align-items-center margin-bottom-1"
                                >
                                  <h4
                                    class="card__title margin-top-0 margin-bottom-0"
                                  >
                                    ${template.name}
                                  </h4>
                                  <span
                                    class=${classMap({
                                      invisible:
                                        this.boothTemplate?.id !== template.id,
                                    })}
                                  >
                                    <i class="icon-check icon--success"></i>
                                  </span>
                                </div>
                                <div class="margin-top-auto">
                                  <custom-button
                                    class=${classMap({
                                      invisible:
                                        this.boothTemplate?.id === template.id,
                                    })}
                                    @click=${() =>
                                      this.setBoothTemplate(template)}
                                    >Select</custom-button
                                  >
                                </div>
                              </div>
                            </uimc-card>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                </div>
              `
            : null}

          <div class="row margin-bottom-1">
            <div class="col-12">
              <custom-button
                @click=${() => {
                  this.showModal(
                    "Cancel project draft",
                    "You are about to leave the page without saving the changes. Do you really want to continue?",
                    "warning",
                    "Leave without saving",
                    "Return to draft",
                    "small",
                    () => {
                      this.router.navigate("project-list");
                    }
                  );
                }}
                >Cancel</custom-button
              >

              <custom-button
                .onClick=${() => this.save()}
                primary
                ?disabled=${!this.validTitle ||
                  !this.boothTemplate ||
                  !this.businessLine}
                >Create project</custom-button
              >
            </div>
          </div>
        </div>
      </main>

      <app-footer></app-footer>
    `;
  }
}

customElements.define("create-project", CreateProject);
