import { LitElement, html, css } from "lit";
import { classMap } from "lit/directives/class-map.js";

import shared from "../../shared/styles.scss";
import custom from "./validation-hints.scss";

class ValidationHints extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      patternMapper: { type: Object },
      currentInput: { type: String },
      validationHints: { type: Object },
    };
  }

  constructor() {
    super();
    this.patternMapper = [];
    this.currentInput = "";
    this.validationHints = [];
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  updated(changedProperties) {
    if (changedProperties.has("currentInput")) {
      this.getValidationHints();
    }
  }

  getValidationHints() {
    this.validationHints = this.patternMapper.map(p => {
      return { ...p, isValid: new RegExp(p.pattern).test(this.currentInput) };
    });
  }

  render() {
    return html`
      <div
        class="validation-hints ${classMap({
          "margin-bottom-1": !this.validationHints.some(vh => !vh.isValid),
        })}"
      >
        ${this.validationHints.some(vh => !vh.isValid)
          ? html`
              <div class="display-flex flex-column">
                ${this.validationHints.map(
                  vh => html`
                    <div
                      class=${classMap({
                        "validation-hints__hintText": true,
                        "is-valid": vh.isValid,
                        "is-invalid": !vh.isValid,
                      })}
                    >
                      ${vh.hintText}
                    </div>
                  `
                )}
              </div>
            `
          : null}
      </div>
    `;
  }
}

customElements.define("validation-hints", ValidationHints);
