import { css } from "lit";

export default css`
  @charset "UTF-8";
  p {
    font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto",
      "Droid Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    margin-top: 0;
    margin-bottom: 1.6666666667rem;
    text-rendering: optimizeLegibility;
  }

  p.lead {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.66667rem;
    margin-top: 1.6666666667rem;
    margin-bottom: 1.6666666667rem;
  }

  @media only screen and (min-width: 48em) {
    p.lead {
      font-size: 1.1111111rem;
      font-weight: 600;
      line-height: 1.66667rem;
      margin-top: 1.6111111111rem;
      margin-bottom: 1.7222222222rem;
    }
  }

  p.small {
    font-size: 0.8888999rem;
    line-height: 1.38889rem;
    margin-bottom: 1.38889rem;
  }

  p.xsmall {
    font-size: 0.7777778rem;
    line-height: 1.11111rem;
    margin-bottom: 1.11111rem;
  }

  button {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
    border-radius: 0;
    background: transparent;
    color: inherit;
    line-height: inherit;
    transition: none;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }

  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  [class^="icon-"]::before,
  [class*=" icon-"]::before {
    font-family: "SH Icon" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    margin-left: 0.2em;
    text-align: center;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-360-degrees::before,
  .icon-360-grad {
    content: "\\e847";
  }

  /* '' */

  .icon-apps::before,
  .icon-nine-dots::before {
    content: "\\e85a";
  }

  /* '' */

  .icon-arrow-down-large-light::before {
    content: "\\e874";
  }

  /* '' */

  .icon-arrow-down-large::before,
  .icon-arrow-down::before {
    content: "\\e81f";
  }

  /* '' */

  .icon-arrow-down-medium-light::before {
    content: "\\e864";
  }

  /* '' */

  .icon-arrow-down-medium::before {
    content: "\\e83f";
  }

  /* '' */

  .icon-arrow-down-small-light::before {
    content: "\\e863";
  }

  /* '' */

  .icon-arrow-down-small::before,
  .icon-dropdown::before,
  .icon-drop-down::before {
    content: "\\e803";
  }

  /* '' */

  .icon-arrow-left-large-light::before {
    content: "\\e87d";
  }

  /* '' */

  .icon-arrow-left-large::before,
  .icon-arrow-left::before {
    content: "\\e81e";
  }

  /* '' */

  .icon-arrow-left-medium-light::before {
    content: "\\e862";
  }

  /* '' */

  .icon-arrow-left-medium::before {
    content: "\\e833";
  }

  /* '' */

  .icon-arrow-left-small-light::before {
    content: "\\e850";
  }

  /* '' */

  .icon-arrow-left-small::before {
    content: "\\e806";
  }

  /* '' */

  .icon-arrow-long-left::before {
    content: "\\e842";
  }

  /* '' */

  .icon-arrow-long-right::before {
    content: "\\e854";
  }

  /* '' */

  .icon-arrow-right-large-light::before {
    content: "\\e87a";
  }

  /* '' */

  .icon-arrow-right-large::before,
  .icon-arrow-right::before {
    content: "\\e81c";
  }

  /* '' */

  .icon-arrow-right-medium-light::before {
    content: "\\e86e";
  }

  /* '' */

  .icon-arrow-right-medium::before,
  .icon-link-intern::before,
  .icon-intern::before {
    content: "\\e827";
  }

  /* '' */

  .icon-arrow-right-small-light::before {
    content: "\\e875";
  }

  /* '' */

  .icon-arrow-right-small::before {
    content: "\\e80a";
  }

  /* '' */

  .icon-arrow-to-top-light::before {
    content: "\\e85d";
  }

  /* '' */

  .icon-arrow-to-top::before {
    content: "\\e84d";
  }

  /* '' */

  .icon-arrow-up-large-light::before {
    content: "\\e826";
  }

  /* '' */

  .icon-arrow-up-large::before,
  .icon-arrow-top::before {
    content: "\\e81d";
  }

  /* '' */

  .icon-arrow-up-medium-light::before {
    content: "\\e878";
  }

  /* '' */

  .icon-arrow-up-medium::before,
  .icon-arrow-drop-up::before {
    content: "\\e84f";
  }

  /* '' */

  .icon-arrow-up-small-light::before {
    content: "\\e867";
  }

  /* '' */

  .icon-arrow-up-small::before {
    content: "\\e840";
  }

  /* '' */

  .icon-arrow::before,
  .icon-link-extern::before,
  .icon-extern::before {
    content: "\\e834";
  }

  /* '' */

  .icon-at::before {
    content: "\\e83e";
  }

  /* '' */

  .icon-bell::before {
    content: "\\e855";
  }

  /* '' */

  .icon-book::before {
    content: "\\e810";
  }

  /* '' */

  .icon-bookmark::before {
    content: "\\e802";
  }

  /* '' */

  .icon-bullet-list::before {
    content: "\\e82f";
  }

  /* '' */

  .icon-calculate::before {
    content: "\\e87e";
  }

  /* '' */

  .icon-calendar::before {
    content: "\\e804";
  }

  /* '' */

  .icon-chat::before {
    content: "\\e846";
  }

  /* '' */

  .icon-check::before,
  .icon-checkmark::before {
    content: "\\e818";
  }

  /* '' */

  .icon-close-fullscreen::before {
    content: "\\e839";
  }

  /* '' */

  .icon-close::before {
    content: "\\e817";
  }

  /* '' */

  .icon-comment::before {
    content: "\\e843";
  }

  /* '' */

  .icon-delete::before {
    content: "\\e86c";
  }

  /* '' */

  .icon-department::before {
    content: "\\e86d";
  }

  /* '' */

  .icon-document::before {
    content: "\\e814";
  }

  /* '' */

  .icon-dots-ellipsis-horizontal::before {
    content: "\\e856";
  }

  /* '' */

  .icon-dots-ellipsis-vertical::before {
    content: "\\e857";
  }

  /* '' */

  .icon-double-arrow-backward::before {
    content: "\\e828";
  }

  /* '' */

  .icon-double-arrow-down-light::before {
    content: "\\e837";
  }

  /* '' */

  .icon-double-arrow-down::before {
    content: "\\e830";
  }

  /* '' */

  .icon-double-arrow-forward::before {
    content: "\\e83a";
  }

  /* '' */

  .icon-double-arrow-up-light::before {
    content: "\\e861";
  }

  /* '' */

  .icon-double-arrow-up::before {
    content: "\\e801";
  }

  /* '' */

  .icon-download::before {
    content: "\\e823";
  }

  /* '' */

  .icon-duration::before {
    content: "\\e873";
  }

  /* '' */

  .icon-edit::before {
    content: "\\e87c";
  }

  /* '' */

  .icon-email::before {
    content: "\\e815";
  }

  /* '' */

  .icon-equipment-name::before {
    content: "\\e838";
  }

  /* '' */

  .icon-facebook::before {
    content: "\\e821";
  }

  /* '' */

  .icon-favorite::before,
  .icon-star::before,
  .icon-rating::before {
    content: "\\e812";
  }

  /* '' */

  .icon-favorite-off::before,
  .icon-star-off::before {
    content: "\\e859";
  }

  /* '' */

  .icon-feed::before {
    content: "\\e841";
  }

  /* '' */

  .icon-filter::before {
    content: "\\e858";
  }

  /* '' */

  .icon-fullscreen::before,
  .icon-large-view::before {
    content: "\\e809";
  }

  /* '' */

  .icon-google::before {
    content: "\\e829";
  }

  /* '' */

  .icon-heart::before {
    content: "\\e824";
  }

  /* '' */

  .icon-help::before {
    content: "\\e877";
  }

  /* '' */

  .icon-hide::before {
    content: "\\e83b";
  }

  /* '' */

  .icon-home::before,
  .icon-house::before {
    content: "\\e807";
  }

  /* '' */

  .icon-hospital::before {
    content: "\\e81b";
  }

  /* '' */

  .icon-id-card::before {
    content: "\\e871";
  }

  /* '' */

  .icon-information::before,
  .icon-info::before {
    content: "\\e835";
  }

  /* '' */

  .icon-instagram::before {
    content: "\\e852";
  }

  /* '' */

  .icon-label::before {
    content: "\\e876";
  }

  /* '' */

  .icon-layout-2x2::before {
    content: "\\e819";
  }

  /* '' */

  .icon-layout-3x2::before {
    content: "\\e81a";
  }

  /* '' */

  .icon-layout-3x3::before {
    content: "\\e851";
  }

  /* '' */

  .icon-layout-list::before {
    content: "\\e820";
  }

  /* '' */

  .icon-level-up::before {
    content: "\\e87b";
  }

  /* '' */

  .icon-like::before {
    content: "\\e87f";
  }

  /* '' */

  .icon-linkedin::before {
    content: "\\e822";
  }

  /* '' */

  .icon-location-pin::before,
  .icon-pin::before {
    content: "\\e80c";
  }

  /* '' */

  .icon-locked::before {
    content: "\\e879";
  }

  /* '' */

  .icon-log-in::before {
    content: "\\e831";
  }

  /* '' */

  .icon-log-out::before {
    content: "\\e832";
  }

  /* '' */

  .icon-menu::before,
  .icon-burger::before {
    content: "\\e805";
  }

  /* '' */

  .icon-minus::before {
    content: "\\e82e";
  }

  /* '' */

  .icon-movie::before {
    content: "\\e868";
  }

  /* '' */

  .icon-pause::before {
    content: "\\e825";
  }

  /* '' */

  .icon-phone::before,
  .icon-tel::before {
    content: "\\e80b";
  }

  /* '' */

  .icon-play::before {
    content: "\\e80d";
  }

  /* '' */

  .icon-plus::before {
    content: "\\e82d";
  }

  /* '' */

  .icon-podcast::before {
    content: "\\e85b";
  }

  /* '' */

  .icon-portfolio::before {
    content: "\\e86b";
  }

  /* '' */

  .icon-print::before {
    content: "\\e816";
  }

  /* '' */

  .icon-private::before {
    content: "\\e85e";
  }

  /* '' */

  .icon-qr-bar-code::before {
    content: "\\e860";
  }

  /* '' */

  .icon-question::before {
    content: "\\e866";
  }

  /* '' */

  .icon-reload::before,
  .icon-replay::before {
    content: "\\e853";
  }

  /* '' */

  .icon-scale-down::before {
    content: "\\e83d";
  }

  /* '' */

  .icon-scale-up::before {
    content: "\\e83c";
  }

  /* '' */

  .icon-search::before {
    content: "\\e80f";
  }

  /* '' */

  .icon-send-message::before {
    content: "\\e865";
  }

  /* '' */

  .icon-settings::before {
    content: "\\e85f";
  }

  /* '' */

  .icon-share::before {
    content: "\\e811";
  }

  /* '' */

  .icon-shopping-cart::before,
  .icon-basket::before {
    content: "\\e80e";
  }

  /* '' */

  .icon-show::before {
    content: "\\e869";
  }

  /* '' */

  .icon-slider-drag-drop::before,
  .icon-slider::before {
    content: "\\e260";
  }

  /* '' */

  .icon-snippet::before {
    content: "\\e85c";
  }

  /* '' */

  .icon-speakers-off::before,
  .icon-volume-muted::before,
  .icon-volume-off::before {
    content: "\\e82a";
  }

  /* '' */

  .icon-speakers-on::before,
  .icon-volume-up::before,
  .icon-volume-down::before {
    content: "\\e82b";
  }

  /* '' */

  .icon-start-video-call::before {
    content: "\\e86f";
  }

  /* '' */

  .icon-support::before {
    content: "\\e870";
  }

  /* '' */

  .icon-text-check::before {
    content: "\\e844";
  }

  /* '' */

  .icon-time::before {
    content: "\\e872";
  }

  /* '' */

  .icon-tool::before,
  .icon-settings::before {
    content: "\\e845";
  }

  /* '' */

  .icon-twitter::before {
    content: "\\e813";
  }

  /* '' */

  .icon-upload::before {
    content: "\\e82c";
  }

  /* '' */

  .icon-user::before {
    content: "\\e800";
  }

  /* '' */

  .icon-video-hd::before {
    content: "\\e848";
  }

  /* '' */

  .icon-video-skip-backward::before {
    content: "\\e849";
  }

  /* '' */

  .icon-video-skip-forward::before {
    content: "\\e84a";
  }

  /* '' */

  .icon-video-stop::before {
    content: "\\e84b";
  }

  /* '' */

  .icon-view-carousel::before {
    content: "\\e84c";
  }

  /* '' */

  .icon-warning::before,
  .icon-exclamationmark::before {
    content: "\\e836";
  }

  /* '' */

  .icon-world::before,
  .icon-globe::before {
    content: "\\e808";
  }

  /* '' */

  .icon-youtube::before {
    content: "\\e84e";
  }

  /* '' */

  .alert {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -1px 4px rgba(0, 0, 0, 0.05);
    height: fit-content;
    padding: 0.8333333333rem;
    position: relative;
    display: none;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-out;
    max-height: 100vh;
    overflow: visible;
    min-width: 1px;
    width: auto;
  }

  .bg-color-black .alert,
  .dark .alert,
  :host([theme="dark"]) .alert {
    background-color: #1a1a1a;
    color: #e6e6e6;
    box-shadow: none;
  }

  .bg-color-black .alert__close i::before,
  .dark .alert__close i::before,
  :host([theme="dark"]) .alert__close i::before {
    color: #999999;
    margin: 0;
  }

  .bg-color-black .alert__close:hover i::before,
  .dark .alert__close:hover i::before,
  :host([theme="dark"]) .alert__close:hover i::before {
    color: #b3b3b3;
  }

  .bg-color-black .alert__border--info,
  .dark .alert__border--info,
  :host([theme="dark"]) .alert__border--info {
    border-color: #ffffff;
  }

  .gray .alert,
  :host([theme="gray"]) .alert {
    background-color: #ededed;
    color: #1a1a1a;
    box-shadow: none;
  }

  .gray .alert__close i::before,
  :host([theme="gray"]) .alert__close i::before {
    color: #666666;
    margin: 0;
  }

  .gray .alert__close:hover i::before,
  :host([theme="gray"]) .alert__close:hover i::before {
    color: #4d4d4d;
  }

  .alert__border {
    border-left: 0.2222222222rem solid;
    border-color: #b3b3b3;
  }

  .alert__border--info {
    border-color: #000000;
  }

  .alert__border--success {
    border-color: #009a38;
  }

  .alert__border--warning {
    border-color: #ffd200;
  }

  .alert__border--error {
    border-color: #e7001d;
  }

  .alert__close {
    font-size: 1.2222222rem;
    float: right;
    margin-bottom: 1.1111111111rem;
    margin-left: auto;
    min-width: auto;
    height: fit-content;
    line-height: 1;
    text-align: end;
  }

  .alert__close i::before {
    color: #666666;
    margin: 0;
  }

  .alert__close:hover i::before {
    color: #4d4d4d;
  }

  .alert__header {
    display: flex;
    width: 100%;
  }

  .alert__body > *:first-child {
    margin-top: 0;
  }

  .alert__body > *:last-child {
    margin-bottom: 0;
  }

  .alert__container {
    padding: 0;
    padding-right: 1.0416666667rem;
  }

  .alert__title {
    font-size: 1.1111111rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .alert__indicator {
    display: none;
    padding-left: 0.8333333333rem;
    margin-top: -0.15rem;
  }

  .alert--info .alert__indicator {
    display: inline-block;
  }

  .alert--info .alert__indicator::after {
    font-family: "SH Icon";
    font-weight: normal !important;
    font-style: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    vertical-align: baseline;
    content: "\\e835";
    background-color: #000000;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: 1.6666666667rem;
    height: 1.6666666667rem;
    line-height: 1.7;
    border-radius: 50%;
    content: "\\e835";
  }

  .bg-color-black .alert--info .alert__indicator::after,
  .dark .alert--info .alert__indicator::after,
  :host([theme="dark"]) .alert--info .alert__indicator::after {
    font-family: "SH Icon";
    font-weight: normal !important;
    font-style: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    vertical-align: baseline;
    content: "\\e835";
    background-color: #ffffff;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: 1.6666666667rem;
    height: 1.6666666667rem;
    line-height: 1.7;
    border-radius: 50%;
    content: "\\e835";
  }

  .alert--success .alert__indicator {
    display: inline-block;
  }

  .alert--success .alert__indicator::after {
    font-family: "SH Icon";
    font-weight: normal !important;
    font-style: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    vertical-align: baseline;
    content: "\\e818";
    background-color: #009a38;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: 1.6666666667rem;
    height: 1.6666666667rem;
    line-height: 1.7;
    border-radius: 50%;
    content: "\\e818";
  }

  .alert--warning .alert__indicator {
    display: inline-block;
  }

  .alert--warning .alert__indicator::after {
    font-family: "SH Icon";
    font-weight: normal !important;
    font-style: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    vertical-align: baseline;
    content: "\\e836";
    background-color: #ffd200;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: 1.6666666667rem;
    height: 1.6666666667rem;
    line-height: 1.7;
    border-radius: 50%;
    content: "\\e836";
    color: #000000;
  }

  .alert--error .alert__indicator {
    display: inline-block;
  }

  .alert--error .alert__indicator::after {
    font-family: "SH Icon";
    font-weight: normal !important;
    font-style: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    vertical-align: baseline;
    content: "\\e817";
    background-color: #e7001d;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: 1.6666666667rem;
    height: 1.6666666667rem;
    line-height: 1.7;
    border-radius: 50%;
    content: "\\e817";
  }

  .alert__overlay {
    display: flex;
    height: fit-content;
    width: 20.8333333333rem;
    z-index: 4444;
    position: fixed;
    outline: 0;
  }

  .alert__overlay--in-top-left {
    top: 0;
    left: 0;
    display: block;
    animation: fadeInTopLeft 0.3s ease-out;
  }

  @keyframes fadeInTopLeft {
    0% {
      left: -5%;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }

  .alert__overlay--in-top-right {
    top: 0;
    right: 0;
    display: block;
    animation: fadeInTopRight 0.3s ease-out;
  }

  @keyframes fadeInTopRight {
    0% {
      right: -5%;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }

  .alert__overlay--in-bottom-left {
    bottom: 0;
    left: 0;
    display: block;
    animation: fadeInBottomLeft 0.3s ease-out;
  }

  @keyframes fadeInBottomLeft {
    0% {
      left: -5%;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }

  .alert__overlay--in-bottom-right {
    bottom: 0;
    right: 0;
    display: block;
    animation: fadeInBottomRight 0.3s ease-out;
  }

  @keyframes fadeInBottomRight {
    0% {
      right: -5%;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }

  .alert__overlay--out-top-left {
    left: 0;
    animation: fadeOutTopLeft 0.21s ease-out;
  }

  @keyframes fadeOutTopLeft {
    0% {
      top: 0;
      opacity: 1;
    }
    100% {
      top: 10%;
      opacity: 0;
    }
  }

  .alert__overlay--out-top-right {
    right: 0;
    animation: fadeOutTopRight 0.21s ease-out;
  }

  @keyframes fadeOutTopRight {
    0% {
      top: 0;
      opacity: 1;
    }
    100% {
      top: 10%;
      opacity: 0;
    }
  }

  .alert__overlay--out-bottom-left {
    left: 0;
    animation: fadeOutBottomLeft 0.21s ease-out;
  }

  @keyframes fadeOutBottomLeft {
    0% {
      bottom: 0;
      opacity: 1;
    }
    100% {
      bottom: -5%;
      opacity: 0;
    }
  }

  .alert__overlay--out-bottom-right {
    right: 0;
    animation: fadeOutBottomRight 0.21s ease-out;
  }

  @keyframes fadeOutBottomRight {
    0% {
      bottom: 0;
      opacity: 1;
    }
    100% {
      bottom: -5%;
      opacity: 0;
    }
  }

  .alert.is-hidden {
    transition: transform 0.21s ease-out, max-height 0.3s ease 2s;
    pointer-events: none;
    max-height: 0;
    opacity: 0;
  }

  .alert.is-open {
    display: flex;
  }

  .button {
    background-color: #ffffff;
    color: #333333;
    border-color: #b3b3b3;
    border-radius: 100px;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    min-width: 6.2222222222rem;
    padding: 0.5555555556rem 1.1111111111rem 0.5555555556rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.24s linear;
    -webkit-appearance: none;
    /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  Provide basic, default focus styles.
  */
    /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
    /*
  Define a strong focus indicator for keyboard focus.
  */
    /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  }

  .button:disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }

  .button:hover {
    background-color: #f2f2f2;
    color: #333333;
  }

  .button:focus {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
  }

  .js-focus-visible .button:focus:not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .js-focus-visible .button:focus.focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
  }

  .button:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .button:focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
  }

  .button:active {
    background-color: #f2f2f2;
    /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
    box-shadow: none;
  }

  .button:active:not(:focus-visible) {
    box-shadow: none;
  }

  .button::before,
  .button i::before {
    color: #cf4b00;
    margin-right: 0.25rem;
    margin-left: -0.3em;
  }

  [class*="bg-color-gray-shade"] .button,
  .bg-color-black .button,
  :host([theme="dark"]) .button {
    color: #ffffff;
    border-color: #4d4d4d;
    background-color: #1a1a1a;
    /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  Provide basic, default focus styles.
  */
    /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
    /*
  Define a strong focus indicator for keyboard focus.
  */
    /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  }

  [class*="bg-color-gray-shade"] .button:disabled,
  .bg-color-black .button:disabled,
  :host([theme="dark"]) .button:disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }

  [class*="bg-color-gray-shade"] .button:hover,
  .bg-color-black .button:hover,
  :host([theme="dark"]) .button:hover {
    background-color: #3c3c3c;
  }

  [class*="bg-color-gray-shade"] .button:focus,
  .bg-color-black .button:focus,
  :host([theme="dark"]) .button:focus {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
  }

  .js-focus-visible
    [class*="bg-color-gray-shade"]
    .button:focus:not(.focus-visible),
  .js-focus-visible .bg-color-black .button:focus:not(.focus-visible),
  .js-focus-visible :host([theme="dark"]) .button:focus:not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .js-focus-visible [class*="bg-color-gray-shade"] .button:focus.focus-visible,
  .js-focus-visible .bg-color-black .button:focus.focus-visible,
  .js-focus-visible :host([theme="dark"]) .button:focus.focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
  }

  [class*="bg-color-gray-shade"] .button:focus:not(:focus-visible),
  .bg-color-black .button:focus:not(:focus-visible),
  :host([theme="dark"]) .button:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }

  [class*="bg-color-gray-shade"] .button:focus-visible,
  .bg-color-black .button:focus-visible,
  :host([theme="dark"]) .button:focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
  }

  [class*="bg-color-gray-shade"] .button:active,
  .bg-color-black .button:active,
  :host([theme="dark"]) .button:active {
    background-color: #3c3c3c;
    /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
  }

  [class*="bg-color-gray-shade"] .button:active:not(:focus-visible),
  .bg-color-black .button:active:not(:focus-visible),
  :host([theme="dark"]) .button:active:not(:focus-visible) {
    box-shadow: none;
  }

  [class*="bg-color-gray-shade"] .button::before,
  [class*="bg-color-gray-shade"] .button i::before,
  .bg-color-black .button::before,
  .bg-color-black .button i::before,
  :host([theme="dark"]) .button::before,
  :host([theme="dark"]) .button i::before {
    color: #ffffff;
  }

  .button--link {
    border: 0;
    min-width: 0;
    min-width: 0;
    background: transparent;
    cursor: pointer;
    /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  Provide basic, default focus styles.
  */
    /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
    /*
  Define a strong focus indicator for keyboard focus.
  */
    /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  }

  .button--link:hover {
    background-color: #f2f2f2;
  }

  .button--link:focus {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
  }

  .js-focus-visible .button--link:focus:not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .js-focus-visible .button--link:focus.focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
  }

  .button--link:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .button--link:focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
  }

  .button--link::before,
  .button--link i::before {
    margin-right: 0.25rem;
    color: #cf4b00;
  }

  [class*="bg-color-gray-tint"] .button--link,
  :host([theme="gray"]) .button--link {
    /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  }

  [class*="bg-color-gray-tint"] .button--link:hover,
  :host([theme="gray"]) .button--link:hover {
    background-color: #e1e1e1;
  }

  [class*="bg-color-gray-shade"] .button--link,
  .bg-color-black .button--link,
  :host([theme="dark"]) .button--link {
    background: transparent;
    /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  }

  [class*="bg-color-gray-shade"] .button--link:hover,
  .bg-color-black .button--link:hover,
  :host([theme="dark"]) .button--link:hover {
    background-color: #262626;
  }

  [class*="bg-color-gray-shade"] .button--link:focus,
  .bg-color-black .button--link:focus,
  :host([theme="dark"]) .button--link:focus {
    color: #ffffff;
  }

  [class*="bg-color-gray-shade"] .button--link:focus::before,
  [class*="bg-color-gray-shade"] .button--link:focus i::before,
  .bg-color-black .button--link:focus::before,
  .bg-color-black .button--link:focus i::before,
  :host([theme="dark"]) .button--link:focus::before,
  :host([theme="dark"]) .button--link:focus i::before {
    color: #ffffff;
  }

  [class*="bg-color-gray-shade"] .button--link:active,
  .bg-color-black .button--link:active,
  :host([theme="dark"]) .button--link:active {
    /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
  }

  [class*="bg-color-gray-shade"] .button--link:active:hover,
  .bg-color-black .button--link:active:hover,
  :host([theme="dark"]) .button--link:active:hover {
    background-color: #262626;
  }

  .button--primary {
    color: #ffffff;
    background-color: #cf4b00;
    border-color: #cf4b00;
    /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  Provide basic, default focus styles.
  */
    /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
    /*
  Define a strong focus indicator for keyboard focus.
  */
    /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  }

  .button--primary:hover {
    background-color: #ba4400;
    border-color: #ba4400;
    color: #ffffff;
  }

  .button--primary:focus {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
    color: #ffffff;
  }

  .js-focus-visible .button--primary:focus:not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .js-focus-visible .button--primary:focus.focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
    color: #ffffff;
  }

  .button--primary:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .button--primary:focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
    color: #ffffff;
  }

  .button--primary:active {
    background-color: #ba4400;
    border-color: #ba4400;
    /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
    color: #ffffff;
  }

  .button--primary:active:not(:focus-visible) {
    box-shadow: none;
  }

  [class*="bg-color-gray-shade"] .button--primary,
  .bg-color-black .button--primary,
  :host([theme="dark"]) .button--primary {
    color: #ffffff;
    background-color: #cf4b00;
    border-color: #cf4b00;
    /*
  Override focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  Provide basic, default focus styles.
  */
    /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
  */
    /*
  Define a strong focus indicator for keyboard focus.
  */
    /*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
  */
    /*
  If :focus-visible is supported on this
  platform, provide enhanced focus styles for keyboard
  focus.
  */
  }

  [class*="bg-color-gray-shade"] .button--primary:hover,
  .bg-color-black .button--primary:hover,
  :host([theme="dark"]) .button--primary:hover {
    background-color: #ba4400;
    border-color: #ba4400;
    color: #ffffff;
  }

  [class*="bg-color-gray-shade"] .button--primary:focus,
  .bg-color-black .button--primary:focus,
  :host([theme="dark"]) .button--primary:focus {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
    color: #ffffff;
  }

  .js-focus-visible
    [class*="bg-color-gray-shade"]
    .button--primary:focus:not(.focus-visible),
  .js-focus-visible .bg-color-black .button--primary:focus:not(.focus-visible),
  .js-focus-visible
    :host([theme="dark"])
    .button--primary:focus:not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .js-focus-visible
    [class*="bg-color-gray-shade"]
    .button--primary:focus.focus-visible,
  .js-focus-visible .bg-color-black .button--primary:focus.focus-visible,
  .js-focus-visible :host([theme="dark"]) .button--primary:focus.focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
    color: #ffffff;
  }

  [class*="bg-color-gray-shade"] .button--primary:focus:not(:focus-visible),
  .bg-color-black .button--primary:focus:not(:focus-visible),
  :host([theme="dark"]) .button--primary:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }

  [class*="bg-color-gray-shade"] .button--primary:focus-visible,
  .bg-color-black .button--primary:focus-visible,
  :host([theme="dark"]) .button--primary:focus-visible {
    box-shadow: 0 0 0 2px rgba(236, 102, 2, 0.5);
    outline: none;
    color: #ffffff;
  }

  [class*="bg-color-gray-shade"] .button--primary:active,
  .bg-color-black .button--primary:active,
  :host([theme="dark"]) .button--primary:active {
    background-color: #ba4400;
    border-color: #ba4400;
    /*
    Remove default focus styles for mouse users ONLY if
    :focus-visible is supported on this platform.
    */
    color: #ffffff;
  }

  [class*="bg-color-gray-shade"] .button--primary:active:not(:focus-visible),
  .bg-color-black .button--primary:active:not(:focus-visible),
  :host([theme="dark"]) .button--primary:active:not(:focus-visible) {
    box-shadow: none;
  }

  .button--primary::before,
  .button--primary i::before {
    color: #ffffff;
  }

  .button--control {
    color: #ec6602;
    min-width: inherit;
    padding: 0;
    width: 2.1111111111rem;
    height: 2.1111111111rem;
    overflow: hidden;
    font-size: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .button--control i {
    width: 100%;
  }

  .button--control::before,
  .button--control i::before {
    margin-right: 0;
    margin-left: 0;
    font-size: 1rem;
  }

  .button--control:hover,
  .button--control:focus {
    color: #ec6602;
  }

  .button--control.button--small {
    padding: 0;
    width: 1.7777777778rem;
    height: 1.7777777778rem;
    font-size: 0;
  }

  .button--control.button--small::before,
  .button--control.button--small i::before {
    font-size: 0.7777778rem;
  }

  .button--small {
    font-size: 0.7777778rem;
    padding: 0.3888888889rem 0.6666666667rem;
    min-width: inherit;
  }

  .display-none {
    display: none !important;
  }

  .display-inline {
    display: inline !important;
  }

  .display-inline-block {
    display: inline-block !important;
  }

  .display-block {
    display: block !important;
  }

  .display-flex {
    display: flex !important;
  }

  .container {
    width: 100%;
    padding-right: 0.833333333rem;
    padding-left: 0.833333333rem;
    margin-right: auto;
    margin-left: auto;
  }

  @media only screen and (min-width: 36em) {
    .container {
      max-width: 30em;
    }
  }

  @media only screen and (min-width: 48em) {
    .container {
      max-width: 40em;
    }
  }

  @media only screen and (min-width: 62em) {
    .container {
      max-width: 53.333em;
    }
  }

  @media only screen and (min-width: 75em) {
    .container {
      max-width: 80em;
    }
  }

  .container-fluid {
    width: 100%;
    padding-right: 0.833333333rem;
    padding-left: 0.833333333rem;
    margin-right: auto;
    margin-left: auto;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.833333333rem;
    margin-left: -0.833333333rem;
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 0.833333333rem;
    padding-left: 0.833333333rem;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-first {
    order: -1;
  }

  .order-last {
    order: 13;
  }

  .order-0 {
    order: 0;
  }

  .order-1 {
    order: 1;
  }

  .order-2 {
    order: 2;
  }

  .order-3 {
    order: 3;
  }

  .order-4 {
    order: 4;
  }

  .order-5 {
    order: 5;
  }

  .order-6 {
    order: 6;
  }

  .order-7 {
    order: 7;
  }

  .order-8 {
    order: 8;
  }

  .order-9 {
    order: 9;
  }

  .order-10 {
    order: 10;
  }

  .order-11 {
    order: 11;
  }

  .order-12 {
    order: 12;
  }

  .offset-1 {
    margin-left: 8.3333333333%;
  }

  .offset-2 {
    margin-left: 16.6666666667%;
  }

  .offset-3 {
    margin-left: 25%;
  }

  .offset-4 {
    margin-left: 33.3333333333%;
  }

  .offset-5 {
    margin-left: 41.6666666667%;
  }

  .offset-6 {
    margin-left: 50%;
  }

  .offset-7 {
    margin-left: 58.3333333333%;
  }

  .offset-8 {
    margin-left: 66.6666666667%;
  }

  .offset-9 {
    margin-left: 75%;
  }

  .offset-10 {
    margin-left: 83.3333333333%;
  }

  .offset-11 {
    margin-left: 91.6666666667%;
  }

  @media (min-width: 36em) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sm-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    .col-sm-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-sm-5 {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }
    .col-sm-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }
    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }
    .col-sm-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      order: -1;
    }
    .order-sm-last {
      order: 13;
    }
    .order-sm-0 {
      order: 0;
    }
    .order-sm-1 {
      order: 1;
    }
    .order-sm-2 {
      order: 2;
    }
    .order-sm-3 {
      order: 3;
    }
    .order-sm-4 {
      order: 4;
    }
    .order-sm-5 {
      order: 5;
    }
    .order-sm-6 {
      order: 6;
    }
    .order-sm-7 {
      order: 7;
    }
    .order-sm-8 {
      order: 8;
    }
    .order-sm-9 {
      order: 9;
    }
    .order-sm-10 {
      order: 10;
    }
    .order-sm-11 {
      order: 11;
    }
    .order-sm-12 {
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.3333333333%;
    }
    .offset-sm-2 {
      margin-left: 16.6666666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.3333333333%;
    }
    .offset-sm-5 {
      margin-left: 41.6666666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.3333333333%;
    }
    .offset-sm-8 {
      margin-left: 66.6666666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.3333333333%;
    }
    .offset-sm-11 {
      margin-left: 91.6666666667%;
    }
  }

  @media (min-width: 48em) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-md-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    .col-md-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-md-5 {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }
    .col-md-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }
    .col-md-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      order: -1;
    }
    .order-md-last {
      order: 13;
    }
    .order-md-0 {
      order: 0;
    }
    .order-md-1 {
      order: 1;
    }
    .order-md-2 {
      order: 2;
    }
    .order-md-3 {
      order: 3;
    }
    .order-md-4 {
      order: 4;
    }
    .order-md-5 {
      order: 5;
    }
    .order-md-6 {
      order: 6;
    }
    .order-md-7 {
      order: 7;
    }
    .order-md-8 {
      order: 8;
    }
    .order-md-9 {
      order: 9;
    }
    .order-md-10 {
      order: 10;
    }
    .order-md-11 {
      order: 11;
    }
    .order-md-12 {
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.3333333333%;
    }
    .offset-md-2 {
      margin-left: 16.6666666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.3333333333%;
    }
    .offset-md-5 {
      margin-left: 41.6666666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.3333333333%;
    }
    .offset-md-8 {
      margin-left: 66.6666666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.3333333333%;
    }
    .offset-md-11 {
      margin-left: 91.6666666667%;
    }
  }

  @media (min-width: 62em) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-lg-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    .col-lg-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-lg-5 {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }
    .col-lg-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }
    .col-lg-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      order: -1;
    }
    .order-lg-last {
      order: 13;
    }
    .order-lg-0 {
      order: 0;
    }
    .order-lg-1 {
      order: 1;
    }
    .order-lg-2 {
      order: 2;
    }
    .order-lg-3 {
      order: 3;
    }
    .order-lg-4 {
      order: 4;
    }
    .order-lg-5 {
      order: 5;
    }
    .order-lg-6 {
      order: 6;
    }
    .order-lg-7 {
      order: 7;
    }
    .order-lg-8 {
      order: 8;
    }
    .order-lg-9 {
      order: 9;
    }
    .order-lg-10 {
      order: 10;
    }
    .order-lg-11 {
      order: 11;
    }
    .order-lg-12 {
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.3333333333%;
    }
    .offset-lg-2 {
      margin-left: 16.6666666667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.3333333333%;
    }
    .offset-lg-5 {
      margin-left: 41.6666666667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.3333333333%;
    }
    .offset-lg-8 {
      margin-left: 66.6666666667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.3333333333%;
    }
    .offset-lg-11 {
      margin-left: 91.6666666667%;
    }
  }

  @media (min-width: 75em) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-xl-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    .col-xl-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-xl-5 {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }
    .col-xl-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }
    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }
    .col-xl-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      order: -1;
    }
    .order-xl-last {
      order: 13;
    }
    .order-xl-0 {
      order: 0;
    }
    .order-xl-1 {
      order: 1;
    }
    .order-xl-2 {
      order: 2;
    }
    .order-xl-3 {
      order: 3;
    }
    .order-xl-4 {
      order: 4;
    }
    .order-xl-5 {
      order: 5;
    }
    .order-xl-6 {
      order: 6;
    }
    .order-xl-7 {
      order: 7;
    }
    .order-xl-8 {
      order: 8;
    }
    .order-xl-9 {
      order: 9;
    }
    .order-xl-10 {
      order: 10;
    }
    .order-xl-11 {
      order: 11;
    }
    .order-xl-12 {
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.3333333333%;
    }
    .offset-xl-2 {
      margin-left: 16.6666666667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.3333333333%;
    }
    .offset-xl-5 {
      margin-left: 41.6666666667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.3333333333%;
    }
    .offset-xl-8 {
      margin-left: 66.6666666667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.3333333333%;
    }
    .offset-xl-11 {
      margin-left: 91.6666666667%;
    }
  }

  .margin-auto {
    margin: auto !important;
  }

  .margin-0 {
    margin: 0rem !important;
  }

  .margin-1 {
    margin: 1.666666666rem !important;
  }

  .margin-2 {
    margin: 3.333333332rem !important;
  }

  .margin-3 {
    margin: 4.999999998rem !important;
  }

  .margin-4 {
    margin: 6.666666664rem !important;
  }

  .margin-top-auto {
    margin-top: auto !important;
  }

  .margin-top-0 {
    margin-top: 0rem !important;
  }

  .margin-top-1 {
    margin-top: 1.666666666rem !important;
  }

  .margin-top-2 {
    margin-top: 3.333333332rem !important;
  }

  .margin-top-3 {
    margin-top: 4.999999998rem !important;
  }

  .margin-top-4 {
    margin-top: 6.666666664rem !important;
  }

  .margin-right-auto {
    margin-right: auto !important;
  }

  .margin-right-0 {
    margin-right: 0rem !important;
  }

  .margin-right-1 {
    margin-right: 1.666666666rem !important;
  }

  .margin-right-2 {
    margin-right: 3.333333332rem !important;
  }

  .margin-right-3 {
    margin-right: 4.999999998rem !important;
  }

  .margin-right-4 {
    margin-right: 6.666666664rem !important;
  }

  .margin-bottom-auto {
    margin-bottom: auto !important;
  }

  .margin-bottom-0 {
    margin-bottom: 0rem !important;
  }

  .margin-bottom-1 {
    margin-bottom: 1.666666666rem !important;
  }

  .margin-bottom-2 {
    margin-bottom: 3.333333332rem !important;
  }

  .margin-bottom-3 {
    margin-bottom: 4.999999998rem !important;
  }

  .margin-bottom-4 {
    margin-bottom: 6.666666664rem !important;
  }

  .margin-left-auto {
    margin-left: auto !important;
  }

  .margin-left-0 {
    margin-left: 0rem !important;
  }

  .margin-left-1 {
    margin-left: 1.666666666rem !important;
  }

  .margin-left-2 {
    margin-left: 3.333333332rem !important;
  }

  .margin-left-3 {
    margin-left: 4.999999998rem !important;
  }

  .margin-left-4 {
    margin-left: 6.666666664rem !important;
  }

  .padding-auto {
    padding: auto !important;
  }

  .padding-0 {
    padding: 0rem !important;
  }

  .padding-1 {
    padding: 1.666666666rem !important;
  }

  .padding-2 {
    padding: 3.333333332rem !important;
  }

  .padding-3 {
    padding: 4.999999998rem !important;
  }

  .padding-4 {
    padding: 6.666666664rem !important;
  }

  .padding-top-auto {
    padding-top: auto !important;
  }

  .padding-top-0 {
    padding-top: 0rem !important;
  }

  .padding-top-1 {
    padding-top: 1.666666666rem !important;
  }

  .padding-top-2 {
    padding-top: 3.333333332rem !important;
  }

  .padding-top-3 {
    padding-top: 4.999999998rem !important;
  }

  .padding-top-4 {
    padding-top: 6.666666664rem !important;
  }

  .padding-right-auto {
    padding-right: auto !important;
  }

  .padding-right-0 {
    padding-right: 0rem !important;
  }

  .padding-right-1 {
    padding-right: 1.666666666rem !important;
  }

  .padding-right-2 {
    padding-right: 3.333333332rem !important;
  }

  .padding-right-3 {
    padding-right: 4.999999998rem !important;
  }

  .padding-right-4 {
    padding-right: 6.666666664rem !important;
  }

  .padding-bottom-auto {
    padding-bottom: auto !important;
  }

  .padding-bottom-0 {
    padding-bottom: 0rem !important;
  }

  .padding-bottom-1 {
    padding-bottom: 1.666666666rem !important;
  }

  .padding-bottom-2 {
    padding-bottom: 3.333333332rem !important;
  }

  .padding-bottom-3 {
    padding-bottom: 4.999999998rem !important;
  }

  .padding-bottom-4 {
    padding-bottom: 6.666666664rem !important;
  }

  .padding-left-auto {
    padding-left: auto !important;
  }

  .padding-left-0 {
    padding-left: 0rem !important;
  }

  .padding-left-1 {
    padding-left: 1.666666666rem !important;
  }

  .padding-left-2 {
    padding-left: 3.333333332rem !important;
  }

  .padding-left-3 {
    padding-left: 4.999999998rem !important;
  }

  .padding-left-4 {
    padding-left: 6.666666664rem !important;
  }

  .flex-row {
    flex-direction: row !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  .flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-fill {
    flex: 1 1 auto !important;
  }

  .flex-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-start {
    justify-content: flex-start !important;
  }

  .justify-content-end {
    justify-content: flex-end !important;
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .justify-content-around {
    justify-content: space-around !important;
  }

  .align-items-start {
    align-items: flex-start !important;
  }

  .align-items-end {
    align-items: flex-end !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  .align-items-baseline {
    align-items: baseline !important;
  }

  .align-items-stretch {
    align-items: stretch !important;
  }

  .align-content-start {
    align-content: flex-start !important;
  }

  .align-content-end {
    align-content: flex-end !important;
  }

  .align-content-center {
    align-content: center !important;
  }

  .align-content-between {
    align-content: space-between !important;
  }

  .align-content-around {
    align-content: space-around !important;
  }

  .align-content-stretch {
    align-content: stretch !important;
  }

  .align-self-auto {
    align-self: auto !important;
  }

  .align-self-start {
    align-self: flex-start !important;
  }

  .align-self-end {
    align-self: flex-end !important;
  }

  .align-self-center {
    align-self: center !important;
  }

  .align-self-baseline {
    align-self: baseline !important;
  }

  .align-self-stretch {
    align-self: stretch !important;
  }

  @media (min-width: 36em) {
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
      flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      align-self: stretch !important;
    }
  }

  @media (min-width: 48em) {
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
      flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      align-self: stretch !important;
    }
  }

  @media (min-width: 62em) {
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
      flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      align-self: stretch !important;
    }
  }

  @media (min-width: 75em) {
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      align-self: stretch !important;
    }
  }

  .flex-auto {
    flex: auto !important;
  }
`;
