export const getRouteNameByUrl = url => {
  const hash = url.split(window.location.origin)[1].split("#")[1];

  if (hash[0] === "/") {
    return hash.substring(1);
  }

  return hash;
};

export default {
  getRouteNameByUrl,
};
