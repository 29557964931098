import * as THREE from "three";

import ApiModel from "../Models/ApiModel";
import Environment from "./Environment";
import Floor from "./Floor";
import FloorMirror from "./FloorMirror";

let scene = null;
let cssScene = null;
let camera = null;
let resources = null;
let raycaster = null;

export default class Exhibition extends ApiModel {
  constructor({ experience, editMode }) {
    super();

    ({ scene } = experience);
    ({ cssScene } = experience);
    ({ camera } = experience);
    ({ resources } = experience);
    ({ raycaster } = experience);

    this.experience = experience;
    this.editMode = editMode;
    this.zoomLevel = 1;
    this.zoomItem = null;
    this.isInit = true;

    this.addListeners();
  }

  get isStandaloneBoothType() {
    return this.experience.get("project").boothTemplate.name === "Standalone";
  }

  get isPlatform() {
    return this.experience.get("project").boothTemplate.type === "platform";
  }

  get isEnvironment() {
    return this.experience.get("project").boothTemplate.type === "environment";
  }

  get exhibitList() {
    return this.experience.get("project").get("exhibits");
  }

  get hotspots() {
    return this.exhibitList.reduce((hotspots, exhibit) => {
      return [...hotspots, ...exhibit.get("hotspots")];
    }, []);
  }

  get firstLevelHotspots() {
    return this.hotspots.filter(hotspot => hotspot.get("level") === 1);
  }

  get secondLevelHotspots() {
    return this.hotspots.filter(hotspot => {
      if (this.isPlatform) {
        return (
          hotspot.get("level") === 2 &&
          hotspot.get("exhibit").get("platformName") ===
            this.zoomItem.get("platformName")
        );
      } else {
        return (
          hotspot.get("level") === 2 &&
          hotspot.get("exhibit").get("id") === this.zoomItem?.get("id")
        );
      }
    });
  }

  getExhibitByPlaceName(placename) {
    return this.experience
      .get("project")
      .get("exhibits")
      .find(exhibit => {
        return exhibit.get("placeName") === placename;
      });
  }

  addListeners() {
    this.on("asset-loaded", () => {
      this.updateReadyState();
    });

    resources.on("init", () => {
      this.floor = new Floor({ exhibition: this });
      if (!this.isPlatform && !this.isEnvironment) {
        this.floorMirror = new FloorMirror({ exhibition: this });
      }

      this.exhibitList.map(exhibit => exhibit.init({ exhibition: this }));

      this.environment = new Environment({ exhibition: this });
      this.isInit = false;
    });

    raycaster.on("hotspot-positioned", hotspot => {
      this.addHotspotToNearestExhibit(hotspot);
    });

    this.on("tooltip-opened", fromHotspot => {
      this.exhibitList.forEach(exhibit => {
        exhibit.hotspots.forEach(hotspot => {
          if (hotspot !== fromHotspot) {
            hotspot.container.isTooltipActive = false;
          }
        });
      });
    });
  }

  updateReadyState() {
    this.states = [
      this.isStandaloneBoothType ? true : this.floor?.isReady,
      // this.isStandaloneBoothType ? true : this.floorMirror?.isReady,
      ...this.exhibitList.map(e => e.isReady),
    ];
    if (!this.states.includes(false) && !this.states.includes(undefined)) {
      this.exhibitList.map(exhibit => exhibit.createHotspots());
      this.trigger("loaded");
    }
  }

  async addHotspotToNearestExhibit(hotspot) {
    const [x, y, z] = hotspot.get("position");
    const hotspotPosition = new THREE.Vector3(x, y, z);

    let min = 5000;
    let nearestExhibit;
    let exhibitPos;

    const zoomedPlatform = this.floor.platforms?.find(p => p.isZoomed);
    let target = new THREE.Vector3()
    // find exhibit position by min distance between hotspot position and all exhibts positions
    this.exhibitList.forEach(exhibit => {
      if (
        this.isPlatform &&
        exhibit.platformName === zoomedPlatform.platformData.name
      ) {
        if (exhibit.resource) {
          exhibitPos = exhibit.resource?.scene.getWorldPosition(target); 
        }else {
          exhibitPos = new THREE.Vector3(...exhibit.get("position"));
        }       
        const distance = hotspotPosition.distanceTo(exhibitPos);
        if (distance < min) {
          min = distance;
          nearestExhibit = exhibit;
        }
      } else if (!this.isPlatform) {
        const exhibitPos = new THREE.Vector3(...exhibit.get("position"));
        const distance = hotspotPosition.distanceTo(exhibitPos);
        if (distance < min) {
          min = distance;
          nearestExhibit = exhibit;
        }
      }
    });
    hotspot.set("exhibit", nearestExhibit);
    await hotspot.create();
  }

  switchLevel(toExhibit = null, platformName) {
    if (!toExhibit) {
      camera.resetCameraPosition();
      if (this.isPlatform) {
        this.floor.reset();
      }

      this.set("zoomLevel", 1);
      this.set("zoomItem", null);

      this.exhibitList.forEach(exhibit => {
        exhibit.hotspots.forEach(hotspot => {
          hotspot.get("level") === 1 ? hotspot.scaleUp() : hotspot.scaleDown();
        });
      });
    } else {
      this.set("zoomLevel", 2);
      this.set("zoomItem", toExhibit);

      camera.animateCameraMove(toExhibit.get("secondLevel"));

      this.exhibitList.forEach(exhibit => {
        exhibit.hotspots.forEach(hotspot => {
          if (this.isPlatform) {
            if (exhibit.platformName === platformName) {
              hotspot.get("level") === 2
                ? hotspot.scaleUp()
                : hotspot.scaleDown();
            } else {
              hotspot.scaleDown();
            }
          } else {
            if (exhibit === toExhibit) {
              hotspot.get("level") === 2
                ? hotspot.scaleUp()
                : hotspot.scaleDown();
            } else {
              hotspot.scaleDown();
            }
          }
        });
      });
    }

    this.trigger("updated");
  }

  async updatePlatformExhibits(platform) {
    const { isActive } = platform.platformData;

    const platformsExhibit = this.experience.project.exhibits.find(
      exhibit => platform.platformData.name === exhibit.platformName
    );

    if (isActive) {
      platformsExhibit.setExhibitVisible();
    } else {
      platformsExhibit.setExhibitUnvisible();
    }

    const count = this.floor.platformCount;
    const radius = this.floor.platformRadius;

    this.floor.allPlatformsGroup.position.x = -radius;
    camera.controls.target.x = -radius;

    for (let i = 0; i < count; i += 1) {
      const activePlatform = this.floor.activePlatforms[i];
      const centralExhibit = this.centralExhibits.find(
        exhibit => exhibit.platformName === activePlatform.platformData.name
      );
      const [x, z] = this.getPlatformPosition(count, radius, i);
      activePlatform.platformData.position = [
        x,
        activePlatform.platformData.position[1],
        z,
      ];
      activePlatform.setPosition();

      centralExhibit.position = [x, centralExhibit.position[1], z];
      centralExhibit.setPositionXZ(activePlatform.platformData.position);
    }
    this.floor.managePlatformLabels();
  }

  get centralExhibits() {
    return this.experience.project.exhibits.filter(exhibit =>
      exhibit.name.includes("central")
    );
  }

  getPlatformPosition(fragmentCount, radius, fragmentPosition) {
    const angle = (360 / fragmentCount / 180) * fragmentPosition * Math.PI;
    const x = Math.round(radius * Math.cos(angle));
    const z = Math.round(radius * Math.sin(angle));
    return [x, z];
  }

  dispose() {
    scene.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();

        for (const key in child.material) {
          const value = child.material[key];

          // Test if there is a dispose function
          if (value && typeof value.dispose === "function") {
            value.dispose();
          }
        }
      }
    });

    cssScene.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();

        for (const key in child.material) {
          const value = child.material[key];

          // Test if there is a dispose function
          if (value && typeof value.dispose === "function") {
            value.dispose();
          }
        }
      }
    });
  }
}
