import { LitElement, html, css } from "lit";
import { classMap } from "lit-html/directives/class-map.js";
import { ConsumerMixin } from "lit-element-context";

import Api from "../../../../api/api-helper.js";

import shared from "../../../../shared/styles.scss";
import custom from "./exhibit-select.scss";

import "@shs/ui-marcom/src/js/card";
import { handleInvalidRequest } from "../../../../api/helper.js";

class ExhibitSelect extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      experience: { type: Object },
      models: { type: Array },
      selectedModel: { type: Object },
      onSelect: { type: Object },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["setLoading", "showAlert"];
  }

  constructor() {
    super();
    this.models = [];
    this.selectedModel = null;
    this.onSelect = () => {};

    this.api = Api.getInstance();
  }

  async firstUpdated() {
    this.setLoading(true);
    try {
      this.models = await this.api.models();
    } catch (error) {
      handleInvalidRequest(this.router, this.showAlert);
    } finally {
      this.setLoading(false);
    }
  }

  select(model) {
    this.selectedModel = model;
    this.onSelect(model);
  }

  render() {
    return html`
      <div class="exhibit-select">
        <div class="row">
          <div class="col-12">
            <p>
              Please select an exhibit. After you have added the exhibit to the
              booth you are able to rotate and move the 3D object.
            </p>
          </div>
        </div>
        <div class="row">
          ${this.models.map(model => {
            return html`
              <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <uimc-card
                  class="display-block margin-bottom-1 cursor-pointer"
                  @click=${() => {
                    this.select(model);
                  }}
                >
                  <uimc-aspect-ratio slot="card-image" formats='{"sm": "4x3"}'>
                    <img
                      src=${this.api.getFileSource(model?.previewImage)}
                      alt=${`${model?.name} preview image`}
                    />
                  </uimc-aspect-ratio>

                  <div
                    class="display-flex align-items-center justify-content-between"
                  >
                    <p class="margin-bottom-0">
                      <strong>${model?.name}</strong>
                    </p>
                    <span
                      class="${classMap({
                        invisible: this.selectedModel?.id !== model?.id,
                      })}"
                    >
                      <i class="icon-check icon--success"></i>
                    </span>
                  </div>
                </uimc-card>
              </div>
            `;
          })}
        </div>
      </div>
    `;
  }
}

customElements.define("exhibit-select", ExhibitSelect);
