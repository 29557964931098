import { getXYZArray } from "./helper";
import { getHotspot } from "./hotspot";
import { getLight } from "./boothtemplate";
import axios from "axios";

function getPlatformArray(apiPlatforms) {
  const platforms = [];

  apiPlatforms.forEach(platform => {
    platforms.push(getPlatform(platform));
  });

  return platforms;
}

function getPlatform(apiPlatform) {
  if (!apiPlatform) {
    return null;
  }

  return {
    name: apiPlatform.name,
    id: apiPlatform.id,
    sort: apiPlatform.sort,
    position: getXYZArray(apiPlatform, "position"),
    frontPosition: getXYZArray(apiPlatform, "frontposition"),
    hotspot: getHotspot(apiPlatform.hotspot),
    spotLight: getLight(apiPlatform.spotlight),
    bridgeAniDelay: apiPlatform.bridgeanidelay,
    bridgeRotation: apiPlatform.bridgerotation,
    isActive: apiPlatform.isactive,
    firstLevelScale: apiPlatform.firstlevelscale,
    secondLevelScale: apiPlatform.secondlevelscale,
  };
}

async function updatePlatform(id, fields, url, options) {
  const platformtUrl = new URL(`/items/project_platforms/${id}`, url);

  const res = await axios.patch(platformtUrl, fields, options);

  return res;
}

export { getPlatformArray, getPlatform, updatePlatform };
