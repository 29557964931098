import { LitElement, html, css } from "lit";
import { classMap } from "lit/directives/class-map";

import shared from "../../../../shared/styles.scss";
import custom from "./hotspot-container.scss";

class HotspotContainer extends LitElement {
  static get properties() {
    return {
      contentType: { type: String, attribute: "false" },
      content: { type: String, attribute: "false" },
      labelPosition: { type: String, attribute: "false" },
      labelValue: { type: String, attribute: "false" },
      dotImage: { type: String },
      hasDotCount: { type: Boolean },
      dotCount: { type: Number },
      isTooltipActive: { type: Boolean },
      onClickHotSpot: { type: Object },
      onOpenTooltip: { type: Object },
    };
  }

  constructor() {
    super();
    this.isTooltipActive = false;
    this.content = "";
    this.link = "";
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  firstUpdated() {}

  clickOnHotspot() {
    this.onClickHotSpot();

    this.isTooltipActive =
      this.contentType === "tooltip" && !this.isTooltipActive;

    if (this.isTooltipActive) {
      this.onOpenTooltip();
    }
  }

  render() {
    return html`
      <div
        class="hotspot-container hotspot ${classMap({
          "hotspot--leftHanded": this.labelPosition === "left",
          "hotspot--rightHanded": this.labelPosition === "right",
        })}" 
        @pointerdown=${() => this.clickOnHotspot()}
          @touchstart=${() => this.clickOnHotspot()}
      >
        <div
          class="dot"
         
        >
          <div class="dot__pulse dot__pulse--inner"></div>
          <div class="dot__pulse dot__pulse--outer"></div>
          <div class="dot__image">
            ${this.contentType === "zoom" && this.hasDotCount
              ? html`
                  <span class="color-orange font-size">${this.dotCount}</span>
                `
              : html`
                  <div
                    class="dot__icon--width"
                    style="content: url(/assets/icons/${this.contentType === "animation" ? "play" : "plus"}.svg);"
                  ></div>
                `}
          </div>
        </div>

        <div
          class="label__container ${classMap({
            "label--leftHanded": this.labelPosition === "left",
            "label--rightHanded": this.labelPosition === "right",
          })}"
        >
          <h6
            class="label__text margin-top-0 margin-bottom-0 ${this
              .contentType === "zoom"
              ? "label__text--zoom"
              : ""}"
          >
            ${this.labelValue}
          </h6>
        </div>
        ${this.contentType === "tooltip" && this.isTooltipActive
          ? html`
              <div
                class="tooltip ${classMap({
                  "tooltip--leftHanded": this.labelPosition === "left",
                  "tooltip--rightHanded": this.labelPosition === "right",
                })}"
              >
                ${this.content}
              </div>
            `
          : null}
      </div>
    `;
  }
}

customElements.define("hotspot-container", HotspotContainer);
