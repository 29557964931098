import { LitElement, html, css } from "lit-element";

import shared from "../../shared/styles.scss";
import custom from "./functional-panel.scss";

class FunctionalPanel extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      headline: { attribute: true },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  render() {
    return html`
      <div class="functional-panel">
        <div class="functional-panel__headline">
          ${this.headline}
        </div>

        <slot></slot>
      </div>
    `;
  }
}

customElements.define("functional-panel", FunctionalPanel);
