import * as THREE from "three";
import EventEmitter from "./Utils/EventEmitter";

let sizes = null;
let camera = null;
let scene = null;

export default class Raycaster extends EventEmitter {
  constructor({ experience }) {
    super();

    this.isEnabled = false;
    this.tempHotspot = null;

    ({ sizes } = experience);
    ({ camera } = experience);
    ({ scene } = experience);

    // setup
    this.mouse = new THREE.Vector2();
    this.instance = new THREE.Raycaster();

    this.setMouse = event => {
      this.mouse.x = (event.clientX / sizes.width) * 2 - 1;
      this.mouse.y = -(event.clientY / sizes.height) * 2 + 1;
    };

    this.handleClick = () => {
      if (this.isEnabled) {
        this.isEnabled = false;

        this.trigger("hotspot-positioned", [this.tempHotspot]);
        window.removeEventListener("mousemove", this.setMouse);
      }

      if (!camera.isAnimated) {
        camera.setAutorotation(false, 0);
      }
    };

    window.addEventListener("pointerdown", this.handleClick);
  }

  enablePositionHotspot(hotspot) {
    this.tempHotspot = hotspot;

    window.addEventListener("mousemove", this.setMouse);

    this.isEnabled = true;
  }

  update() {
    if (!this.isEnabled) {
      return;
    }

    this.instance.setFromCamera(this.mouse, camera.instance);
    this.intersects = this.instance.intersectObjects(scene.children, true);

    if (this.tempHotspot && this.intersects.length) {
      const { x, y, z } = this.intersects[0].point;
      this.tempHotspot.setPosition([x, y, z]);
    }
  }

  dispose() {
    window.removeEventListener("pointerdown", this.handleClick);
  }
}
