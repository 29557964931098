import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../../../shared/styles.scss";
import custom from "./contact-settings-tab.scss";

import "../contact-options-list/contact-options-list";
import "../contact-text-select/contact-text-select";
import "../contact-option-config/contact-option-config";
import { hasAuthToken, handleInvalidRequest } from "../../../../api/helper";

class ContactSettings extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      configIsEnabled: { type: Boolean },
      activeEditBox: { type: String, attribute: false },
      router: { type: Object },
    };
  }

  constructor() {
    super();
    this.activeEditBox = null;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showModal", "showAlert", "setLoading"];
  }

  get selectDialog() {
    return html`
      <contact-text-select
        base-path=${this.basePath}
        .project=${this.project}
      ></contact-text-select>
    `;
  }

  get configureOption() {
    return html`
      <contact-option-config
        base-path=${this.basePath}
        .project=${this.project}
        .onInput=${(key, value) => {
          this.handleInput(key, value);
        }}
      ></contact-option-config>
    `;
  }

  firstUpdated() {
    this.project.on("updated", () => {
      this.requestUpdate();
    });
  }

  handleInput(key, value) {
    switch (key) {
      case "contactTitle":
        this.contactTitle = value;
        break;

      case "contactType":
        this.contactType = value;
        break;

      case "meetingType":
        this.meetingType = value;
        break;
      default:
        break;
    }
  }

  saveStaticContent() {
    // TODO: show alert
  }

  addContactOption() {
    const newOption = {
      type: this.contactType,
      title: this.contactTitle,
      meetingType: this.meetingType,
    };

    this.project.contact.dynamicContent.options.push(newOption);
    this.project.trigger("updated");

    this.contactType = null;
    this.contactTitle = null;
    this.meetingType = null;

    this.saveContact();
  }

  changeCompaignId(event) {
    this.project.contact.dynamicContent.campaignID = event.target.value;
    this.project.trigger("updated");
  }

  async toggleContactEdit() {
    await this.project.toggleProjectContact();
    this.requestUpdate();
    this.project.trigger("updated");
  }

  toggleProjectContact() {
    this.project.set("config").contactEnabled = !this.project.get("config")
      .contactEnabled;
  }

  setCampaignID(id) {
    this.project.contact.dynamicContent.campaignID = id;
    this.project.trigger("updated");
    this.saveContact();
  }

  async save() {
    this.setLoading(true);

    try {
      const status = await this.project.persist();
      if (status === 200) {
        this.showAlert(
          "Saved",
          `Project contact widget has been saved.`,
          "success"
        );
      }
    } catch (error) {
      if (!hasAuthToken()) {
        handleInvalidRequest(this.router, this.showAlert);
      } else {
        this.showAlert(
          "Saving failed",
          `Saving project contact widget has failed.`,
          "error"
        );
      }
    } finally {
      this.activeEditBox = null;

      this.setLoading(false);
    }
  }

  async saveContact() {
    this.setLoading(true);
    try {
      const status = await this.project.persistContact();

      if (status === 200) {
        this.showAlert(
          "Saved",
          `Project contact widget has been saved.`,
          "success"
        );
      }
    } catch (error) {
      if (!hasAuthToken()) {
        handleInvalidRequest(this.router, this.showAlert);
      } else {
        this.showAlert(
          "Saving failed",
          `Saving project contact widget has failed.`,
          "error"
        );
      }
    } finally {
      this.setLoading(false);
    }
  }

  render() {
    return html`
      <div class="contact-settings">
        <custom-toggle-switch
          ?checked=${this.project.get("config").contactEnabled}
          .onChange=${() => {
            this.toggleContactEdit();
          }}
          >Activate Contact Widget
        </custom-toggle-switch>

        <div class="margin-top-1">
          <h5 class="margin-top-0">Eloqua Campaign ID</h5>

          <p class="display-flex align-items-center justify-content-between ">
            ${this.project.contact?.dynamicContent?.campaignID.length > 0
              ? html`
                  ${this.project.contact.dynamicContent.campaignID}
                `
              : html`
                  <i>No Eloqua Campaign ID available.</i>
                `}

            <custom-tooltip-button
              tooltip="Edit campaign ID"
              type="control"
              icon="edit"
              ?disabled=${this.activeEditBox !== null ||
                !this.project.get("config").contactEnabled}
              .onClick=${() => {
                this.activeEditBox = "CAMPAIGN";
              }}
            ></custom-tooltip-button>
          </p>

          ${this.activeEditBox === "CAMPAIGN"
            ? html`
                <edit-box
                  class="margin-bottom-1"
                  description="Type in the Eloqua Campaign ID and press save button to store the setting."
                  .onSubmit=${() => this.save()}
                >
                  <custom-textfield
                    theme="gray"
                    label="*Eloqua Campaign ID"
                    value="${this.project.contact.dynamicContent.campaignID}"
                    .onChange=${id => this.setCampaignID(id)}
                  ></custom-textfield>
                </edit-box>
              `
            : null}
        </div>

        <div class="margin-top-1">
          <h5 class="margin-top-0">Contact options</h5>
          <p>
            Add and edit contact options (e.g. contact form, appointments, ...)
          </p>
        </div>

        <div class="margin-top-1">
          <contact-options-list
            base-path=${this.basePath}
            .project=${this.project}
          ></contact-options-list>
        </div>
        <div class="margin-top-1">
          <custom-button
            icon="plus"
            ?disabled=${!this.project.get("config").contactEnabled}
            .onClick=${() => {
              this.showModal(
                "Add a new contact option",
                this.configureOption,
                "",
                "Add option",
                "Cancel",
                "large",
                () => {
                  this.addContactOption();
                }
              );
            }}
            >Add a contact option</custom-button
          >
        </div>
        <div class="margin-top-1">
          <custom-button
            icon="arrow-right-small"
            ?disabled=${!this.project.get("config").contactEnabled}
            .onClick=${() => {
              this.showModal(
                "Configure button, hints and shortcuts",
                this.selectDialog,
                null,
                "Save",
                "Cancel",
                "large",
                () => this.saveStaticContent()
              );
            }}
            >Advanced configuration of frontend texts</custom-button
          >
        </div>
      </div>
    `;
  }
}

customElements.define("contact-settings", ContactSettings);
