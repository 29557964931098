import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../../../shared/styles.scss";
import custom from "./publish-settings-tab.scss";

import "@shs/ui-marcom/src/js/collection";
import "@shs/ui-marcom/src/js/button";
import "@shs/ui-marcom/src/js/disclaimer";

class PublishSettings extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      router: { type: Object },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showModal", "showAlert", "setLoading"];
  }

  firstUpdated() {
    this.project.on("updated", () => {
      this.requestUpdate();
    });
  }

  async createBuild() {
    this.setLoading(true);

    const status = await this.project.createBuild();

    if (status === 200) {
      this.showAlert(
        "Published",
        `Project "${this.project.get(
          "name"
        )}" has been published. The download is available in the collection below.`,
        "success"
      );
    }

    if (status >= 400) {
      this.showAlert(
        "Publishing failed",
        `Publishing project "${this.project.get("name")}" have failed.`,
        "error"
      );
    }

    this.setLoading(false);
  }

  async deleteBuild(build) {
    this.setLoading(true);

    const status = await build.flush();

    if (status === 204) {
      this.showAlert(
        "Deleted",
        `Package for project "${this.project.get("name")}" has been deleted.`,
        "success"
      );
    }

    if (status >= 400) {
      this.showAlert(
        "Deletion failed",
        `Deleting package for project "${this.project.get(
          "name"
        )}" have failed.`,
        "error"
      );
    }

    this.setLoading(false);
  }

  openUrlInNewTab(url) {
    const newTabUrl = `${this.router.root}/#/${url}`;
    window.open(newTabUrl, "_blank");
  }

  render() {
    return html`
      <div class="publish-settings">
        <div class="row">
          <div class="col-12">
            <h4 class="margin-top-0">Preview</h4>
            <uimc-button
              @click=${() =>
                this.openUrlInNewTab(
                  `preview-project/${this.project.get("id")}`
                )}
              >Open preview</uimc-button
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>Publish booth</h4>
            <p>
              <span class="disclaimer-bold">The responsibility for complying with all necessary approval processes lies solely with the user. No examination of content regarding its approvals is undertaken within the scope of tool usage ('simplEX').</span>
              <uimc-button @click=${() => this.createBuild()} primary
                >Publish
              </uimc-button>
            </p>
            <uimc-collection compact>
              ${this.project.buildList.map(
                build => html`
                  <uimc-collection-item url="${build.src}">
                    ${build.filename}

                    <div slot="additional-content">
                      <uimc-button
                        type="control"
                        icon="delete"
                        @click=${event => {
                          event.preventDefault();
                          event.stopPropagation();

                          this.showModal(
                            "Delete package",
                            `You are about to delete the the package "${
                              build.filename
                            }" from project "${this.project.get(
                              "name"
                            )}". Do you really want to continue?`,
                            "warning",
                            "Delete package",
                            "Return",
                            "small",
                            () => {
                              this.deleteBuild(build);
                            }
                          );
                        }}
                      ></uimc-button>
                    </div>
                  </uimc-collection-item>
                `
              )}
            </uimc-collection>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define("publish-settings", PublishSettings);
