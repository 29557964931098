import Tag from "@shs/ui-marcom/src/js/tag";

import shared from "../../shared/styles.scss";
import custom from "./custom-tag.scss";

class CustomTag extends Tag {
  _onClick() {
    if (typeof this.onChange === "function") {
      this.onChange(this.isSelected);
    }
  }
}

customElements.define("custom-tag", CustomTag);
