import { html, unsafeCSS } from "lit";
import { classMap } from "lit/directives/class-map";
import Button from "../custom-button/custom-button";

import shared from "../../shared/styles.scss";

import "@shs/ui-marcom/src/js/tooltip";

export default class CustomTooltipButton extends Button {
  static get properties() {
    return {
      ...super.properties,
      tooltip: {
        type: String,
        attribute: true,
      },
    };
  }

  static get styles() {
    return [super.styles, unsafeCSS(shared)];
  }

  constructor() {
    super();

    this.tooltip = null;
  }

  get button() {
    return html`
      <button
        class=${classMap({
          button: true,
          "button--primary": this.isPrimary,
          "button--control": this.type === "control",
          "button--link": this.type === "link",
          "button--small": this.size === "small",
          [`icon-${this.icon}`]: this.icon,
        })}
        ?disabled=${this.isDisabled}
        @click=${this.onClick}
      >
        <slot></slot>
      </button>
    `;
  }

  render() {
    return html`
      ${this.tooltip && !this.isDisabled
        ? html`
            <uimc-tooltip class="display-flex" content=${this.tooltip}
              >${this.button}</uimc-tooltip
            >
          `
        : html`
            ${this.button}
          `}
    `;
  }
}

window.customElements.define("custom-tooltip-button", CustomTooltipButton);
