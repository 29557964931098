import { LitElement, html, css } from "lit";

import shared from "../../shared/styles.scss";
import custom from "./user-login.scss";

import "../../components/app-header/app-header";
import "../../custom-uimc/custom-textfield/custom-textfield";

class UserLogin extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      user: { type: String },
      pwd: { type: String },
      onSubmitLogin: { type: Object },
    };
  }

  constructor() {
    super();
    this.user = null;
    this.pwd = null;
    this.onSubmitLogin = () => {};
    this.currentUser = null;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  setMail(email) {
    this.user = email;
  }

  setPwd(pwd) {
    this.pwd = pwd;
  }

  handleKeyDownEnter(e) {
    if (e.keyCode === 13) {
      this.onSubmitLogin({ email: this.user, password: this.pwd });
    }
  }

  async handleForgotPassword() {
    const status = await this.api.resetPassword(this.currentUser.email);
    if (status === 204) {
      this.showAlert(
        "Reset password - Mail sent",
        `Mail for password reset was sent to ${this.currentUser.email}`,
        "success"
      );
    } else {
      this.showAlert(
        "Reset password failed",
        `Password reset failed for ${this.currentUser.email}`,
        "error"
      );
    }
  }

  render() {
    return html`
      <app-header
        .router=${this.router}
        .currentUser=${() => null}
      ></app-header>
      <div class="container">
        <div class="user-login">
          <h1>Login for ${SIMPLEX.APP_TITLE}</h1>
          <p>
            simplEX is our tool for building interactive 3D experiences. Get in
            touch with the Hybrid Exhibitions team to learn more about the
            capabilities of simplEX or to get your personal user account.
          </p>
          <div class="display-flex flex-row align-items-center">
            <custom-textfield
              class="margin-right-1"
              label="E-Mail"
              .onChange=${email => {
                this.setMail(email);
              }}
              @keyup=${e => {
                this.handleKeyDownEnter(e);
              }}
            ></custom-textfield>
            <custom-textfield
              class="margin-right-1"
              type="password"
              label="Password"
              .onChange=${pwd => {
                this.setPwd(pwd);
              }}
              @keyup=${e => {
                this.handleKeyDownEnter(e);
              }}
            ></custom-textfield>
            <uimc-button
              primary
              @click=${() => {
                this.onSubmitLogin({ email: this.user, password: this.pwd });
              }}
              >Login</uimc-button
            >
          </div>
          <div class="reset-password-link">
            <a href="#reset-password" data-navigo>Forgot password?</a>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define("user-login", UserLogin);
