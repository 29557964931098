import EventEmitter from "./EventEmitter.js";

import { Clock } from "three";

export default class Loop extends EventEmitter {
  constructor({ renderer }) {
    super();
    this.renderer = renderer;

    this.initTime = Date.now();
    this.current = this.initTime;
    this.elapsed = 0;
    this.delta = 16;
    this.clock = new Clock();
  }

  start() {
    this.renderer.webGlInstance.setAnimationLoop(() => {
      this.elapsedTime = this.clock.getElapsedTime();
      const currentTime = Date.now();
      this.delta = currentTime - this.current;
      this.current = currentTime;
      this.elapsed = this.current - this.initTime;

      this.trigger("tick");
    });
  }

  stop() {
    this.renderer.webGlInstance.setAnimationLoop(null);
  }
}
