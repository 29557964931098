import { html, css } from "lit";
import { classMap } from "lit/directives/class-map";
import Dropdown from "@shs/ui-marcom/src/js/dropdown";

import custom from "./custom-dropdown.scss";

class CustomDropdown extends Dropdown {
  static get properties() {
    return {
      isArrowHidden: {
        type: String,
        attribute: true,
      },
    };
  }

  constructor() {
    super();
    this.isArrowHidden = false;
  }

  static get styles() {
    return [super.styles, css(custom)];
  }

  firstUpdated() {
    const children = this._getChildren();
    for (const menu of children) {
      menu.onClick = () => {
        this._onClose();
      };
    }
    document.addEventListener("click", e => {
      const target = e.composedPath()[0];

      if (!this.shadowRoot.contains(target)) {
        this._onClose();
      }
    });
  }

  renderToggleButton() {
    return html`
      <span
        class="${classMap({
          "dropdown__toggle-button": true,
          "custom-dropdown__hide-arrow": this.isArrowHidden,
        })}"
        @click=${() => {
          this._onToggle();
        }}
      >
        ${this.label} <i class="icon-dropdown"></i>
      </span>
    `;
  }
}

customElements.define("custom-dropdown", CustomDropdown);
