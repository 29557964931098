import { LitElement, html } from "lit";
import { classMap } from "lit/directives/class-map";
import styles from "./custom-alert-css";

/**
   * Alerts are notifications that attract the user’s attention. 
   * They can be directly related to an interactive process and give the user feedback on the success or failure of an action.
   * 
   * @element uimc-alert
   * 
   * @attr {String} title - Sets the alert-title
   * @attr {"success"|"warning"|"error"|"info"} indicator - Sets an indicator if needed  | default: null
   * @attr {String} type - Sets type of alert default: inline | options: overlay
   * @attr {String} direction - Sets fade in and out direction for overlay default: top-left | options: top-right | bottom-left | bottom-right
   * @attr {String} theme - Sets theme of alert default: light | options: dark | gray
   * @attr {Boolean} auto-hide - sets time until alert disapers automatically default: null | options: value > 0 (unit: sec.)
   * @attr {Boolean} left-border - set indicator-border on left-side,  not applicable  on type overlay default: false | options: true
   * @attr {Boolean} is-open - sets visibility of the alert type = overlay, default: false | options: true
   * @attr {Boolean} is-hidden - sets visibility of the alert type = inline, default: false | options: true
  
   * 
   * @example 
   *    <uimc-alert 
   *      title="Lorem ipsum" 
   *      indicator="warning"
   *      left-border
   *      type="overlay">
   *      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</p>
   *    </uimc-alert>
   * 
   */
class Alert extends LitElement {
  static get properties() {
    return {
      title: {
        type: String,
        attribute: true,
      },
      indicator: {
        type: String,
        attribute: true,
      },
      type: {
        type: String,
        attribute: true,
      },
      direction: {
        type: String,
        attribute: true,
      },
      theme: {
        type: String,
        attribute: true,
      },
      border: {
        type: Boolean,
        attribute: "left-border",
      },
      isOpen: {
        type: Boolean,
        attribute: "is-open",
        reflect: true,
      },
      isHidden: {
        type: Boolean,
        attribute: "is-hidden",
      },
      autoHide: {
        type: Number,
        attribute: "auto-hide",
      },
      openOverlay: {
        attribute: false,
      },
      onHide: {
        attribute: false,
        type: Object,
      },
    };
  }

  static get styles() {
    return [styles];
  }

  constructor() {
    super();
    this.title = null;
    this.indicator = null;
    this.type = "inline";
    this.theme = null;
    this.direction = "top-left";
    this.border = false;
    this.isOpen = false;
    this.isHidden = false;
    this.autoHide = null;
    this.onHide = () => {};
  }

  firstUpdated() {
    if (this.type !== "overlay") {
      this.isOpen = true;
    }

    if (this.type === "inline" && this.isHidden) {
      this.isOpen = false;
    }
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (
      (changedProperties.has("type") || changedProperties.has("isOpen")) &&
      this.isOpen &&
      this.type === "overlay"
    ) {
      this.isHidden = false;
    }

    if (
      (changedProperties.has("type") ||
        changedProperties.has("isOpen") ||
        changedProperties.has("autoHide")) &&
      this.autoHide &&
      this.isOpen
    ) {
      this.autoFade(this.autoHide);
    }
  }

  _hideAlert() {
    this.isHidden = true;
  }

  close() {
    if (this.isHidden) {
      this.isOpen = false;
      this.onHide();
    }
  }

  autoFade(duration) {
    duration = duration * 1000;
    if (duration > 0) {
      setTimeout(() => {
        this._hideAlert();
      }, duration);
    }
  }

  render() {
    return html`
      <div
        class=${classMap({
          alert: true,
          "margin-1": true,
          "padding-left-0": this.indicator !== null,
          "is-open": this.isOpen,
          "display-none": !this.isOpen && !this.isHidden,
          "is-hidden": this.isHidden,
          alert__overlay: this.type === "overlay",
          [`alert__overlay--in-${this.direction}`]:
            this.type === "overlay" && !this.isHidden,
          [`alert__overlay--out-${this.direction}`]:
            this.type === "overlay" && this.isHidden,
          alert__border: this.border === true && this.type !== "overlay",

          [`alert__border--${this.indicator}`]: this.indicator,

          [`alert--${this.indicator}`]: this.indicator,

          dark: this.theme === "dark",
          gray: this.theme === "gray",
        })}
        @animationend=${() => {
          this.close();
        }}
      >
        <div
          class=${classMap({
            row: true,
            "justify-content-between": true,
            "padding-left-1": !this.type,
            "padding-right-1": !this.type,
          })}
        >
          <div class="col-9 padding-right-0">
            <div class="row justify-content-start">
              ${this.indicator
                ? html`
                    <div class="col-1">${this.iconTemplate}</div>
                  `
                : null}
              <div
                class=${classMap({
                  "col-9": this.type === "inline",
                  "col-8": this.type === "overlay",
                  "col-10": !this.indicator,
                })}
              >
                ${this.headerTemplate} ${this.bodyTemplate}
              </div>
            </div>
          </div>
          <div class="col-1">${this.closeTemplate}</div>
        </div>
      </div>
    `;
  }

  get headerTemplate() {
    return html`
      <div class="alert__header">
        <h5 class="alert__title">${this.title}</h5>
      </div>
    `;
  }
  get bodyTemplate() {
    return html`
      <div class="alert__body">
        <p class="alert__text">
          <slot></slot>
        </p>
      </div>
    `;
  }

  get iconTemplate() {
    return html`
      <i class="icon alert__indicator"></i>
    `;
  }

  get closeTemplate() {
    return html`
      <button type="button" class="alert__close" @click=${this._hideAlert}>
        <i class="icon-close"></i>
      </button>
    `;
  }
}

window.customElements.define("uimc-alert", Alert);

export default Alert;
