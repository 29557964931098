import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../shared/styles.scss";
import custom from "./edit-project.scss";

import Project from "../../experience/Models/Project";

import "../../components/editor-panel/editor-panel";
import "../../components/experience-container/experience-container";
import { handleInvalidRequest, hasAuthToken } from "../../api/helper";

class EditProject extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      project: { type: Object, attribute: false },
      experience: { type: Object, attribute: false },
      projectId: { attribute: "project-id", type: Number },
    };
  }

  constructor() {
    super();

    this.project = null;
    this.experience = null;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showModal", "showAlert", "setLoading"];
  }

  async firstUpdated() {
    this.setLoading(true);

    try {
      this.project = await Project.fetch(this.projectId);

      this.project.on("updated", () => {
        this.requestUpdate();
      });
    } catch (error) {
      if (!hasAuthToken) {
        handleInvalidRequest(this.router, this.showAlert);
      }
    } finally {
      this.setLoading(false);
    }
  }

  render() {
    return html`
      <div class="edit-project">
        ${this.project
          ? html`
              <experience-container
                base-path="${this.basePath}"
                ?edit=${true}
                .project=${this.project}
              ></experience-container>
              <editor-panel
                base-path=${this.basePath}
                .router=${this.router}
                .project=${this.project}
              ></editor-panel>
            `
          : null}
      </div>
    `;
  }
}

customElements.define("edit-project", EditProject);
