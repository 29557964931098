import axios from "axios";

const selectedFields = ["id", "first_name", "last_name", "email", "role.*"];

async function getCurrentUser(url, options) {
  const requestUrl = new URL("/users/me", url);
  requestUrl.searchParams.append("fields", selectedFields.join(","));

  const res = await axios.get(requestUrl, options);
  const currentUserData = res.data.data;

  return currentUserData;
}

async function getUsers(url, options) {
  const fields = [
    "id",
    "name",
    "users.first_name",
    "users.last_name",
    "users.email",
    "users.id",
    "users.status",
  ];

  const requestUrl = new URL("/roles", url);
  requestUrl.searchParams.append("fields", fields.join(","));
  requestUrl.searchParams.append(
    "filter",
    JSON.stringify({ name: { _in: ["User", "SiemensAdmin", "Admin"] } })
  );
  requestUrl.searchParams.append("deep", {
    users: { _filter: { status: { _in: ["active", "invited"] } } },
  });

  const res = await axios.get(requestUrl, options);
  const users = [];
  res.data?.data?.forEach(ul => ul.users.forEach(u => users.push(u)));
  return users;
}

async function getSharedWithList(projectId, url, options) {
  const fields = [
    "sharedwith.*",
    "sharedwith.directus_users_id.id",
    "sharedwith.directus_users_id.first_name",
    "sharedwith.directus_users_id.last_name",
    "sharedwith.directus_users_id.email",
  ];
  const requestUrl = new URL(`/items/projects/${projectId}`, url);

  requestUrl.searchParams.append("fields", fields);

  const res = await axios.get(requestUrl, options);

  return res.data?.data.sharedwith;
}

async function shareWithUser(projectId, userIdentifier, type, url, options) {
  let ids = null;

  if (type === "userId") {
    ids = await getSharedId(projectId, userIdentifier, url, options);
  }

  if (!ids && type === "userId") {
    const requestUrl = new URL("/items/projects_directus_users", url);

    await axios.post(
      requestUrl,
      { projects_id: projectId, directus_users_id: userIdentifier },
      options
    );
  }

  if (type === "userEmail") {
    const requestUrl = new URL("/items/projects_directus_users", url);

    await axios.post(
      requestUrl,
      { projects_id: projectId, directus_users_id: { email: userIdentifier } },
      options
    );
  }
  return getSharedWithList(projectId, url, options);
}

async function unshareUser(sharedWith, url, options) {
  if (!sharedWith?.id) {
    return [];
  }

  const requestUrl = new URL(
    `/items/projects_directus_users/${sharedWith.id}`,
    url
  );

  await axios.delete(requestUrl, options);

  return getSharedWithList(sharedWith.projects_id, url, options);
}

async function getSharedId(projectId, userId, url, options) {
  const sharedWithFilter = {
    _and: [
      { projects_id: { _eq: projectId } },
      { directus_users_id: { _eq: userId } },
    ],
  };

  const requestUrl = new URL(`/items/projects_directus_users`, url);
  requestUrl.searchParams.append("filter", JSON.stringify(sharedWithFilter));
  const res = await axios.get(requestUrl, options);

  return res.data.data[0]?.id;
}

export {
  getCurrentUser,
  getUsers,
  getSharedWithList,
  shareWithUser,
  unshareUser,
};
