import EventEmitter from "../Utils/EventEmitter";

import Api from "../../api/api-helper";

export default class ApiModel extends EventEmitter {
  constructor(payload) {
    super();

    this.oldValues = {};
    this.api = Api.getInstance();

    if (payload) {
      this.normalize(payload);
    }
  }

  get(property) {
    if (!Object.prototype.hasOwnProperty.call(this, property)) {
      return null;
    }

    return this[property];
  }

  set(property, newValue) {
    if (!Object.prototype.hasOwnProperty.call(this, property)) {
      return -1;
    }
    if (
      Object.prototype.hasOwnProperty.call(this.oldValues, property) &&
      newValue === this[property]
    ) {
      return 0;
    }
    this.oldValues[property] = this[property];
    this[property] = newValue;

    this.trigger("updated");
    return 1;
  }

  reset(property) {
    if (!Object.prototype.hasOwnProperty.call(this.oldValues, property)) {
      return -1;
    }

    this[property] = this.oldValues[property];
    delete this.oldValues[property];

    this.trigger("updated");
    return 1;
  }

  serialize() {
    const payload = {};
    Object.getOwnPropertyNames(this).forEach(prop => {
      payload[prop] = this.get(prop);
    });

    return payload;
  }

  normalize(payload) {
    Object.assign(this, payload);
  }
}
