import { LitElement, html, css } from "lit";

import shared from "../../../../shared/styles.scss";
import custom from "./video-tag.scss";

class VideoTag extends LitElement {
  static get properties() {
    return {
      videoFile: { type: String },
    };
  }

  constructor() {
    super();
    this.videoFile = null;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  firstUpdated() {}

  get video() {
    const el = this.shadowRoot.getElementById("radiology-outside-video");
    return el;
  }

  render() {
    return html`
      <div>
        ${this.videoFile
          ? html`
              <video
                id="radiology-outside-video"
                muted
                volume="0"
                onloadedmetadata="this.muted = true"
                autoplay
                loop
                crossorigin="anonymous"
                playsinline
                style="display:none"
              >
                <source
                  src="${this.videoFile}"
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
              </video>
            `
          : null}
      </div>
    `;
  }
}

customElements.define("video-tag", VideoTag);
