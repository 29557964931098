import EventEmitter from "./EventEmitter";

export default class Size extends EventEmitter {
  constructor({ container }) {
    super();

    // Setup
    const rect = container.getBoundingClientRect();
    this.width = rect.width;
    this.height = rect.height;
    this.pixelRatio = Math.min(window.devicePixelRatio, 2);

    // Resize event

    window.addEventListener("resize", () => {
      const rect = container.getBoundingClientRect();
      this.width = rect.width;
      this.height = rect.height;
      this.pixelRatio = Math.min(window.devicePixelRatio, 2);

      this.trigger("resize");
    });
  }
}
