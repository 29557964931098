import { css } from "lit";

import SelectElement from "@shs/ui-marcom/src/js/select";

import custom from "./custom-select.scss";

class CustomSelect extends SelectElement {
  static get styles() {
    return [super.styles, css(custom)];
  }
}

customElements.define("custom-select", CustomSelect);
