import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../../../shared/styles.scss";
import custom from "./exhibit-settings-tab.scss";

import "../exhibit-list/exhibit-list";
import "../exhibit-list-item/exhibit-list-item";
import "../platform-list/platform-list";
import "../platform-list-detail/platform-list-detail";

class ExhibitSettings extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object, attribute: false },
      selectedPlatform: { type: Object, attribute: false },
      activeTab: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["setExperienceControls"];
  }

  connectedCallback() {
    super.connectedCallback();

    this.project.trigger("updated");
  }

  disconnectedCallback() {
    this.setExperienceControls(null);
    this.project.trigger("updated");

    super.disconnectedCallback();
  }

  async firstUpdated() {
    if (
      this.project
        .get("experience")
        .get("exhibition")
        .get("zoomLevel") === 2
    ) {
      this.project
        .get("experience")
        .get("exhibition")
        .get("floor")
        .platforms.forEach(platform => {
          if (platform.isZoomed) {
            this.bubbleSelectedPlatformEvent(platform.platformData.name);
          }
        });
    }
  }

  updated() {
    this.project
      .get("experience")
      .get("exhibition")
      .on("updated", () => {
        if (
          this.project
            .get("experience")
            .get("exhibition")
            .get("zoomLevel") === 2
        ) {
          this.project
            .get("experience")
            .get("exhibition")
            .get("floor")
            .platforms.forEach(platform => {
              if (platform.isZoomed) {
                this.bubbleSelectedPlatformEvent(platform.platformData.name);
              }
            });
        }
      });
  }

  bubbleSelectedPlatformEvent(selectedPlatform) {
    const selectedPlatformEvent = new CustomEvent("selected-platform-event", {
      detail: selectedPlatform,
    });

    this.dispatchEvent(selectedPlatformEvent);
  }

  bubbleResetSelectedPlatformEvent() {
    const resetSelectedPlatformEvent = new CustomEvent(
      "reset-selected-platform-event",
      {}
    );

    this.dispatchEvent(resetSelectedPlatformEvent);
  }

  render() {
    return html`
      <div class="exhibit-settings">
        ${this.project.boothTemplate.type === "platform" &&
        this.selectedPlatform === "default"
          ? html`
              <div class="margin-bottom-1">
                Select one of the platform placeholders to edit the size of the
                platform, to change the title and to add exhibits to the
                placeholders on the platform.
              </div>
              <platform-list
                base-path="${this.basePath}"
                .project=${this.project}
                .activeTab=${this.activeTab}
              ></platform-list>
            `
          : null}
        ${this.project.boothTemplate.type === "platform" &&
        this.selectedPlatform !== "default"
          ? html`
              <platform-list-detail
                base-path="${this.basePath}"
                .project=${this.project}
                .selectedPlatform=${this.selectedPlatform}
                @reset-selected-platform-event=${() =>
                  this.bubbleResetSelectedPlatformEvent()}
              ></platform-list-detail>
            `
          : null}
        ${this.project.boothTemplate.type !== "platform"
          ? html`
              <exhibit-list
                base-path="${this.basePath}"
                .project=${this.project}
              >
              </exhibit-list>
            `
          : null}
      </div>
    `;
  }
}

customElements.define("exhibit-settings", ExhibitSettings);
