import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import Api from "../../../../api/api-helper";

import shared from "../../../../shared/styles.scss";
import custom from "./exhibit-list-item.scss";

import "../../../edit-box/edit-box";
import "../../file-upload/file-upload";
import "../exhibit-select/exhibit-select";

import "@shs/ui-marcom/src/js/media-object";
import "@shs/ui-marcom/src/js/aspect-ratio";

import "../../../../custom-uimc/custom-button/custom-button";
import "../../../../custom-uimc/custom-tooltip-button/custom-tooltip-button";
import "../../../../custom-uimc/custom-value-slider/custom-value-slider";

class ExhibitListItem extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      exhibit: { type: Object },
      index: { type: Number, attribute: true },
      api: { type: Number },
      activeEditBox: { type: String, attribute: true },
      setActiveEditBox: { type: Object, attribute: false },
      model: { type: Object, attribute: false },
      previewImage: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showModal", "showAlert", "showOverlay", "hidePopup", "setLoading"];
  }

  constructor() {
    super();

    this.index = 0;
    this.activeEditBox = "";
    this.setActiveEditBox = () => {};
    this.model = null;
  }

  async firstUpdated() {
    this.setLoading(true);

    this.api = Api.getInstance();
    this.setPreviewImage();
    this.exhibit.on("updated", () => {
      this.requestUpdate();
    });

    this.setLoading(false);
  }

  get selectDialog() {
    return html`
      <exhibit-select
        base-path=${this.basePath}
        .project=${this.project}
        .onSelect=${model => this.selectModel(model)}
      ></exhibit-select>
    `;
  }

  get uploadDialog() {
    return html`
      <file-upload
        base-path=${this.basePath}
        .project=${this.project}
        header=${`Add a visual for "${this.exhibit.get("model")?.name} with ${
          this.exhibit.get("model")?.meta.screenRatio
        } screen"`}
        description=${`Please select a file for upload. Visuals can be pictures (JPEG, PNG) or videos (MP4) and need an aspect ratio of ${
          this.exhibit.get("model")?.meta.screenRatio
        }. The maximum file size for an image is 500KB and for a video 5MB.`}
        .currentFile=${this.api.getFileSource(
          this.exhibit.get("keyVisual")?.previewId
        )}
        fileType=${this.exhibit.get("keyVisual")?.fileType}
        .onRemove=${() => this.removeVisualImage()}
        .onUpload=${keyVisualData => this.uploadVisualImage(keyVisualData)}
      ></file-upload>
    `;
  }

  async uploadVisualImage(keyVisualData) {
    this.setLoading(true);

    const visual = await this.api.addKeyVisualByExhibitId(
      this.exhibit.id,
      keyVisualData
    );

    this.exhibit.set("keyVisual", visual);
    this.exhibit.setKeyVisual();

    this.hidePopup();
    this.setLoading(false);
  }

  async removeVisualImage() {
    this.setLoading(true);

    const status = await this.exhibit.removeKeyVisual();

    if (status === 204) {
      this.showAlert("Removed", `Key visual has been removed`, "success");
    }

    if (status >= 400) {
      this.showAlert(
        "Romoving failed",
        `Removing key visual has failed.`,
        "error"
      );
    }

    this.hidePopup();
    this.setLoading(false);
  }

  setPreviewImage() {
    if (this.exhibit.get("model")?.previewImage) {
      this.previewImage = this.api.getFileSource(
        this.exhibit.get("model")?.previewImage
      );
    } else {
      this.previewImage = this.exhibitplacePreviewImage;
    }
  }

  get exhibitplacePreviewImage() {
    const exhibitplace = this.project
      .get("boothTemplate")
      .exhibits.find(exhibit => exhibit.name === this.exhibit.placeName);

    return this.api.getFileSource(exhibitplace.signImage);
  }

  selectModel(model) {
    this.model = model;
  }

  async setModel() {
    this.setLoading(true);

    const status = await this.exhibit.selectModel(this.model);
    this.save();
    this.setPreviewImage();
  }

  setRotation(event) {
    this.exhibit.setRotation(event.target.value);
  }

  setMovement(event) {
    this.exhibit.setMovement(event.target.value);
  }

  formatRotationValue(value) {
    return `${Math.round((value / Math.PI) * 180)}°`;
  }

  async save() {
    this.setLoading(true);

    try {
      const status = await this.exhibit.persist();

      if (status === 200) {
        this.showAlert(
          "Saved",
          `Settings for exhibit "${
            this.exhibit.get("model")?.name
          }" have been saved.`,
          "success"
        );
      }
    } catch (error) {
      this.showAlert(
        "Saving failed",
        `Saving settings for exhibit "${
          this.exhibit.get("model")?.name
        }" have failed.`,
        "error"
      );
    } finally {
      this.setActiveEditBox("");
      this.setLoading(false);
    }
  }

  async delete() {
    this.setLoading(true);

    const status = await this.exhibit.flush();

    if (status === 200) {
      this.showAlert("Deleted", `Exhibit have been removed.`, "success");
    }

    if (status >= 400) {
      this.showAlert(
        "Deletion failed",
        `Removing exhibit has failed.`,
        "error"
      );
    }

    this.setPreviewImage();
    this.setLoading(false);
  }

  render() {
    return html`
      <div class="exhibit-list-item">
        ${this.api
          ? html`
              <uimc-media-object class="display-block" breakpoint="md">
                ${this.previewImage
                  ? html`
                      <uimc-aspect-ratio
                        class="image-size-medium"
                        slot="media-object-image"
                        formats='{"xs": "4x3"}'
                        object-fit="contain"
                      >
                        <img src=${this.previewImage} />
                      </uimc-aspect-ratio>
                    `
                  : null}
                <div slot="media-object-body">
                  <h5 class="margin-top-0">${this.exhibit.get("headline")}</h5>

                  ${this.exhibit.get("model")?.name
                    ? html`
                        <p class="margin-top-0">
                          ${this.exhibit.get("model")?.name}
                        </p>
                      `
                    : null}
                  ${this.exhibit.get("model") === null
                    ? html`
                        <div class="margin-top-1">
                          <custom-button
                            .onClick=${() => {
                              this.showModal(
                                "Add an exhibit to the 3D booth",
                                this.selectDialog,
                                null,
                                "Add exhibit",
                                "Cancel",
                                "large",
                                () => this.setModel()
                              );
                            }}
                          >
                            Select an exhibit
                          </custom-button>
                        </div>
                      `
                    : html`
                        <div class="display-flex align-items-center">
                          ${this.project.get("boothTemplate")?.sliderValues
                            ?.rotate
                            ? html`
                                <custom-tooltip-button
                                  class="margin-right-1"
                                  ?disabled=${this.activeEditBox !== ""}
                                  tooltip="Rotate exhibit"
                                  type="control"
                                  .onClick=${() => {
                                    this.setActiveEditBox(
                                      "ROTATION",
                                      this.index
                                    );
                                  }}
                                >
                                  <img
                                    src="${this
                                      .basePath}assets/icons/rotate.svg"
                                  />
                                </custom-tooltip-button>
                              `
                            : null}
                          ${this.project.get("boothTemplate")?.sliderValues
                            ?.move
                            ? html`
                                <custom-tooltip-button
                                  class="margin-right-1"
                                  ?disabled=${this.activeEditBox !== ""}
                                  tooltip="Move exhibit"
                                  type="control"
                                  .onClick=${() => {
                                    this.setActiveEditBox("MOVE", this.index);
                                  }}
                                >
                                  <img
                                    src="${this
                                      .basePath}assets/icons/movement.svg"
                                  />
                                </custom-tooltip-button>
                              `
                            : null}
                          ${this.exhibit.get("model")?.type === "stele"
                            ? html`
                                ${this.exhibit.get("keyVisual")
                                  ? html`
                                      <custom-tooltip-button
                                        class="margin-right-1"
                                        ?disabled=${this.activeEditBox !== ""}
                                        tooltip="Change visual"
                                        type="control"
                                        icon="edit"
                                        .onClick=${() => {
                                          this.showOverlay(this.uploadDialog);
                                        }}
                                      >
                                        Change visual
                                      </custom-tooltip-button>
                                    `
                                  : html`
                                      <custom-tooltip-button
                                        class="margin-right-1"
                                        ?disabled=${this.activeEditBox !== ""}
                                        tooltip="Add visual"
                                        type="control"
                                        primary
                                        icon="plus"
                                        .onClick=${() => {
                                          this.showOverlay(this.uploadDialog);
                                        }}
                                      >
                                        Add visual
                                      </custom-tooltip-button>
                                    `}
                              `
                            : null}

                          <custom-tooltip-button
                            ?disabled=${this.activeEditBox !== ""}
                            tooltip="Delete exhibit"
                            class="margin-left-auto"
                            type="control"
                            icon="delete"
                            .onClick=${() => {
                              this.showModal(
                                "Delete exhibit",
                                `You are about to delete the exhibit "${
                                  this.exhibit.get("model")?.name
                                }". Do you really want to continue?`,
                                "warning",
                                "Delete exhibit",
                                "Cancel",
                                "small",
                                async () => {
                                  await this.delete();
                                }
                              );
                            }}
                          >
                            Delete exhibit
                          </custom-tooltip-button>
                        </div>
                      `}
                </div>
              </uimc-media-object>

              ${this.project.get("boothTemplate")?.sliderValues?.rotate &&
              this.activeEditBox === `ROTATION_${this.index}`
                ? html`
                    <edit-box
                      class="margin-top-1"
                      description=${"Move the sliders to rotate the exhibit. Press the save button to store the setting."}
                      .onSubmit=${async () => {
                        await this.save();
                      }}
                    >
                      ${this.project
                        .get("boothTemplate")
                        .sliderValues.rotate?.sliders?.map(slider => {
                          return html`
                            <div class="display-flex">
                              <uimc-aspect-ratio
                                formats='{"xs": "1x1"}'
                                class="image-size-xsmall display-inline-block margin-right-1"
                              >
                                <uimc-tooltip content="Rotate exhibit">
                                  <img
                                    src="${this
                                      .basePath}assets/icons/rotate.svg"
                                  />
                                </uimc-tooltip>
                              </uimc-aspect-ratio>
                              <custom-value-slider
                                .onChange=${event => this.setRotation(event)}
                                class="margin-right-auto"
                                label=${slider?.label}
                                max-value=${slider?.max}
                                min-value=${slider?.min}
                                steps=${slider?.step}
                                .formatValue=${this.formatRotationValue}
                                value=${this.exhibit.get("rotationY")}
                                ?hideTooltip=${false}
                              ></custom-value-slider>
                            </div>
                          `;
                        })}
                    </edit-box>
                  `
                : null}
              ${this.project.get("boothTemplate")?.sliderValues?.move &&
              this.activeEditBox === `MOVE_${this.index}`
                ? html`
                    <edit-box
                      class="margin-top-1"
                      description=${"Move the sliders to move the exhibit. Press the save button to store the setting."}
                      .onSubmit=${async () => {
                        await this.save();
                      }}
                    >
                      ${this.project
                        .get("boothTemplate")
                        .sliderValues.move?.sliders?.map(slider => {
                          return html`
                            <div class="display-flex">
                              <uimc-aspect-ratio
                                formats='{"xs": "1x1"}'
                                class="image-size-xsmall display-inline-block margin-right-1"
                              >
                                <uimc-tooltip content="Move exhibit">
                                  <img
                                    src="${this
                                      .basePath}assets/icons/movement.svg"
                                  />
                                </uimc-tooltip>
                              </uimc-aspect-ratio>
                              <custom-value-slider
                                .onChange=${event => this.setMovement(event)}
                                class="margin-right-auto"
                                label=${slider?.label}
                                max-value=${slider?.max}
                                min-value=${slider?.min}
                                steps=${slider?.step}
                                value=${slider?.value}
                                ?hideTooltip=${true}
                              ></custom-value-slider>
                              <!-- <uimc-button
                                type="control"
                                icon="reload"
                                .onClick=${() => {
                                console.log("reset rotation");
                              }}
                              ></uimc-button> -->
                            </div>
                          `;
                        })}
                    </edit-box>
                  `
                : null}
            `
          : null}
      </div>
    `;
  }
}

customElements.define("exhibit-list-item", ExhibitListItem);
