import axios from "axios";
import { getXYZArray } from "./helper";

async function createHotspot(hotspot, url, options) {
  const hotspotUrl = new URL(`/items/hotspots`, url);

  const res = await axios.post(hotspotUrl, hotspot, options);
  return getHotspot(res.data);
}

async function createContentPage(hotspotId, projectId, url, options) {
  const createContentPageUrl = new URL(`/create-contentpage`, url);

  const res = await axios.post(
    createContentPageUrl,
    {
      hotspotId,
      projectId,
    },
    options
  );

  return res?.data;
}

function getHotspotCopy(hotspot) {
  const {
    id,
    position,
    labelValue,
    labelPosition,
    contentType,
    animationName,
    nearestExhibitPlace,
    ...rest
  } = hotspot;

  return {
    ...rest,
    positionx: position[0],
    positiony: position[1],
    positionz: position[2],
    label: labelValue,
    labelposition: labelPosition,
    contenttype: contentType,
    animationname: animationName,
    type: "zoom",
    nearestexhibitplace: nearestExhibitPlace,
    status: "published",
  };
}

async function addHotspotToExhibit(hotspotId, exhibitId, url, options) {
  const exhibitsHotspotsUrl = new URL(`/items/exhibits_hotspots`, url);

  return axios.post(
    exhibitsHotspotsUrl,
    {
      exhibits_id: exhibitId,
      hotspots_id: hotspotId,
    },
    options
  );
}

function getHotspot(apiHotspot) {
  if (!apiHotspot) {
    return null;
  }
  if (apiHotspot.data) {
    apiHotspot = apiHotspot.data;
  }
  return {
    id: apiHotspot.id,
    labelValue: apiHotspot.label,
    labelPosition: apiHotspot.labelposition,
    level: apiHotspot.level,
    contentType: apiHotspot.contenttype,
    content: apiHotspot.content,
    animationName: apiHotspot.animationname,
    editingUrl: apiHotspot.editingurl,
    nearestExhibitPlace: apiHotspot.nearestexhibitplace,
    position: getXYZArray(apiHotspot, "position"),
  };
}

async function updateHotspot(id, fields, url, options) {
  const hotspotUrl = new URL(`/items/hotspots/${id}`, url);

  const res = await axios.patch(hotspotUrl, fields, options);

  return res;
}

async function deleteHotspots(ids, url, options) {
  const hotspotUrl = new URL("/items/hotspots", url);

  const res = await axios.delete(hotspotUrl, { ...options, data: ids });

  return res;
}

async function deleteHotspot(id, url, options) {
  const hotspotUrl = new URL(`/items/hotspots/${id}`, url);

  const res = await axios.delete(hotspotUrl, options);

  return res;
}

export {
  createHotspot,
  createContentPage,
  getHotspotCopy,
  addHotspotToExhibit,
  getHotspot,
  updateHotspot,
  deleteHotspots,
  deleteHotspot,
};
