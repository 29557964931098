import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../shared/styles.scss";
import custom from "./reset-password.scss";

import Api from "../../api/api-helper";

import "../../components/app-header/app-header";
import "../../custom-uimc/custom-textfield/custom-textfield";
import "../../components/password-validation/password-validation";

class ResetPassword extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      email: { type: String },
      password: { type: String },
      isInitialRequest: { type: Boolean },
      resetToken: { type: String },
    };
  }

  constructor() {
    super();
    this.email = null;
    this.password = null;
    this.currentUser = null;
    this.resetToken = null;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showAlert"];
  }

  firstUpdated() {
    this.api = Api.getInstance();
    this.getResetToken();
    this.removeAccessTokenFromUrl();
  }

  getResetToken() {
    const params = new URLSearchParams(window.location.search);
    if (!params.has("token")) {
      return;
    }

    this.resetToken = params.get("token");
  }

  removeAccessTokenFromUrl() {
    const { history, location } = window;
    const { search } = location;
    if (
      search &&
      search.indexOf("token") !== -1 &&
      history &&
      history.replaceState
    ) {
      // remove access_token from url
      const cleanSearch = search
        .replace(/(\&|\?)token([_A-Za-z0-9=\.%\-]+)/g, "")
        .replace(/^&/, "?");
      // replace search params with clean params
      const cleanURL = location.toString().replace(search, cleanSearch);
      // use browser history API to clean the params
      history.replaceState({}, "", cleanURL);
    }
  }

  setEmail(email) {
    this.email = email;
  }

  setPassword(password) {
    this.password = password;
  }

  handleKeyDownEnter(e) {
    if (e.key !== "Enter") {
      return;
    }

    this.handleSubmitEmail();
  }

  async handleSubmitEmail() {
    const status = await this.api.resetPassword(this.email);
    if (status === 204) {
      this.showAlert(
        "Password reset",
        `E-Mail was sent to ${this.email}`,
        "success"
      );
    } else {
      this.showAlert("Password reset", "Password reset failed.", "error");
    }
    this.setEmail(null);
    this.router.navigate("user-login");
  }

  async submitNewPassword() {
    if (this.password === null) {
      this.showAlert(
        "Password reset failed",
        "Missing token or password is invalid resp. do not match.",
        "error"
      );
    }
    const status = await this.api.submitNewPassword({
      password: this.password,
      token: this.resetToken,
    });
    if (status === 204) {
      this.showAlert(
        "Reset password successfully",
        `New password was set`,
        "success"
      );
    } else {
      this.showAlert("Reset password failed", `Password wasn't reset`, "error");
    }
    this.resetToken = null;
    this.router.navigate("user-login");
  }

  render() {
    return html`
      <app-header
        .router=${this.router}
        .currentUser=${() => null}
      ></app-header>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Reset password</h1>
          </div>
        </div>

        ${!this.resetToken
          ? html`
              <div class="row">
                <div class="col-6">
                  <p>
                    Please enter your e-mail address. You will receive an email
                    with the link for the password reset.
                  </p>

                  <custom-textfield
                    class="margin-bottom-1"
                    label="E-Mail"
                    .onChange=${email => {
                      this.setEmail(email);
                    }}
                    @keyup=${e => {
                      this.handleKeyDownEnter(e);
                    }}
                  ></custom-textfield>
                  <uimc-button
                    primary
                    @click=${() => {
                      this.handleSubmitEmail();
                    }}
                    >Send</uimc-button
                  >
                </div>
              </div>
            `
          : html`
              <div class="row">
                <div class="col-6">
                  <p>
                    Please enter your new password.
                  </p>
                  <password-validation
                    base-path=${this.basePath}
                    @keyupEnter=${() => this.submitNewPassword()}
                    .onChangePassword=${password => this.setPassword(password)}
                  ></password-validation>
                </div>
              </div>

              <div class="row margin-top-1">
                <div class="col-6">
                  <uimc-button
                    primary
                    @click=${() => {
                      this.submitNewPassword();
                    }}
                    >Send</uimc-button
                  >
                </div>
              </div>
            `}
      </div>
    `;
  }
}

customElements.define("reset-password", ResetPassword);
