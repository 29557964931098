import axios from "axios";

const fields = ["staticContent", "dynamicContent", "language"];

async function getContactTemplate(language = "en_US", url, options) {
  const requestUrl = new URL("/items/contacts", url);
  const filter = { _and: [{ name: "standard template" }, { language }] };

  requestUrl.searchParams.append("fields", fields.join(","));
  requestUrl.searchParams.append("filter", JSON.stringify(filter));

  const res = await axios.get(requestUrl, options);
  return res.data.data[0];
}

async function updateContactById(id, data, url, options) {
  const requestUrl = new URL(`/items/project_contacts/${id}`, url);
  const { language, staticContent, dynamicContent } = data;

  const res = await axios.patch(
    requestUrl,
    { language, staticContent, dynamicContent },
    options
  );
  return res.data;
}

export { getContactTemplate, updateContactById };
