import * as THREE from "three";
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper";

const COLOR = 0xffffff;

let experience = null;
let scene = null;
let camera = null;

export default class Environment {
  constructor({ exhibition }) {
    ({ experience } = exhibition);
    ({ scene } = experience);
    ({ camera } = experience);

    this.projectLights = experience.project.lights;

    if (!experience.useHdr) {
      this.setLights();
    }
    
  }

  setLights() {
    this.projectLights.map(light => this.setLight(light));
  }

  setLight(projectLight) {
    const { type, intensity, position, target, width, height } = projectLight;

    switch (type) {
      case "ambientLight":
        const ambientLight = new THREE.AmbientLight(COLOR, intensity);
        scene.add(ambientLight);
        break;
      case "frontDirectLight":
        const frontLight = new THREE.DirectionalLight(COLOR, intensity);
        frontLight.position.set(...position);
        scene.add(frontLight);
        break;
      case "topDirectLight":
        const topLight = new THREE.DirectionalLight(COLOR, intensity);
        topLight.position.set(...position);
        this.setTarget(topLight, target);
        this.setShadows(topLight, projectLight);
        scene.add(topLight);
        break;
      case "rectAreaLight":
        const rectAreaLight = new THREE.RectAreaLight(
          COLOR,
          intensity,
          width,
          height
        );
        rectAreaLight.position.set(...position);
        rectAreaLight.lookAt(...target);
        scene.add(rectAreaLight);
        const rectLightHelper = new RectAreaLightHelper(rectAreaLight);
        // scene.add(rectLightHelper);
        break;
      case "pointLight":
        const pointLight = new THREE.PointLight(COLOR, intensity);
        pointLight.position.set(...position);
        camera.instance.add(pointLight);
        const pointLightHelper = new THREE.PointLightHelper(pointLight, 1);
        // scene.add(pointLightHelper);
        break;
      default:
        break;
    }
  }

  setTarget(light, targetPosition) {
    const target = new THREE.Object3D(targetPosition);
    target.position.set(...targetPosition);
    scene.add(target);
    target.updateMatrix();
    target.updateMatrixWorld();
    light.target = target;

    const spotLightHelper = new THREE.SpotLightHelper(light);
    // scene.add(spotLightHelper);
  }

  setShadows(light, projectLight) {
    light.castShadow = true;
    light.shadow.camera.castShadow = true;

    light.shadow.camera.far = projectLight.shadowCamFar;
    light.shadow.camera.top = projectLight.shadowCamTop;
    light.shadow.camera.right = projectLight.shadowCamRight;
    light.shadow.camera.bottom = projectLight.shadowCamBottom;
    light.shadow.camera.left = projectLight.shadowCamLeft;
  }
}
