import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../shared/styles.scss";
import custom from "./change-password.scss";

import Api from "../../api/api-helper";

import "../../components/app-header/app-header";
import "../../custom-uimc/custom-textfield/custom-textfield";
import "../../components/password-validation/password-validation";

class ChangePassword extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      newPassword: { type: String },
    };
  }

  constructor() {
    super();
    this.password = null;
    this.currentUser = null;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["currentUser", "showAlert"];
  }

  firstUpdated() {
    this.api = Api.getInstance();
  }

  setNewPassword(password) {
    this.newPassword = password;
  }

  async submitNewPassword() {
    if (this.newPassword === null) {
      this.showAlert(
        "Password reset failed",
        "Password is missing or do not match.",
        "error"
      );
      return;
    }

    const status = await this.api.updatePassword({
      user: this.currentUser,
      password: this.newPassword,
    });

    if (status === 200) {
      this.showAlert(
        "Reset password successfully",
        `New password was set`,
        "success"
      );
    } else {
      this.showAlert("Reset password failed", "", "error");
    }
    this.router.navigate("project-list");
  }

  render() {
    return html`
      <app-header
        .router=${this.router}
        .currentUser=${() => null}
      ></app-header>

      <div class="container">
        <div class="row">
          <div class="col-6">
            <h1>Change password</h1>
            <p>
              Please enter your new password.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <password-validation
              class="margin-bottom-1"
              base-path=${this.basePath}
              @keyupEnter=${() => this.submitNewPassword()}
              .onChangePassword=${password => this.setNewPassword(password)}
            ></password-validation>
          </div>
        </div>

        <div class="row margin-top-1">
          <div class="col-6">
            <uimc-button
              primary
              @click=${() => {
                this.submitNewPassword();
              }}
              >Send</uimc-button
            >
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define("change-password", ChangePassword);
