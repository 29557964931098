import { LitElement, html, css } from "lit";

import shared from "../../../../shared/styles.scss";
import custom from "./hotspot-list.scss";

import "../hotspot-list-item/hotspot-list-item";

import "@shs/ui-marcom/src/js/collection";
import "@shs/ui-marcom/src/js/collection-item";
import "@shs/ui-marcom/src/js/label";
import "../../../../custom-uimc/custom-tooltip-button/custom-tooltip-button";

class HotspotList extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      experience: { type: Object },
      hotspots: { type: Array },
      activeEditBox: { type: String },
      switchZoomLevel: { type: Object },
    };
  }

  constructor() {
    super();
    this.hotspots = [];
    this.activeEditBox = "";
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  setActiveEditBox(activeEditBox, index) {
    if (index >= 0) {
      this.activeEditBox = `${activeEditBox}_${index}`;
      return;
    }

    this.activeEditBox = activeEditBox;
  }

  render() {
    return html`
      <div class="hotspot-list">
        <uimc-collection>
          ${this.hotspots.map((hotspot, index) => {
            return html`
              <uimc-collection-item>
                <hotspot-list-item
                  base-path=${this.basePath}
                  .project=${this.project}
                  .experience=${this.experience}
                  .hotspot=${hotspot}
                  index=${index}
                  activeEditBox=${this.activeEditBox}
                  .setActiveEditBox=${this.setActiveEditBox.bind(this)}
                  .switchZoomLevel=${this.switchZoomLevel}
                >
                </hotspot-list-item>
              </uimc-collection-item>
            `;
          })}
        </uimc-collection>
      </div>
    `;
  }
}

customElements.define("hotspot-list", HotspotList);
