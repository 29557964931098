import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../../shared/styles.scss";
import custom from "./file-upload.scss";

import Api from "../../../api/api-helper";

import "@shs/ui-marcom/src/js/collection-item";
import "@shs/ui-marcom/src/js/checkbox";
import "../../../custom-uimc/custom-file-picker/custom-file-picker";

class FileUpload extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      api: { type: Object },
      header: { type: String, attribute: true },
      description: { type: String, attribute: true },

      currentFile: { type: Object },
      selectedFile: { type: Object },
      selectedFileName: { type: String },
      uploadImage: { type: Object },
      fileType: { type: String },
      onRemove: { type: Object },
      onUpload: { type: Object },
    };
  }

  constructor() {
    super();

    this.selectedFileName = "";

    this.selectedFile = null;
    this.uploadImage = null;

    this.currentFile = null;
    this.onRemove = () => {};
    this.onUpload = () => {};

    this.api = Api.getInstance();
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["hidePopup", "setLoading"];
  }

  async firstUpdated() {
    this.project.on("updated", () => {
      this.requestUpdate();
    });
  }

  async select(event) {
    const file = event.target.files[0];
    this.fileType = file.type;
    this.selectedFile = await this.readFileAsync(file);
    this.selectedFileName = file.name;
  }

  async remove() {
    this.currentFile = null;
    this.onRemove();
    this.hidePopup();
  }

  async upload() {
    if (!this.selectedFile) return;

    this.uploadImage = this.selectedFile;

    this.setLoading(true);
    this.hidePopup();

    const { previewId, textureId } = await this.uploadFiles();

    const keyVisualData = {
      status: "published",
      name: this.selectedFileName,
      file: textureId,
      preview: previewId,
      fileType: this.fileType,
    };

    this.onUpload(keyVisualData);
    this.setLoading(false);
  }

  async uploadFiles() {
    const formDataPreview = new FormData();
    const formDataTexture = new FormData();
    formDataTexture.append(
      "title",
      this.selectedFileName.substring(0, this.selectedFileName.lastIndexOf("."))
    );

    formDataPreview.append(
      "title",
      "preview_" +
        this.selectedFileName.substring(
          0,
          this.selectedFileName.lastIndexOf(".")
        )
    );

    formDataTexture.append(
      "file",
      await this.convertToBlob(this.uploadImage),
      this.selectedFileName
    );

    formDataPreview.append(
      "file",
      await this.convertToBlob(this.selectedFile),
      "preview_" + this.selectedFileName
    );

    const previewRes = await this.api.uploadKeyVisualFiles(formDataPreview);
    const textureRes = await this.api.uploadKeyVisualFiles(formDataTexture);

    return {
      previewId: previewRes.data.data.id,
      textureId: textureRes.data.data.id,
    };
  }

  async convertToBlob(data) {
    return await (await fetch(data)).blob();
  }

  get asset() {
    if (this.selectedFile) {
      return this.selectedFile;
    }

    if (this.currentFile) {
      return this.currentFile;
    }
    return "";
  }

  static createImage(source) {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.src = source;

      image.onload = () => resolve(image);
      image.onerror = error => reject(error);
    });
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  render() {
    return html`
      <div
        class="upload-backwall-visual padding-top-1 padding-bottom-1 container"
      >
        ${this.api
          ? html`
            <div class="row">
              <div class="col-10">
                <h5 class="margin-top-0 margin-bottom-1">
                  ${this.header}
                </h5>
                ${
                  this.fileType === "video/mp4"
                    ? html`
                        ${this.asset !== ""
                          ? html`
                              <video
                                autoplay
                                muted
                                class="margin-bottom-1"
                                width="300"
                                src=${this.asset}
                              ></video>
                            `
                          : html`
                              <p>${this.description}</p>
                            `}
                      `
                    : html`
                        ${this.asset !== ""
                          ? html`
                              <img
                                class="margin-bottom-1"
                                width="300"
                                src=${this.asset}
                              />
                            `
                          : html`
                              <p>${this.description}</p>
                            `}
                      `
                }
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                ${
                  this.selectedFile
                    ? html`
                        <uimc-button
                          primary
                          id="upload_button"
                          icon="plus"
                          @click=${() => this.upload()}
                          >Add visual
                        </uimc-button>
                      `
                    : html`
                        <custom-file-picker
                          primary
                          .onSelect=${e => this.select(e)}
                          >Select a file</custom-file-picker
                        >
                      `
                }
                ${
                  this.currentFile
                    ? html`
                        <uimc-button
                          id="upload_button"
                          icon="delete"
                          @click=${() => this.remove()}
                          >Remove
                        </uimc-button>
                      `
                    : null
                }
                <uimc-button @click=${() =>
                  this.hidePopup()}> Cancel </uimc-button>
              </div>
            </div>
          </div>
        `
          : null}
      </div>
    `;
  }
}

customElements.define("file-upload", FileUpload);
