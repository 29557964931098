export default [
  {
    isValid: false,
    pattern: "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[_!@#$%^*&-.]).{8,}$",
    hintText: null,
  },
  { isValid: false, pattern: "^.{8,}$", hintText: "At least 8 characters" },
  {
    isValid: false,
    pattern: "^(?=.*[A-Z]).*$",
    hintText: "Contains an upppercase letter",
  },
  {
    isValid: false,
    pattern: "^(?=.*[a-z]).*$",
    hintText: "Contains a lowercase letter",
  },
  { isValid: false, pattern: "^(?=.*[0-9]).*$", hintText: "Contains a number" },
  {
    isValid: false,
    pattern: "^(?=.*[_!@#$%^*&-.]).*$",
    hintText: "Contains a symbol (-_!@#$%^&*.)",
  },
];
