import axios from "axios";

const selectedFields = [
  "id",
  "name",
  "type",
  "previewimage",
  "model",
  "aomodel",
  "scale",
  "meta",
  "textures.directus_files_id.id",
  "textures.directus_files_id.filename_download",
];

async function getModels(url, options) {
  const requestUrl = new URL("/items/models", url);
  requestUrl.searchParams.append("fields", selectedFields.join(","));

  const res = await axios.get(requestUrl, options);
  const apiModels = res.data.data;

  return apiModels.map((model) => {
    return {
      id: model.id,
      name: model.name,
      type: model.type,
      previewImage: model.previewimage,
      model: model.model,
      aoModel: model.aomodel,
      scale: model.scale,
      meta: model.meta,
      textures: getTextureObject(model.textures),
    };
  });
}

function getModel(apiModel) {
  return {
    id: apiModel.id,
    name: apiModel.name,
    type: apiModel.type,
    previewImage: apiModel.previewimage,
    model: apiModel.model,
    aoModel: apiModel.aomodel,
    scale: apiModel.scale,
    meta: apiModel.meta,
    textures: getTextureObject(apiModel.textures),
  };
}

export { getModels, getModel };

function getTextureObject(apiTexturesObject) {
  if (!apiTexturesObject || apiTexturesObject.length <= 0) return [];
  apiTexturesObject = apiTexturesObject.filter(
    (to) => to.directus_files_id !== null
  );

  return apiTexturesObject.map((ato) => {
    const texture = ato.directus_files_id;
    return {
      id: texture.id,
      filename: texture.filename_download,
    };
  });
}
