import * as THREE from "three";
import { CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer";
import * as TWEEN from "@tweenjs/tween.js/dist/tween.umd";

import ApiModel from "../Models/ApiModel";
import "@shs/ui-marcom/src/js/button";

let experience = null;
let cssScene = null;

export default class PlatformLabel extends ApiModel {
  constructor(exhibition, platform) {
    super();

    // Options
    ({ experience } = exhibition.exhibition);
    ({ cssScene } = experience);
    this.platform = platform;
    this.setPlatformButton();
  }

  setPlatformButton() {
    this.button = document.createElement("uimc-button");
    this.button.innerHTML = this.platform.platformData.hotspot.labelValue;
    this.button.setAttribute("theme", "dark");
    this.button.addEventListener(
      "pointerdown",
      this.handleLabelClick.bind(this)
    );
    this.button.addEventListener("pointerover", this.pointerOver.bind(this));
    this.button.addEventListener("pointerout", this.pointerOut.bind(this));

    const labelObj = new CSS3DObject(this.button);
    this.labelButton = new THREE.Group();
    this.labelButton.add(labelObj);
    this.labelButton.position.set(
      ...this.platform.platformData.hotspot.position
    );

    this.labelButton.rotation.y = 1.57;
    this.labelButton.scale.set(0, 0, 0);

    cssScene.add(this.labelButton);
  }

  handleLabelClick() {
    this.platform.zoomToPlatform();
  }

  pointerOver() {
    this.setPrimary();
    this.platform.setPlatformActive();
  }

  pointerOut() {
    if (!this.platform.isZoomed) {
      this.platform.setPlatformUnActive();
    }
  }

  setPrimary() {
    this.button.setAttribute("primary", true);
  }

  unsetPrimary() {
    this.button.removeAttribute("primary", true);
  }

  scaleDown() {
    new TWEEN.Tween(this.labelButton.scale)
      .to({ x: 0, y: 0, z: 0 }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  }

  scaleUp() {
    new TWEEN.Tween(this.labelButton.scale)
      .to({ x: 1, y: 1, z: 1 }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  }

  async saveLabel() {
    const res = await this.api.updateHotspotById(
      this.platform.platformData.hotspot.id,
      {
        label: this.platform.platformData.hotspot.labelValue,
      }
    );

    await this.api.updateProjectById(experience.project.id, {
      date_updated: new Date(),
    });

    this.button.innerHTML = this.platform.platformData.hotspot.labelValue;
    return res.status;
  }
}
