import * as THREE from "three";
import { PMREMGenerator } from "three/src/extras/PMREMGenerator.js";

import ApiModel from "./Models/ApiModel";
import Sizes from "./Utils/Sizes";
import Loop from "./Utils/Loop";
import Camera from "./Camera";
import Renderer from "./Renderer";

import Exhibition from "./Exhibition/Exhibition";
import Resources from "./Utils/Resources";

import Raycaster from "./Raycaster";
import Stats from "./Utils/Stat";
import { Tween } from "@tweenjs/tween.js";

export default class Experience extends ApiModel {
  constructor(canvas, container, project, editMode = false) {
    super();

    // global access
    window.experience = this;

    // options
    this.canvas = canvas;
    this.container = container;
    this.project = project;

    // setup
    this.sizes = new Sizes({ container: this.container });
    this.scene = new THREE.Scene();
    this.cssScene = new THREE.Scene();
    this.camera = new Camera({ experience: this, editMode });
    this.renderer = new Renderer({ experience: this });
    this.loop = new Loop({ renderer: this.renderer });

    const axesHelper = new THREE.AxesHelper(100);
    // this.scene.add(axesHelper);

    // set controls after instanciate CSS3DRenderer
    this.camera.setControls();
    this.camera.setInit();

    this.raycaster = new Raycaster({ experience: this });
    this.stats = new Stats();
    this.resources = new Resources({ experience: this });
    this.exhibition = new Exhibition({ experience: this, editMode });
   

    if(!this.is_touch_enabled() || this.isStandaloneBoothType()) {
      this.useHdr = true;
    }

    this.resources.on("init", () => { 
      if(this.useHdr) {
        this.resources.loadRGBEImage(this.project.boothTemplate.iblImage).then(() => {
          console.log(this.resources.rgbeImage);
          const pmremGenderator = new PMREMGenerator(this.renderer.webGlInstance);
          const pmremTexture = pmremGenderator.fromEquirectangular(
            this.resources.rgbeImage
          ).texture;
          this.scene.environment = pmremTexture;
        });
      }
    });
    
    this.exhibition.on("loaded", () => {
      this.loopAnimation = true;
    });

    this.loop.start();

    // Resize event
    this.sizes.on("resize", () => {
      this.resize();
    });

    // Time tick event
    this.loop.on("tick", () => {
      this.update();
    });
  }

  is_touch_enabled() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  isStandaloneBoothType() {
    return this.project.boothTemplate.type === "standalone" || this.project.boothTemplate.type === "environment" ;
  }

  resize() {
    this.camera.resize();
    this.renderer.resize();
  }

  update() {
    this.camera.update();
    this.renderer.update();
    this.raycaster.update();
    this.stats.update();

    this.project?.exhibits?.map(e => {
      if (e.animationController?.length > 0) {
        e.animationController.map(c => {
          if (c.isPlaying) {
           c.update(this.loop.delta)
          }
        })           
      }
    })

    if (this.loopAnimation) {
      this.exhibition.floor.update();
    }
  }

  dispose() {
    this.loop.stop();
    this.sizes.off("resize");
    this.loop.off("tick");
    this.exhibition.dispose();
    this.raycaster.dispose();
    this.camera.dispose();
    this.renderer.dispose();
    this.stats.dispose();
  }
}
