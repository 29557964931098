import { LitElement, html, css } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html";

import { marked } from "marked";

import shared from "../../shared/styles.scss";
import custom from "./markdown-renderer.scss";

class MarkdownRenderer extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      md: { type: String, attribute: true },
      content: { type: String, attribute: false },
    };
  }

  constructor() {
    super();
    this.md = "";
    this.content = "";
  }

  firstUpdated() {
    marked.use({ renderer: this.renderer });
    this.content = marked.parse(this.md);
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  get renderer() {
    return {
      heading(text, level) {
        const escapedText = text.toLowerCase().replace(/[^\w]+/g, "-");

        level += 3;
        if (level > 6) {
          level = 6;
        }

        return `
          <h${level} class="margin-top-0" id="${escapedText}">
            ${text}
          </h${level}>`;
      },
      list(body) {
        return `
          <ul class="list list--bullet">
            ${body}
          </ul>`;
      },
      listitem(text) {
        return `
          <li class="list__item">
            ${text}
          </li>`;
      },
    };
  }

  render() {
    return html`
      <div class="markdown-renderer wysiwyg">
        ${unsafeHTML(this.content)}
      </div>
    `;
  }
}

customElements.define("markdown-renderer", MarkdownRenderer);
