import * as THREE from "three";
import { CSS3DRenderer } from "three/examples/jsm/renderers/CSS3DRenderer";

let canvas = null;
let sizes = null;
let scene = null;
let cssScene = null;
let camera = null;
let project = null;

export default class Renderer {
  constructor({ experience }) {
    ({ canvas } = experience);
    ({ sizes } = experience);
    ({ scene } = experience);
    ({ cssScene } = experience);
    ({ camera } = experience);
    ({ project } = experience);

    this.setWebGlInstance();
    this.setCssInstance();
  }

  setWebGlInstance() {
    this.webGlInstance = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true,
      logarithmicDepthBuffer: true,
    });

    this.webGlInstance.setClearColor(0x11111, 0);
    this.webGlInstance.shadowMap.enabled = true;
    this.webGlInstance.shadowMapSoft = true;
    this.webGlInstance.shadowMap.type = THREE.PCFSoftShadowMap;
    this.webGlInstance.setSize(sizes.width, sizes.height);
    this.webGlInstance.setPixelRatio(sizes.pixelRatio);

    //Only if hdr image is used
    this.webGlInstance.toneMapping = THREE.LinearToneMapping;
    if (project.boothTemplate.type === "standalone" || project.boothTemplate.type === "environment") {
      this.webGlInstance.toneMappingExposure = 0.6;
    }else {this.webGlInstance.toneMappingExposure = 1;}
  }

  setCssInstance() {
    this.cssInstance = new CSS3DRenderer();
    this.cssInstance.setSize(sizes.width, sizes.height);
    this.cssInstance.domElement.style.position = "absolute";
    this.cssInstance.domElement.style.top = 0;
    this.cssInstance.domElement.style.left = 0;
    this.cssInstance.domElement.classList.add("hotspots");

    canvas.parentElement.appendChild(this.cssInstance.domElement);
  }

  resize() {
    this.webGlInstance.setSize(sizes.width, sizes.height);
    this.webGlInstance.setPixelRatio(sizes.pixelRatio);
    this.cssInstance.setSize(sizes.width, sizes.height);
  }

  update() {
    this.webGlInstance.render(scene, camera.instance);
    this.cssInstance.render(cssScene, camera.instance);
  }

  dispose() {
    this.cssInstance = null;
    this.webGlInstance.dispose();
  }
}
