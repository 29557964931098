import * as THREE from "three";
import EventEmitter from "./EventEmitter.js";
import { AnimationUtils } from "three/src/animation/AnimationUtils.js";

export default class AnimationController extends EventEmitter {
  constructor(object, animationData) {
    super();
    this.isPlaying = false;
    this.object3D = object;
    this.animation = this.object3D.animations[0];
    this.startFrame = animationData.startFrame;
    this.endFrame = animationData.endFrame;
    this.name = animationData.name;
    this.init();
  }

  init() {
    this.mixer = new THREE.AnimationMixer(this.object3D.scene);
   
    if (this.startFrame && this.endFrame) {
      this.supClip = AnimationUtils.subclip(
        this.animation,
        "animation_0",
        this.startFrame,
        this.step1Frame,
        25
      );
      this.action = this.mixer.clipAction(this.supClip);
    }else {
      this.action = this.mixer.clipAction(this.animation);
    }

    this.mixer.addEventListener('finished', this.handleFinish.bind(this));
  }

  handleFinish() {
    this.reset();
  }

  update(timeDelta) {
    this.mixer.update(timeDelta * 0.001);
  }

  loopOnce() {
    this.action.setLoop(THREE.LoopOnce);
  }

  loopRepeat() {
    this.action.setLoop(THREE.LoopRepeat);
  }

  play() {
    this.isPlaying = true
    // this.loopRepeat();
    this.action.paused = false;
    this.action.play();
  }

  reset() {
    this.isPlaying = false
    this.action.reset();
    this.action.paused = true;
  }

  pause() {
    this.isPlaying =false;
    this.action.paused = true;
  }

  startAt(time) {
    this.isPlaying = true;
    this.action.paused = false;
    this.action.startAt(time);
  }
}
