import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import Api from "../../../../api/api-helper.js";

import shared from "../../../../shared/styles.scss";
import custom from "./hotspot-list-item.scss";

import "@shs/ui-marcom/src/js/aspect-ratio";
import "@shs/ui-marcom/src/js/tooltip";
import "@shs/ui-marcom/src/js/button";
import "@shs/ui-marcom/src/js/menu";
import "@shs/ui-marcom/src/js/menu-item";
import "../../../../custom-uimc/custom-textfield/custom-textfield";
import "../../../../custom-uimc/custom-value-slider/custom-value-slider";
import "../../../../custom-uimc/custom-checkbox/custom-checkbox";
import "../../../../custom-uimc/custom-dropdown/custom-dropdown";

import { hasAuthToken, handleInvalidRequest } from "../../../../api/helper.js";

class HotspotListItem extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      experience: { type: Object },
      hotspot: { type: Object },
      index: { type: Number },
      activeEditBox: { type: String, attribute: true },
      setActiveEditBox: { type: Object },
      switchZoomLevel: { type: Object },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["setLoading", "showAlert", "showModal"];
  }

  constructor() {
    super();

    this.hotspot = null;
    this.activeEditBox = "";
    this.setActiveEditBox = () => {};
    this.switchZoomLevel = () => {};

    this.api = Api.getInstance();
  }

  async firstUpdated() {
    this.hotspot.on("updated", () => {
      this.requestUpdate();
    });
  }

  get position() {
    const [x, y, z] = this.hotspot.get("position");
    return { x, y, z };
  }

  get animationItems() {
    return  this.hotspot.exhibit.animationController.map(ac => {
        const animation = {
          name:  ac.name,
          value:  ac.name,
          isSelected: this.hotspot.animationName === ac.name
        };
        return animation
      });
    }

  setLabel(label) {
    this.hotspot.set("labelValue", label);
  }

  setContent(content) {
    this.hotspot.set("content", content);
  }

  setEditingUrl(url) {
    this.hotspot.set("editingUrl", url);
  }

  setAnimation(name){
   this.hotspot.set("animationName", name);
  }

  setPosition(axis, value) {
    switch (axis) {
      case "x":
        this.hotspot.moveOnXAxis(value);
        break;
      case "y":
        this.hotspot.moveOnYAxis(value);
        break;
      case "z":
        this.hotspot.moveOnZAxis(value);
        break;
      default:
    }
  }

  getDistanceBySliderType(sliderType) {
    switch (sliderType) {
      case "x":
        return this.hotspot.distanceXFromExhibitPlace;
      case "y":
        return this.hotspot.distanceYFromExhibitPlace;
      case "z":
        return this.hotspot.distanceZFromExhibitPlace;
      default:
        return 0;
    }
  }

  setOrientation(checked) {
    if (checked) {
      this.hotspot.setLabelPosition("left");
    } else {
      this.hotspot.setLabelPosition("right");
    }
  }

  async save() {
    this.setLoading(true);

    try {
      const status = await this.hotspot.persist();

      this.setActiveEditBox("");

      if (status === 200) {
        this.showAlert("Saved", "Hotspot settings have been saved.", "success");
      }
    } catch (error) {
      if (!hasAuthToken()) {
        handleInvalidRequest(this.router, this.showAlert);
      } else {
        this.showAlert(
          "Saving",
          "Saving hotspot settings have failed.",
          "success"
        );
      }
    } finally {
      this.project.trigger("updated");
      this.setLoading(false);
    }
  }

  async delete() {
    this.setLoading(true);

    try {
      const status = await this.hotspot.flush();

      this.setActiveEditBox("");

      if (status === 204) {
        this.showAlert("Deleted", "Hotspot(s) deleted", "success");
      }
    } catch (error) {
      if (!hasAuthToken()) {
        handleInvalidRequest(this.router, this.showAlert);
      } else {
        this.showAlert("Deletion failed", "Delete hotspot(s) failed", "error");
      }
    } finally {
      this.project.trigger("updated");

      this.setLoading(false);
    }
  }

  render() {
    return html`
      <div class="hotspot-list-item">
        <div class="display-flex">
          <h5 class="margin-bottom-0 margin-top-0">
            ${this.hotspot.get("labelValue")}
            ${this.hotspot.get("contentType")
              ? html`
                  <uimc-label>
                    ${this.hotspot.get("contentType")}
                  </uimc-label>
                `
              : null}
          </h5>

          <div class="margin-left-auto">
            <custom-dropdown isArrowHidden="true" label="..." right-aligned>
              <uimc-menu>
                <!-- Edit label -->
                <uimc-menu-item
                  .onClick=${() => {
                    this.setActiveEditBox("LABEL", this.index);
                  }}
                  ><i class="color-orange icon-edit"></i> Edit
                  label</uimc-menu-item
                >

                <!-- Edit content -->
                <!-- intern -->
                ${this.hotspot.get("contentType") === "tooltip"
                  ? html`
                      <uimc-menu-item
                        .onClick=${() => {
                          this.setActiveEditBox("CONTENT", this.index);
                        }}
                        ><i class="color-orange icon-edit"></i> Edit
                        content</uimc-menu-item
                      >
                    `
                  : null}

                <!-- extern -->
                ${this.hotspot.get("editingUrl")
                  ? html`
                      <uimc-menu-item
                        .onClick=${() => {
                          window.open(this.hotspot.get("editingUrl"), "_blank");
                        }}
                        ><i class="color-orange icon-extern"></i> Edit
                        content</uimc-menu-item
                      >
                    `
                  : null}

                <!-- Move hotspot -->
                <uimc-menu-item
                  .onClick=${() => {
                    this.setActiveEditBox("POSITION", this.index);
                  }}
                >
                  <span style="width:22px; padding:0px 2px;"
                    ><img
                      width="18"
                      src="${this.basePath}assets/icons/movement.svg"
                  /></span>
                  Move hotspot
                </uimc-menu-item>

                 <!-- Add animation to hotspot -->
                ${this.hotspot.exhibit.animationController?.length > 0 ?  
                  html`
                <uimc-menu-item
                  .onClick=${() => {
                    this.setActiveEditBox("ANIMATION", this.index);
                  }}
                  ><i class="color-orange icon-edit"></i>Edit animation</uimc-menu-item
                >`:null}
                   
                <!-- Delete hotspot -->
                <uimc-menu-item
                  .onClick=${() => {
                    this.showModal(
                      "Delete hotspot",
                      `You are about to delete the hotspot "${this.hotspot.get(
                        "labelValue"
                      )}". Do you really want to continue?`,
                      "warning",
                      "Delete hotspot",
                      "Return",
                      "small",
                      async () => {
                        await this.delete();
                      }
                    );
                  }}
                  ><i class="color-orange icon-delete"></i> Delete
                  hotspot</uimc-menu-item
                >

                <hr class="margin-top-0 margin-bottom-0" />

                <!-- Edit scrivito urls -->
                ${this.hotspot.get("contentType") === "small" ||
                this.hotspot.get("contentType") === "large"
                  ? html`
                      <uimc-menu-item
                        .onClick=${() => {
                          this.setActiveEditBox("CONTENT", this.index);
                        }}
                        ><i class="color-orange icon-edit"></i> Edit content
                        URL</uimc-menu-item
                      >
                      <uimc-menu-item
                        .onClick=${() => {
                          this.setActiveEditBox("EDITING_URL", this.index);
                        }}
                        ><i class="color-orange icon-edit"></i> Edit editing
                        URL</uimc-menu-item
                      >
                    `
                  : null}
              </uimc-menu>
            </custom-dropdown>
          </div>
        </div>

        ${this.hotspot.get("contentType") === "zoom"
          ? html`
              <div class="display-flex align-items-center  margin-top-1">
                <custom-tooltip-button
                  tooltip="Add second level hotspts"
                  icon="arrow-right-small"
                  ?disabled=${this.activeEditBox !== ""}
                  .onClick=${() => {
                    this.switchZoomLevel(this.hotspot);
                  }}
                >
                  Switch zoom level
                </custom-tooltip-button>
              </div>
            `
          : null}
        ${this.activeEditBox === `LABEL_${this.index}`
          ? html`
              <edit-box
                class="margin-top-1"
                description=${"Type in the hotspot label and press the save button to store the setting."}
                .onSubmit=${async () => this.save()}
              >
                <p>
                  <custom-textfield
                    theme="gray"
                    .onChange=${label => {
                      this.setLabel(label);
                    }}
                    label="Hotspot label"
                    value="${this.hotspot.get("labelValue")}"
                  ></custom-textfield>
                </p>
                <p class="margin-bottom-0">
                  <custom-checkbox
                    ?checked=${this.hotspot.get("labelPosition") === "left"}
                    .onChange=${checked => this.setOrientation(checked)}
                  >
                    Left-handed label
                  </custom-checkbox>
                </p>
              </edit-box>
            `
          : null}
        ${this.activeEditBox === `CONTENT_${this.index}`
          ? html`
              <edit-box
                class="margin-top-1"
                description=${"Type in the hotspot content and press the save button to store the setting."}
                .onSubmit=${() => this.save()}
              >
                <custom-textfield
                  theme="gray"
                  .onChange=${content => {
                    this.setContent(content);
                  }}
                  label="Hotspot content"
                  value="${this.hotspot.get("content")}"
                ></custom-textfield>
              </edit-box>
            `
          : null}

          ${this.activeEditBox === `ANIMATION_${this.index}`
          ? html`
              <edit-box
                class="margin-top-1"
                description=${"Choose an animation who should play on hotspot click"}
                .onSubmit=${() => this.save()}
              >
                <custom-select 
                  label="Animations" 
                  .isChosen=${true}
                  .onChange=${animationValue => {
                    this.setAnimation(animationValue)
                  }}
                  .items="${[...this.animationItems]}">
                </custom-select>
              </edit-box>
            `
          : null}

        ${this.activeEditBox === `POSITION_${this.index}`
          ? html`
              <edit-box
                class="margin-top-1"
                description=${"Move the sliders to move the hotspot. Press the save button to store the setting."}
                .onSubmit=${() => this.save()}
              >
                ${this.project
                  .get("boothTemplate")
                  ?.sliderValues?.hsMove?.sliders?.map(slider => {
                    return html`
                      <div class="display-flex margin-bottom-1">
                        <uimc-aspect-ratio
                          formats='{"xs": "1x1"}'
                          class="image-size-xsmall display-inline-block margin-right-1"
                        >
                          ${slider?.type
                            ? html`
                                <uimc-tooltip content="Move hotspot">
                                  <img
                                    src="${this
                                      .basePath}assets/icons/${slider?.icon}.svg"
                                  />
                                </uimc-tooltip>
                              `
                            : null}
                        </uimc-aspect-ratio>
                        <custom-value-slider
                          class="margin-right-auto"
                          label=${`${slider?.label} ${slider.type}`}
                          max-value=${slider?.max}
                          min-value=${slider?.min}
                          steps=${slider?.step}
                          value=${this.getDistanceBySliderType(slider.type)}
                          ?hideTooltip=${true}
                          .onChange=${event => {
                            const { value } = event.target;
                            this.setPosition(slider?.type, value);
                          }}
                        ></custom-value-slider>
                      </div>
                    `;
                  })}
              </edit-box>
            `
          : null}
        ${this.activeEditBox === `EDITING_URL_${this.index}`
          ? html`
              <edit-box
                class="margin-top-1"
                description=${"Type in the editing URL and press the save button to store the setting."}
                .onSubmit=${async () => this.save()}
              >
                <p>
                  <custom-textfield
                    theme="gray"
                    .onChange=${url => {
                      this.setEditingUrl(url);
                    }}
                    label="Editing URL"
                    value="${this.hotspot.get("editingUrl")}"
                  ></custom-textfield>
                </p>
              </edit-box>
            `
          : null}
      </div>
    `;
  }
}

customElements.define("hotspot-list-item", HotspotListItem);
