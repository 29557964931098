import axios from "axios";

const selectedFields = ["id", "name", "label", "description"];

async function getBusinessLines(url, options) {
  const requestUrl = new URL("/items/businessline", url);
  requestUrl.searchParams.append("fields", selectedFields.join(","));

  const res = await axios.get(requestUrl, options);
  const apiBusinessLines = res.data.data;

  return apiBusinessLines.map(bl => {
    return {
      id: bl.id,
      name: bl.name,
      label: bl.label,
      description: bl.description,
    };
  });
}

async function getBusinessLineById(id, url, options) {
  const requestUrl = new URL(`/items/businessline/${id}`, url);
  requestUrl.searchParams.append("fields", selectedFields.join(","));

  const res = await axios.get(requestUrl, options);
  const apiBusinessLine = res.data.data;

  return {
    id: apiBusinessLine.id,
    name: apiBusinessLine.name,
    label: apiBusinessLine.label,
    description: apiBusinessLine.description,
  };
}

export { getBusinessLines, getBusinessLineById };
