import { LitElement, html, css } from "lit";
import { classMap } from "lit/directives/class-map";

import shared from "../../shared/styles.scss";
import custom from "./editor-panel.scss";

import "../settings/general-settings/general-settings-tab/general-settings-tab";
import "../settings/exhibit-settings/exhibit-settings-tab/exhibit-settings-tab";
import "../settings/hotspot-settings/hotspot-settings-tab/hotspot-settings-tab";
import "../settings/publish-settings/publish-settings-tab/publish-settings-tab";
import "../settings/contact-settings/contact-settings-tab/contact-settings-tab";

import "@shs/ui-marcom/src/js/tooltip";
import "@shs/ui-marcom/src/js/tabs";
import "@shs/ui-marcom/src/js/tabs-item";
import "@shs/ui-marcom/src/js/button";
import "@shs/ui-marcom/src/js/toggle-switch";
import "../../custom-uimc/custom-tabs/custom-tabs";

class EditorPanel extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      project: { type: Object },
      isHidden: { type: Boolean, attribute: false },
      activeTab: { type: Object, attribute: false },
      selectedPlatform: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  constructor() {
    super();
    this.isHidden = false;
    this.activeTab = null;
    this.selectedPlatform = "default";
    this.tabs = [
      { label: "Settings" },
      { label: "Exhibits" },
      { label: "Hotspots" },
      { label: "Contact" },
      { label: "Publish" },
    ];
  }

  firstUpdated() {
    this.project.on("updated", () => {
      this.requestUpdate();
    });
  }

  toggle() {
    this.isHidden = !this.isHidden;
  }

  resetSelectedPlattform() {
    this.selectedPlatform = "default";
  }

  handleSelectedPlatformEvent(event) {
    this.selectedPlatform = event.detail;
  }

  render() {
    return html`
      <div
        class="editor-panel padding-top-1 padding-bottom-1 ${classMap({
          hidden: this.isHidden,
        })}"
      >
        <div class=" container">
          <div class="row">
            <div class="col-12">
              <div
                class="display-flex align-items-center justify-content-between margin-bottom-1"
              >
                <h4 class="editor-panel__title margin-top-0 margin-bottom-0">
                  ${this.project.get("name")}
                </h4>
                <uimc-tooltip content="Close project">
                  <uimc-button
                    icon="close"
                    type="control"
                    @click=${() => {
                      this.project.get("experience").dispose();
                      this.router.navigate("project-list");
                    }}
                    >Close project</uimc-button
                  >
                </uimc-tooltip>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <custom-tabs
                class="editor-panel__tabs"
                .onChange=${item => {
                  this.activeTab = item;
                }}
                .items="${this.tabs}"
              >
                <uimc-tabs-item>
                  ${this.activeTab?.label === "Settings"
                    ? html`
                        <general-settings
                          base-path=${this.basePath}
                          .project=${this.project}
                          .router=${this.router}
                        ></general-settings>
                      `
                    : null}
                </uimc-tabs-item>
                <uimc-tabs-item>
                  ${this.activeTab?.label === "Exhibits"
                    ? html`
                        <exhibit-settings
                          base-path=${this.basePath}
                          .project=${this.project}
                          .selectedPlatform=${this.selectedPlatform}
                          .activeTab=${this.activeTab}
                          @selected-platform-event=${event =>
                            this.handleSelectedPlatformEvent(event)}
                          @reset-selected-platform-event=${() =>
                            this.resetSelectedPlattform()}
                        ></exhibit-settings>
                      `
                    : null}
                </uimc-tabs-item>
                <uimc-tabs-item>
                  ${this.activeTab?.label === "Hotspots"
                    ? html`
                        <hotspot-settings
                          base-path=${this.basePath}
                          .project=${this.project}
                          .selectedPlatform=${this.selectedPlatform}
                          .activeTab=${this.activeTab}
                          @selected-platform-event=${event =>
                            this.handleSelectedPlatformEvent(event)}
                          @reset-selected-platform-event=${() =>
                            this.resetSelectedPlattform()}
                        ></hotspot-settings>
                      `
                    : null}
                </uimc-tabs-item>
                <uimc-tabs-item>
                  ${this.activeTab?.label === "Contact"
                    ? html`
                        <contact-settings
                          base-path=${this.basePath}
                          .project=${this.project}
                          .router=${this.router}
                          .activeTab=${this.activeTab}
                        ></contact-settings>
                      `
                    : null}
                </uimc-tabs-item>
                <uimc-tabs-item>
                  ${this.activeTab?.label === "Publish"
                    ? html`
                        <publish-settings
                          base-path=${this.basePath}
                          .project=${this.project}
                          .router=${this.router}
                        ></publish-settings>
                      `
                    : null}
                </uimc-tabs-item>
              </custom-tabs>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define("editor-panel", EditorPanel);
