import { LitElement, html, css } from "lit";

import "../../../../custom-uimc/custom-select/custom-select";

import shared from "../../../../shared/styles.scss";
import custom from "./contact-option-config.scss";

class ContactOptionConfig extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      onInput: { type: Object },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  render() {
    return html`
      <div class="contact-option-config display-flex flex-column">
        <div class="col-10">
          <p>
            <custom-textfield
              label="Contact option title"
              .onChange=${contactTitle =>
                this.onInput("contactTitle", contactTitle)}
            ></custom-textfield>
          </p>
          <p>
            <custom-select
              label="Contact option type"
              .items=${[
                { value: "Appointment", name: "Appointment" },
                { value: "E-Mail", name: "E-Mail" },
              ]}
              .onChange=${contactType => {
                this.onInput("contactType", contactType);
              }}
            >
            </custom-select>
          </p>
          <p>
            <custom-textfield
              label="Type of meeting"
              .onChange=${meetingType =>
                this.onInput("meetingType", meetingType)}
            ></custom-textfield>
          </p>
        </div>
      </div>
    `;
  }
}

customElements.define("contact-option-config", ContactOptionConfig);
