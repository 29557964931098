import axios from "axios";

async function createKeVisual(data, url, options) {
  const requestUrl = new URL(`/items/keyvisuals`, url);

  const res = await axios.post(requestUrl, data, options);

  return res;
}

async function deleteKeyVisual(id, url, options) {
  const keyVisualUrl = new URL(`/items/keyvisuals/${id}`, url);

  const res = await axios.delete(keyVisualUrl, options)

  return res.status
}

export { createKeVisual, deleteKeyVisual };
