import { LitElement, html, css } from "lit-element";

import shared from "../../shared/styles.scss";
import custom from "./password-validation.scss";

import "../validation-hints/validation-hints";

import VALIDATION_PATTERN from "../../shared/const/password-validation";

class PasswordValidation extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      password: { type: String },
      confirmPassword: { type: String },
      onChangePassword: { type: Object },
    };
  }

  constructor() {
    super();
    this.password = "";
    this.confirmPassword = "";
    this.validationPattern = VALIDATION_PATTERN[0].pattern;
    this.patternMapper = VALIDATION_PATTERN.slice(1);
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  setPassword(password) {
    this.password = password;
  }

  setConfirmPassword(password) {
    this.confirmPassword = password;
  }

  updated(changedProperties) {
    if (changedProperties.has("confirmPassword")) {
      this.onChangePassword(this.hasValidPassword() ? this.password : null);
    }
  }

  hasValidPassword() {
    return (
      this.password !== "" &&
      this.confirmPassword !== "" &&
      this.password === this.confirmPassword
    );
  }

  handleKeyInput(event) {
    if (event.key === "Enter") this.dispatchEvent(new Event("keyupEnter"));
  }

  render() {
    return html`
      <div class="password-validation">
        <div class="display-flex flex-column">
          <custom-textfield
            type="password"
            label="New password"
            pattern=${this.validationPattern}
            ?validate=${true}
            .onChange=${password => {
              this.setPassword(password);
            }}
            .onInvalid=${() => {
              this.validPassword = false;
            }}
          ></custom-textfield>
          ${
            this.password.length
              ? html`
                  <validation-hints
                    currentInput=${this.password}
                    .patternMapper=${this.patternMapper}
                  >
                  </validation-hints>
                `
              : null
          }
          </validation-hints>
          <custom-textfield
          class="margin-top-1"
          type="password"
          label="Confirm password"
          pattern=${this.validationPattern}
          ?validate=${true}
          .onChange=${password => {
            this.setConfirmPassword(password);
          }}
            .onInvalid=${e => {
              this.validConfirmPassword = false;
            }}
            @keyup=${e => this.handleKeyInput(e)}
          ></custom-textfield>
          ${
            this.confirmPassword.length
              ? html`
                  <validation-hints
                    currentInput=${this.confirmPassword}
                    .patternMapper=${this.patternMapper}
                  >
                  </validation-hints>
                `
              : null
          }
        </div>
      </div>
    `;
  }
}

customElements.define("password-validation", PasswordValidation);
