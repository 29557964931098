import * as THREE from "three";
import { Reflector } from "three/examples/jsm/objects/Reflector.js";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";

let experience = null;
let sizes = null;
let scene = null;
let resources = null;
let boothTemplate = null;

const SVGREGEX = /(\.svg)$/i;
const BOOTHMIRRORCOLOR = 0x6d6d6d;
const MIRRORMAPSIZE = 1920;

export default class FloorMirror {
  constructor({ exhibition }) {
    ({ experience } = exhibition);
    ({ sizes } = experience);
    ({ scene } = experience);
    ({ resources } = experience);
    ({ boothTemplate } = experience.project);

    this.exhibition = exhibition;
    this.position = boothTemplate.floorMirror?.position;
    this.scale = boothTemplate.floorMirror?.scale;
    this.rotation = boothTemplate.floorMirror?.rotation;
    this.file = boothTemplate.floorMirror?.file;
    this.innerRadius = boothTemplate.floorMirror?.innerRadius;
    this.outerRadius = boothTemplate.floorMirror?.outerRadius;
    this.thetaSegments = boothTemplate.floorMirror?.thetaSegments;
    this.phiSegments = boothTemplate.floorMirror?.phiSegments;
    this.thetaStart = boothTemplate.floorMirror?.thetaStart;
    this.thetaLength = boothTemplate.floorMirror?.thetaLength;
    this.rotation = boothTemplate.floorMirror?.rotation;
    this.scale = boothTemplate.floorMirror?.scale;

    this.isReady = false;
    this.setFloorMirror();
  }

  async setFloorMirror() {
    if (!this.file) {
      this.createBoothMirror();
      return;
    }

    if (SVGREGEX.exec(this.file.filename_download)) {
      this.svgMirror = await resources.loadSVG(this.file.id);
      this.createSVGMirror();
    } else {
      this.textureAlpha = await resources.loadTextureAlpha(this.file.id);
      this.createCoverFlow();
    }

    this.isReady = true;
    this.exhibition.trigger("asset-loaded");
  }

  createSVGMirror() {
    this.paths = this.svgMirror.paths;
    this.mirrorGroup = new THREE.Group();
    for (let i = 0; i < this.paths.length; i++) {
      this.path = this.paths[i];
      this.shapes = SVGLoader.createShapes(this.path);
      for (let j = 0; j < this.shapes.length; j++) {
        this.shape = this.shapes[j];
        this.mirrorGeo = new THREE.ShapeGeometry(this.shape);
        this.floorReflector = new Reflector(this.mirrorGeo, {
          clipBias: 0.003,
          textureWidth: window.innerWidth * window.devicePixelRatio,
          textureHeight: window.innerHeight * window.devicePixelRatio,
          color: 0xaaaaaa,
        });
        this.mirrorGroup.add(this.floorReflector);
      }
    }

    this.mirrorGroup.rotation.x = this.rotation[0];
    this.mirrorGroup.rotation.z = this.rotation[2];
    this.mirrorGroup.scale.set(this.scale, this.scale, this.scale);
    this.mirrorGroup.position.set(...this.position);
    this.mirrorGroup.name = "Reflector";
    // experience.exhibition.floor.floorMirrorGroup.add(this.mirrorGroup);
    // scene.add(this.mirrorGroup);
  }

  createCoverFlow() {
    const circleFloorGeo = new THREE.CircleBufferGeometry(730, 100);
    const circleFloorMat = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.9,
    });
    const circleFloorMesh = new THREE.Mesh(circleFloorGeo, circleFloorMat);
    circleFloorMesh.rotation.x = -Math.PI / 2;
    circleFloorMesh.position.y = 0;
    circleFloorMesh.receiveShadow = true;
    circleFloorMesh.name = "mirrorFloor";
    scene.add(circleFloorMesh);

    const circleShapeGeo = new THREE.CircleBufferGeometry(730, 100);
    const circleShapeMat = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      alphaMap: this.textureAlpha,
    });
    const circleShapeMesh = new THREE.Mesh(circleShapeGeo, circleShapeMat);
    circleShapeMesh.rotation.x = -Math.PI / 2;
    circleShapeMesh.receiveShadow = true;
    circleShapeMesh.name = "alphaFloor";
    scene.add(circleShapeMesh);

    const mirrorGeo = new THREE.CircleBufferGeometry(
      this.outerRadius,
      this.thetaSegments
    );
    const floorMirror = new Reflector(mirrorGeo, {
      clipBias: 0.003,
      textureWidth: MIRRORMAPSIZE * sizes.devicePixelRatio,
      textureHeight: MIRRORMAPSIZE * sizes.devicePixelRatio,
      color: 0xffffff,
    });
    floorMirror.name = "reflector";
    floorMirror.rotation.x = -Math.PI / 2;
    floorMirror.position.y = -0.01;
    scene.add(floorMirror);
  }

  createBoothMirror() {
    const ringGeo = new THREE.RingGeometry(
      this.innerRadius,
      this.outerRadius,
      this.thetaSegments,
      this.phiSegments,
      this.thetaStart,
      this.thetaLength
    ); // 2er
    this.mirrorRing = new Reflector(ringGeo, {
      textureWidth: MIRRORMAPSIZE * sizes.devicePixelRatio,
      textureHeight: MIRRORMAPSIZE * sizes.devicePixelRatio,
      color: BOOTHMIRRORCOLOR,
    });
    this.mirrorRing.rotation.x = -Math.PI / 2;
    this.mirrorRing.position.y = -0.1;
    scene.add(this.mirrorRing);

    this.isReady = true;
    this.exhibition.trigger("asset-loaded");
  }
}
