import { html, unsafeCSS } from "lit";
import { classMap } from "lit/directives/class-map";

import ModalDialog from "@shs/ui-marcom/src/js/modal-dialog";
import custom from "./custom-modal-dialog.scss";

class CustomModalDialog extends ModalDialog {
  static get properties() {
    return {
      ...super.properties,
      size: {
        type: String,
        attribute: true,
      },
    };
  }

  static get styles() {
    return [super.styles, unsafeCSS(custom)];
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener("keydown", this._handleEsc.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener("keydown", this._handleEsc.bind(this));
  }

  _handleEsc(event) {
    if (event.code === "Escape") {
      this._onCancel();
    }
  }

  render() {
    return html`
      <div
        class=${classMap({
          "modal-dialog": true,
          "is-open": this.isOpen,
          [`modal-dialog--${this.indicator}`]: this.indicator,
        })}
      >
        <div
          class="modal-dialog__container ${classMap({
            "modal-dialog__container--large": this.size === "large",
          })}"
        >
          ${this.headerTemplate} ${this.bodyTemplate} ${this.footerTemplate}
        </div>
      </div>
    `;
  }
}

customElements.define("custom-modal-dialog", CustomModalDialog);
