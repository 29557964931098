import { LitElement, html, css } from "lit";

import shared from "../../shared/styles.scss";
import custom from "./filter-area.scss";

import Api from "../../api/api-helper";
import "../../custom-uimc/custom-radio-group/custom-radio-group";
import "../../custom-uimc/custom-radio-button/custom-radio-button";
import "../../custom-uimc/custom-searchfield/custom-searchfield";
import "../../custom-uimc/custom-select/custom-select";

import { handleInvalidRequest } from "../../api/helper";

class FilterArea extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      businessLines: { type: Array, attribute: false },
      boothTemplates: { type: Array, attribute: false },
    };
  }

  constructor() {
    super();
    this.businessLines = [];
    this.boothTemplates = [];
    this.filterOptions = {
      basic: "all",
      search: null,
      businessLine: null,
      boothTemplate: null,
      currentUserId: null,
    };
    this.enrichedBusinessLineItems = null;
    this.api = Api.getInstance();
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  async firstUpdated() {
    try {
      this.businessLines = await this.api.businessLines();
      this.boothTemplates = await this.api.boothTemplates();
    } catch (error) {
      handleInvalidRequest(this.router);
    }
  }

  get businessLineItems() {
    return this.businessLines?.map(b => {
      const businessLine = {
        name: b.name,
        value: b.id,
      };

      if (b?.id === this?.businessLine?.id) {
        businessLine.isSelected = true;
      }
      return businessLine;
    });
  }

  get boothTemplateItems() {
    return this.boothTemplates?.map(b => {
      const boothTemplate = {
        name: b.name,
        value: b.id,
      };

      if (b?.id === this?.boothTemplate?.id) {
        boothTemplate.isSelected = true;
      }
      return boothTemplate;
    });
  }

  setFilterOptions(filterOption, newValue) {
    if (filterOption) {
      // eslint-disable-next-line default-case
      switch (filterOption) {
        case "basic":
          this.filterOptions = { ...this.filterOptions, basic: newValue };
          break;
        case "search":
          this.filterOptions = {
            ...this.filterOptions,
            search: newValue,
          };
          break;
        case "businessLine":
          this.filterOptions = {
            ...this.filterOptions,
            businessLine: newValue,
          };
          break;
        case "boothTemplate":
          this.filterOptions = {
            ...this.filterOptions,
            boothTemplate: newValue,
          };
          break;
      }
    }
  }

  bubbleFilterOptionsEvent() {
    const filterOptionsEvent = new CustomEvent("filter-options-event", {
      detail: {
        filterOptions: this.filterOptions,
      },
    });

    this.dispatchEvent(filterOptionsEvent);
  }

  handleFilterChange(filterOption, filterOptionValue) {
    this.setFilterOptions(filterOption, filterOptionValue);
    this.bubbleFilterOptionsEvent(this.filterOptions);
  }

  render() {
    return html`
      <div>
        <custom-radio-group
          class="margin-bottom-1 display-block bg-color-black-7"
          .onChange=${basicValue => {
            this.handleFilterChange("basic", basicValue);
          }}
        >
          <div>
            <custom-radio-button
              label="Show all projects"
              value="all-projects"
              checked
            ></custom-radio-button>
          </div>

          <div>
            <custom-radio-button
              label="Show my projects"
              value="my-projects"
            ></custom-radio-button>
          </div>

          <div>
            <custom-radio-button
              label="Show my private projects"
              value="private-projects"
            ></custom-radio-button>
          </div>

          <div>
            <custom-radio-button
              label="Show projects shared with me"
              value="projects-shared-with-me"
            ></custom-radio-button>
          </div>

          <div>
            <custom-radio-button
              label="Show archived projects"
              value="archived-projects"
            ></custom-radio-button>
          </div>
        </custom-radio-group>

        <p>
          <custom-searchfield
            .onInput=${searchFieldValue => {
              this.handleFilterChange("search", searchFieldValue);
            }}
            placeholder="Search for a project"
            theme="gray"
          ></custom-searchfield>
        </p>

        ${this.businessLineItems.length
          ? html`
              <p>
                <custom-select
                  theme="gray"
                  .onChange=${businessLineValue => {
                    this.handleFilterChange("businessLine", businessLineValue);
                  }}
                  .isChosen=${true}
                  label="Business area/line"
                  .items="${[
                    {
                      name: "Select business area/line:",
                      isSelected: true,
                      value: 0,
                    },
                    ...this.businessLineItems,
                  ]}"
                >
                </custom-select>
              </p>
            `
          : html`
              <p>No business lines/areas could be found!</p>
            `}
        ${this.boothTemplateItems.length
          ? html`
              <p class="margin-bottom-0">
                <custom-select
                  theme="gray"
                  .onChange=${boothTemplateValue => {
                    this.handleFilterChange(
                      "boothTemplate",
                      boothTemplateValue
                    );
                  }}
                  label="Exhibition templates"
                  .isChosen=${true}
                  .items="${[
                    {
                      name: "Select exhibition template:",
                      isSelected: true,
                      value: 0,
                    },
                    ...this.boothTemplateItems,
                  ]}"
                >
                </custom-select>
              </p>
            `
          : html`
              <p>No booth templates could be found!</p>
            `}
      </div>
    `;
  }
}

customElements.define("filter-area", FilterArea);
