import { LitElement, html, css } from "lit";

import shared from "../../shared/styles.scss";
import custom from "./project-list-sort-order.scss";

class ProjectListSortOrder extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      sortOrder: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.sortOrder = "desc";
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  bubbleSortOrderEvent(sortOrder) {
    const sortOrderEvent = new CustomEvent("sort-order-event", {
      detail: sortOrder,
    });
    this.dispatchEvent(sortOrderEvent);
  }

  toggleSortOrder() {
    if (this.sortOrder === "asc") {
      this.sortOrder = "desc";
    } else if (this.sortOrder === "desc") {
      this.sortOrder = "asc";
    }
    this.bubbleSortOrderEvent(this.sortOrder);
    this.requestUpdate();
  }

  render() {
    return html`
      <div class="project-list-sort-order">
        <img
          src="${this.basePath}${
      this.sortOrder === "asc"
        ? "/assets/icons/sort-ascending.svg"
        : "/assets/icons/sort-descending.svg"
    }"
          @click=${() => {
            this.toggleSortOrder();
          }}
        ></i>
      </div>
    `;
  }
}

customElements.define("project-list-sort-order", ProjectListSortOrder);
