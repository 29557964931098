import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../../../shared/styles.scss";
import custom from "./contact-options-list.scss";
import "../contact-list-item/contact-list-item";
import { handleInvalidRequest, hasAuthToken } from "../../../../api/helper";

class ContactOptionsList extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      activeEditBox: { type: String },
    };
  }

  constructor() {
    super();
    this.activeEditBox = "";
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showModal", "showAlert", "setLoading"];
  }

  firstUpdated() {
    this.project.on("updated", () => {
      this.requestUpdate();
    });
  }

  async deleteOption(optionToDelete) {
    this.project.contact.dynamicContent.options = this.project.contact?.dynamicContent.options.filter(
      option => option.title !== optionToDelete.title
    );

    this.setLoading(true);
    try {
      const status = await this.project.persistContact();

      if (status === 200) {
        this.showAlert(
          "Saved",
          `Project contact widget has been saved.`,
          "success"
        );
      }
    } catch (error) {
      if (!hasAuthToken()) {
        handleInvalidRequest(this.router, this.showAlert);
      } else {
        this.showAlert(
          "Saving failed",
          `Saving project contact widget has failed.`,
          "error"
        );
      }
    } finally {
      this.setLoading(false);
      this.project.trigger("updated");
      this.requestUpdate();
    }
  }

  setActiveEditBox(activeEditBox, index) {
    if (index >= 0) {
      this.activeEditBox = `${activeEditBox}_${index}`;
      return;
    }

    this.activeEditBox = activeEditBox;
  }

  render() {
    return html`
      <div class="contact-options-list">
        ${this.project.contact?.dynamicContent.options.length > 0
          ? html`
              <uimc-collection>
                ${this.project.contact?.dynamicContent.options.map(
                  (option, index) => {
                    return html`
                      <uimc-collection-item>
                        <contact-list-item
                          base-path=${this.basePath}
                          .project=${this.project}
                          .option=${option}
                          index=${index}
                          .onDeleteOption=${optionToDelete =>
                            this.deleteOption(optionToDelete)}
                          activeEditBox=${this.activeEditBox}
                          .setActiveEditBox=${this.setActiveEditBox.bind(this)}
                        ></contact-list-item>
                      </uimc-collection-item>
                    `;
                  }
                )}
              </uimc-collection>
            `
          : null}
      </div>
    `;
  }
}

customElements.define("contact-options-list", ContactOptionsList);
