import { LitElement, html, css } from "lit";
import { ifDefined } from "lit/directives/if-defined";
import { classMap } from "lit/directives/class-map";

import Checkbox from "@shs/ui-marcom/src/js/checkbox";

import custom from "./custom-checkbox.scss";

class CustomCheckbox extends Checkbox {
  static get properties() {
    return {
      ...super.properties,
      isChecked: {
        type: Boolean,
        attribute: "checked",
        reflect: true,
      },
      onChange: {
        type: Object,
        attribute: false,
      },
    };
  }

  constructor() {
    super();
    this.onChange = () => {};
  }

  render() {
    return html`
      <input
        id=${this.id}
        type="checkbox"
        value=${ifDefined(this.value)}
        class=${classMap({
          checkbox: true,
          "is-invalid": this.isInvalid,
        })}
        .checked=${this.isChecked}
        .disabled=${this.isDisabled}
        @change=${event => {
          this.isChecked = event.target.checked;
          this.onChange(this.isChecked);
        }}
      />
      <label for=${this.id}>
        <slot></slot>
      </label>
      ${this.hintText
        ? html`
            <small class="checkbox__hint">${this.hintText}</small>
          `
        : null}
    `;
  }
}

customElements.define("custom-checkbox", CustomCheckbox);
