import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js/dist/tween.umd";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import ApiModel from "./Models/ApiModel";

let sizes = null;
let scene = null;

export default class Camera extends ApiModel {
  constructor({ experience, editMode }) {
    super();

    this.experience = experience;
    ({ sizes } = experience);
    ({ scene } = experience);
    this.editMode = editMode;
    this.introAniEditMode = false;

    this.projectCamera = experience.project.camera;
    this.projectControls = experience.project.controls;
    this.setInstance();
    this.introAnimationTarget = {};

 

    this.on("positionChanged", exhibitControls => {
      this.animateCameraMove(exhibitControls);
    });
  }

  setIntroAniEditMode(mode) {
    this.introAniEditMode = mode;
    if (mode) {
      this.moveCameraTarget(this.introAnimationTarget.position);
      this.controls.enablePan = false;
    } else {
      this.controls.enablePan = true;
    }
  }

  setActiveExhibitCameraTarget(exhibit) {
    this.introAnimationTarget = exhibit;
  }

  setInstance() {
    const { fov, near, far, position, lookAt, introPosition, introTarget } = this.projectCamera;
    this.instance = new THREE.PerspectiveCamera(
      fov,
      sizes.width / sizes.height,
      near,
      far
    );

    if ((!introPosition.includes(null) || !introTarget.includes(null))) {
      this.instance.position.set(...introPosition);
      this.instance.lookAt(...introTarget);
    } else {
      this.instance.position.set(...position);
      this.instance.lookAt(...lookAt);
    }

    scene.add(this.instance);
  }

  setControls() {
    const { rotationSpeed } = this.projectControls;
    const { introTarget } = this.projectCamera;

    // set controls to CSS3DRenderer instance (why)
    this.controls = new OrbitControls(
      this.instance,
      this.experience.renderer.cssInstance.domElement
    );

    this.controls.autoRotate = rotationSpeed !== 0;
    this.controls.autoRotateSpeed = rotationSpeed;
    this.controls.target.set(...introTarget);

    this.controls.addEventListener("change", () => {
      this.trigger("controlsChanged");
    });
  }

  setAutorotation(isRotated, speed) {
    this.controls.autoRotate = isRotated;
    this.controls.autoRotateSpeed = speed;
  }

  setInit() {
    const {
      maxPolarAngle,
      minPolarAngle,
      maxAzimuthAngle,
      minAzimuthAngle,
      minDistance,
      maxDistance,
      enablePan,
    } = this.projectControls;

    this.controls.maxPolarAngle = maxPolarAngle; // Math.PI / 2.25; // Don't let to go below the ground
    this.controls.minPolarAngle = minPolarAngle; // Math.PI / 5;

    const isPI = maxAzimuthAngle === 3.14 && minAzimuthAngle === -3.14;

    this.controls.maxAzimuthAngle = isPI ? Infinity : maxAzimuthAngle;
    this.controls.minAzimuthAngle = isPI ? Infinity : minAzimuthAngle;

    this.controls.minDistance = minDistance; // 50;
    this.controls.maxDistance = maxDistance; // 650;
    this.controls.enablePan = enablePan; // false;

    if (
      !this.editMode &&
      this.experience.project.boothTemplate.type === "platform"
    ) {
      this.controls.enabled = false;
    }
  }

  setControlsConfig(secondLevelControl) {
    const { maxAzimuthAngle, minAzimuthAngle } = secondLevelControl;

    const {
      maxPolarAngle,
      minPolarAngle,
      minDistance,
      maxDistance,
      enablePan,
    } = this.projectControls.secondLevel;

    this.controls.enabled = true;

    this.controls.maxPolarAngle = maxPolarAngle; // Math.PI / 2.25; // Don't let to go below the ground
    this.controls.minPolarAngle = minPolarAngle; // Math.PI / 5;

    const isPI = maxAzimuthAngle === 3.14 && minAzimuthAngle === -3.14;

    this.controls.maxAzimuthAngle = isPI ? Infinity : maxAzimuthAngle;
    this.controls.minAzimuthAngle = isPI ? Infinity : minAzimuthAngle;

    this.controls.minDistance = minDistance; // 50;
    this.controls.maxDistance = maxDistance; // 650;
    this.controls.enablePan = enablePan; // false;
  }

  setControlsInfinite() {
    this.controls.minDistance = 0;
    this.controls.maxDistance = Infinity;
    this.controls.maxPolarAngle = Math.PI;
    this.controls.minPolarAngle = 0;
    this.controls.maxAzimuthAngle = Infinity;
    this.controls.minAzimuthAngle = Infinity;
  }

  startIntroAnimation() {
    if((this.projectCamera.position && this.projectCamera.lookAt)) {
      let introControls = {};
      introControls.camPosition = this.projectCamera.position
      introControls.controlTarget = this.projectCamera.lookAt

      this.animateCameraMove(introControls, true)
      this.setInit();
    }
  }

  animateCameraMove(exhibitControls, isIntroAnimation) {
    this.setControlsInfinite();

    let introDelay = 0;

    if(isIntroAnimation) {
      introDelay = 500;
    }

    const { camPosition, controlTarget } = exhibitControls;

    const cameraTween = new TWEEN.Tween(this.instance.position)
      .to({ x: camPosition[0], y: camPosition[1], z: camPosition[2] }, 1000)
      .delay(400)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onComplete(() => {
        if(isIntroAnimation) {
          this.setInit();
        } else {
          this.setControlsConfig(exhibitControls);
        }
      });

    new TWEEN.Tween(this.controls.target)
      .to(
        { x: controlTarget[0], y: controlTarget[1], z: controlTarget[2] },
        1000
      )
      .delay(introDelay)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onStart(() => {
        cameraTween.start();
      })
      .start();
  }

  resetCameraPosition() {
    this.setControlsInfinite();

    const { position, lookAt } = this.projectCamera;

    const cameraTween = new TWEEN.Tween(this.instance.position)
      .to({ x: position[0], y: position[1], z: position[2] }, 1000)
      .delay(400)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => this.setInit());

    new TWEEN.Tween(this.controls.target)
      .to({ x: lookAt[0], y: lookAt[1], z: lookAt[2] }, 1000)
      .onStart(() => {
        cameraTween.start();
      })
      .start()
      .easing(TWEEN.Easing.Quadratic.InOut);
  }

  saveCurrentCameraPosition() {
    this.setIntroAniEditMode(false);
    this.projectCamera.position = this.instance.position;
    this.projectCamera.lookAt = this.controls.target;
    this.persist();
  }

  setOribitRotation(isRotated, speed) {
    this.isAnimated = isRotated;
    this.controls.autoRotate = isRotated;
    if (this.controls.autoRotate) {
      this.controls.autoRotateSpeed = speed;
      return this.controls.autoRotateSpeed;
    }
    this.controls.autoRotateSpeed = 0;
    return this.controls.autoRotateSpeed;
  }

  moveCameraTarget(target) {
    new TWEEN.Tween(this.controls.target)
      .to({ x: target[0], y: target[1], z: target[2] }, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  }

  async persist() {
    const [
      positionx,
      positiony,
      positionz,
    ] = this.projectCamera.position;

    const [
      lookatx,
      lookaty,
      lookatz,
    ] = this.projectCamera.lookAt;

    const res = await this.api.updateCameraById(this.projectCamera.id, {
      positionx,
      positiony,
      positionz,
      lookatx,
      lookaty,
      lookatz,
    });

    return res.status;
  }

  resize() {
    this.instance.aspect = sizes.width / sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {
    TWEEN.update();
    this.controls.update();
  }

  dispose() {
    this.off("positionChanged");
    this.controls.dispose();
  }
}
