import Api from "../../api/api-helper";
import ApiModel from "./ApiModel";
import Build from "./Build";

export default class Project extends ApiModel {
  constructor(payload) {
    super(payload);

    this.experience = null;
  }

  get buildList() {
    return this.get("builds").sort(
      (a, b) => new Date(b.uploaded_on) - new Date(a.uploaded_on)
    );
  }

  async createBuild() {
    const { data, status } = await this.api.createProjectBuild(this.get("id"));
    const apiBuild = {
      id: data.downloadFileHash,
      filename: data.filename,
      title: data.title,
      uploaded_on: Date.now(),
    };
    const newBuild = new Build(apiBuild, this);

    this.get("builds").unshift(newBuild);
    this.trigger("updated");

    return status;
  }

  async setBackwallVisual(keyVisualData) {
    const keyVisual = await this.api.addBackwallVisualByProjectId(
      this.id,
      keyVisualData
    );
    this.set("backwallVisual", keyVisual);
    this.get("experience")
      .get("exhibition")
      .get("floor")
      .setBackwallVisual();
  }

  async removeBackwallVisual() {
    const res = await this.api.updateProjectById(this.id, {
      backwallvisual: null,
      date_updated: new Date(),
    });

    this.set("backwallVisual", null);
    this.get("experience")
      .get("exhibition")
      .get("floor")
      .removeBackwallVisual();

    return res.status;
  }

  normalize(payload) {
    payload.config = payload.config || {};

    super.normalize(payload);
  }

  static async fetch(id) {
    return await Api.getInstance().getProjectById(id);
  }

  async persist() {
    const res = await this.api.updateProjectById(this.get("id"), {
      name: this.get("name"),
      description: this.get("description"),
      businessline: this.get("businessLine")?.id,
      config: this.get("config"),
      contact: this.get("contact")?.id,
      date_updated: new Date(),
    });

    if (res.status >= 400) {
      return res.status;
    }

    const controlsRes = await this.api.updateControlById(
      this.get("controls").id,
      { rotationspeed: this.get("controls").rotationSpeed }
    );

    return controlsRes.status;
  }

  async flush() {
    const res = await this.api.archiveProjectById(this.id);
    delete this;
    return res.status;
  }

  async copy() {
    return this.api.copyProjectFromId(this.get("id"));
  }

  async dearchive() {
    const res = await this.api.dearchiveProjectById(this.id);
    delete this;
    return res.status;
  }

  async setProjectToPrivate() {
    const res = await this.api.setProjectToPrivate(this.id);
    delete this;
    return res.status;
  }

  async setProjectToPublic() {
    const res = await this.api.setProjectToPublic(this.id);
    delete this;
    return res.status;
  }

  async toggleProjectContact() {
    this.get("config").contactEnabled = !this.get("config").contactEnabled;

    if (this.get("config").contactEnabled && !this.get("contact")) {
      const newContact = await this.api.getContact();
      this.set("contact", { ...newContact, status: "published" });
    }

    await this.persist();
  }

  async persistContact() {
    const { id, ...data } = this.get("contact");
    if (!id) {
      return 404;
    }

    const res = this.api.updateContact(id, data);
    return res.status;
  }
}
