import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";
import { handleInvalidRequest, hasAuthToken } from "../../../../api/helper";

import shared from "../../../../shared/styles.scss";
import custom from "./contact-text-select.scss";

class ContactTextSelect extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      content: { type: Object },
    };
  }

  constructor() {
    super();
    this.content = "";
  }

  firstUpdated() {
    this.content = this.project.contact.staticContent;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showModal", "showAlert", "setLoading"];
  }

  setCommonLabel(label, field) {
    this.project.contact.staticContent.common[field] = label;
    this.saveContact();
  }

  setAppointmentLabel(label, field) {
    this.project.contact.staticContent.appointment[field] = label;
    this.saveContact();
  }

  setContactFormLabel(label, field) {
    this.project.contact.staticContent.contactForm[field] = label;
    this.saveContact();
  }

  setFinalPageLabel(label, field) {
    this.project.contact.staticContent.finalPage[field] = label;
    this.saveContact();
  }

  setMailContactLabel(label, field) {
    this.project.contact.staticContent.mailContact[field] = label;
    this.saveContact();
  }

  async saveContact() {
    this.setLoading(true);
    try {
      const status = await this.project.persistContact();

      if (status === 200) {
        this.showAlert(
          "Saved",
          `Project contact widget has been saved.`,
          "success"
        );
      }
    } catch (error) {
      if (!hasAuthToken()) {
        handleInvalidRequest(this.router, this.showAlert);
      } else {
        this.showAlert(
          "Saving failed",
          `Saving project contact widget has failed.`,
          "error"
        );
      }
    } finally {
      this.setLoading(false);
    }
  }

  // TODO: p-Tag and h5 Namings correct? Prüft Holger sobald auf Test verfügbar - vielleicht nochmal erinnern
  render() {
    return html`
      ${this.content !== ""
        ? html`
            <div class="contact-text-select display-flex flex-column">
              <h5>General</h5>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Contact label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.stickyTitle}"
                  .onChange=${(label) => {
                    this.setCommonLabel(label, "stickyTitle");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Yes please label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.yesButton}"
                  .onChange=${(label) => {
                    this.setCommonLabel(label, "yesButton");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">No thanks label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.noButton}"
                  .onChange=${(label) => {
                    this.setCommonLabel(label, "noButton");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Welcome text label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.welcomeText}"
                  .onChange=${(text) => {
                    this.setCommonLabel(text, "welcomeText");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Welcome title label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.welcomeTitle}"
                  .onChange=${(title) => {
                    this.setCommonLabel(title, "welcomeTitle");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Close label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.closeButton}"
                  .onChange=${(label) => {
                    this.setCommonLabel(label, "closeButton");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Back label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.backButton}"
                  .onChange=${(label) => {
                    this.setCommonLabel(label, "backButton");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Help label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.selectionTitle}"
                  .onChange=${(label) => {
                    this.setCommonLabel(label, "selectionTitle");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Choose label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.selectionText}"
                  .onChange=${(label) => {
                    this.setCommonLabel(label, "selectionText");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Get in touch label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.common.confirmButton}"
                  .onChange=${(label) => {
                    this.setCommonLabel(label, "confirmButton");
                  }}
                ></custom-textfield>
              </div>

              <!-- -->

              <h5>Appointment Page</h5>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Title label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.appointment.title}"
                  .onChange=${(label) => {
                    this.setAppointmentLabel(label, "title");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Choose date and time label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.appointment.scheduleText}"
                  .onChange=${(label) => {
                    this.setAppointmentLabel(label, "scheduleText");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Date label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.appointment.dateLabel}"
                  .onChange=${(label) => {
                    this.setAppointmentLabel(label, "dateLabel");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Time label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.appointment.timeLabel}"
                  .onChange=${(label) => {
                    this.setAppointmentLabel(label, "timeLabel");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Choose type of meet label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.appointment.meetLabel}"
                  .onChange=${(label) => {
                    this.setAppointmentLabel(label, "meetLabel");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Meeting location label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.appointment.locationInfo}"
                  .onChange=${(label) => {
                    this.setAppointmentLabel(label, "locationInfo");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Date and time label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.appointment.dateInfo}"
                  .onChange=${(label) => {
                    this.setAppointmentLabel(label, "dateInfo");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Confirm label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.appointment.scheduleConfirmButton}"
                  .onChange=${(label) => {
                    this.setAppointmentLabel(label, "scheduleConfirmButton");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">
                  Please leave your contact informations label
                </p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.appointment.formText}"
                  .onChange=${(label) => {
                    this.setAppointmentLabel(label, "formText");
                  }}
                ></custom-textfield>
              </div>

              <!-- -->

              <h5>Contact Form</h5>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">First name label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.firstname}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "firstname");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Last name label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.lastname}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "lastname");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Organisation label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.organisation}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "organisation");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">E-mail label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.mail}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "mail");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Phone number label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.phone}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "phone");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Job title label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.jobtitle}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "jobtitle");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Select country label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.country}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "country");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">First name invalid</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.firstnameInvalid}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "firstnameInvalid");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Last name invalid</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.lastnameInvalid}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "lastnameInvalid");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Mail invalid</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.mailInvalid}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "mailInvalid");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Phone invalid</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.phoneInvalid}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "phoneInvalid");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Organisation invalid</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.organisationInvalid}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "organisationInvalid");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Job title invalid</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.jobtitleInvalid}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "jobtitleInvalid");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Country invalid</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.countryInvalid}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "countryInvalid");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Consent invalid</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.consentInvalid}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "consentInvalid");
                  }}
                ></custom-textfield>
              </div>

              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Comment label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.comment}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "comment");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">* Mandatory field label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.footnote}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "footnote");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Disclaimer label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.privacyConsent}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "privacyConsent");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Send request label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.contactForm.confirmButton}"
                  .onChange=${(label) => {
                    this.setContactFormLabel(label, "confirmButton");
                  }}
                ></custom-textfield>
              </div>

              <!-- -->

              <h5>Thank you Page</h5>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">
                  Thank you for your request label
                </p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.finalPage.heading}"
                  .onChange=${(label) => {
                    this.setFinalPageLabel(label, "heading");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Next steps label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.finalPage.stepsHeading}"
                  .onChange=${(label) => {
                    this.setFinalPageLabel(label, "stepsHeading");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">First instruction label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.finalPage.stepOne}"
                  .onChange=${(label) => {
                    this.setFinalPageLabel(label, "stepOne");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Second instruction label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.finalPage.stepTwo}"
                  .onChange=${(label) => {
                    this.setFinalPageLabel(label, "stepTwo");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Stay safe and healthy label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.finalPage.farewell}"
                  .onChange=${(label) => {
                    this.setFinalPageLabel(label, "farewell");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Received your request label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.finalPage.receiptText}"
                  .onChange=${(label) => {
                    this.setFinalPageLabel(label, "receiptText");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Thank you label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.finalPage.confirmButton}"
                  .onChange=${(label) => {
                    this.setFinalPageLabel(label, "confirmButton");
                  }}
                ></custom-textfield>
              </div>

              <!-- -->

              <h5>Mail contact</h5>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Mail text label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.mailContact.text}"
                  .onChange=${(label) => {
                    this.setMailContactLabel(label, "text");
                  }}
                ></custom-textfield>
              </div>
              <div class="display-flex display-row align-items-baseline">
                <p class="col-3 padding-left-0">Mail title label</p>
                <custom-textfield
                  class="col-6"
                  value="${this.content.mailContact.title}"
                  .onChange=${(label) => {
                    this.setMailContactLabel(label, "title");
                  }}
                ></custom-textfield>
              </div>
            </div>
          `
        : null}
    `;
  }
}

customElements.define("contact-text-select", ContactTextSelect);
