import Stats from "three/examples/jsm/libs/stats.module";

export default class Stat {
  constructor() {
    this.instance = new Stats();
    this.isActive = false;

    this.handle = (event) => {
      if (event.ctrlKey && event.altKey && event.key === "d") {
        this.isActive ? this.deactivate() : this.activate();
      }
    };

    window.addEventListener("keydown", this.handle);
  }

  activate() {
    document.body.appendChild(this.instance.dom);
    this.isActive = true;
  }

  deactivate() {
    document.body.removeChild(this.instance.dom);
    this.isActive = false;
  }

  update() {
    if (this.isActive) {
      this.instance.update();
    }
  }

  dispose() {
    if (this.isActive) this.deactivate();
    window.removeEventListener("keydown", this.handle);
  }
}
