import { LitElement, html, css } from "lit";

import shared from "../../shared/styles.scss";
import custom from "./custom-file-picker.scss";

import "@shs/ui-marcom/src/js/button";

class CustomFilePicker extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      onSelect: { type: Object, attribute: false },
      isPrimary: { type: Boolean, attribute: "primary" },
    };
  }

  constructor() {
    super();
    this.onSelect = () => {};
    this.isPrimary = false;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  render() {
    return html`
      <div class="file-picker">
        <label
          class="margin-bottom-0"
          @click=${event => {
            event.preventDefault();
            event.stopPropagation();
            this.shadowRoot.querySelector(".file-picker__input").click();
          }}
        >
          <uimc-button ?primary=${this.isPrimary}>
            <slot></slot>
          </uimc-button>
        </label>
        <input
          class="file-picker__input hidden"
          type="file"
          accept="image/*,video/*"
          @change=${e => this.onSelect(e)}
        />
      </div>
    `;
  }
}

customElements.define("custom-file-picker", CustomFilePicker);
