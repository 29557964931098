import axios from "axios";
import BoothTemplate from "../experience/Models/BoothTemplate";
import { getXYZArray } from "./helper";
import { getPlatformArray } from "./platforms";

const selectedFields = [
  "id",
  "name",
  "description",
  "previewimage",
  "camera.*",
  "lights.lights_id.*",
  "controls.controls_id.*",
  "floor.*",
  "floormirror.*",
  "floormirror.file.id",
  "floormirror.file.filename_download",
  "exhibitplaces.exhibitplaces_id.*",
  "platforms.platforms_id.*",
  "platforms.platforms_id.hotspot.*",
  "platforms.platforms_id.spotlight.*",
  "backwalltexture",
  "config",
];

async function getBoothTemplates(url, options) {
  const requestUrl = new URL("/items/boothtemplates", url);
  requestUrl.searchParams.append("fields", selectedFields.join(","));
  requestUrl.searchParams.append("sort", "sort");

  const res = await axios.get(requestUrl, options);
  const apiBoothTemplateArray = res.data.data;

  return apiBoothTemplateArray.map(boothTemplate => {
    return new BoothTemplate({
      id: boothTemplate.id,
      name: boothTemplate.name,
      type: boothTemplate.config.boothType,
      floorRadius: boothTemplate.config.floorRadius,
      background: boothTemplate.config.background,
      description: boothTemplate.description,
      previewImage: boothTemplate.previewimage,
      backwallTexture: boothTemplate.backwalltexture,
      floor: getFloorObject(boothTemplate.floor),
      floorMirror: getFloorMirrorObject(boothTemplate.floormirror),
      camera: getCameraObject(boothTemplate.camera),
      lights: getLightsArray(boothTemplate.lights.map(l => l.lights_id)),
      control: getControlsObject(
        boothTemplate.controls.map(c => c.controls_id)
      ),
      sliderValues: boothTemplate.config.sliderValues,
      staticAsset: boothTemplate.config.staticAsset,
      exhibits: getExhibitArray(boothTemplate.exhibitplaces),
      platforms: getPlatformArray(
        boothTemplate.platforms.map(p => p.platforms_id)
      ),
    });
  });
}

async function getBoothtemplateById(id, url, options) {
  const requestUrl = new URL(`/items/boothtemplates/${id}`, url);
  requestUrl.searchParams.append("fields", selectedFields.join(","));

  const res = await axios.get(requestUrl, options);
  const apiBoothTemplate = res.data.data;

  return new BoothTemplate({
    id: apiBoothTemplate.id,
    name: apiBoothTemplate.name,
    type: apiBoothTemplate.config.boothType,
    floorRadius: apiBoothTemplate.config.floorRadius,
    background: apiBoothTemplate.config.background,
    description: apiBoothTemplate.description,
    previewImage: apiBoothTemplate.previewimage,
    backwallTexture: apiBoothTemplate.backwalltexture,
    floor: getFloorObject(apiBoothTemplate.floor),
    floorMirror: getFloorMirrorObject(apiBoothTemplate.floormirror),
    camera: getCameraObject(apiBoothTemplate.camera),
    lights: getLightsArray(apiBoothTemplate.lights.map(l => l.lights_id)),
    control: getControlsObject(
      apiBoothTemplate.controls.map(c => c.controls_id)
    ),
    sliderValues: apiBoothTemplate.config.sliderValues,
    signs: apiBoothTemplate.config.signs,
    staticAsset: apiBoothTemplate.config.staticAsset,
    exhibits: getExhibitArray(apiBoothTemplate.exhibitplaces),
    platforms: getPlatformArray(
      apiBoothTemplate.platforms.map(p => p.platforms_id)
    ),
  });
}

export {
  getBoothTemplates,
  getBoothtemplateById,
  getLightsArray,
  getLight,
  getControlsObject,
  getCameraObject,
};

// helper method section
function getFloorObject(apiFloorObject) {
  if (!apiFloorObject) {
    return {};
  }

  return {
    floorModel: apiFloorObject.model,
    floorTexture: apiFloorObject.texture,
    position: getXYZArray(apiFloorObject, "position"),
    rotationY: apiFloorObject.rotationy,
    scaling: apiFloorObject.scale,
  };
}

function getFloorMirrorObject(apiFloorMirrorObject) {
  if (!apiFloorMirrorObject) {
    return null;
  }

  return {
    position: getXYZArray(apiFloorMirrorObject, "position"),
    file: apiFloorMirrorObject.file,
    innerRadius: apiFloorMirrorObject.innerradius,
    outerRadius: apiFloorMirrorObject.outerradius,
    thetaSegments: apiFloorMirrorObject.thetasegments,
    phiSegments: apiFloorMirrorObject.phisegments,
    thetaStart: apiFloorMirrorObject.thetastart,
    thetaLength: apiFloorMirrorObject.thetalength,
    rotation: getXYZArray(apiFloorMirrorObject, "rotation"),
    scale: apiFloorMirrorObject.scale,
  };
}

// TODO: Intro position and Intro Target
function getCameraObject(apiCameraObject) {
  if (!apiCameraObject) {
    return {};
  }

  return {
    id: apiCameraObject.id,
    name: apiCameraObject.name,
    position: getXYZArray(apiCameraObject, "position"),
    lookAt: getXYZArray(apiCameraObject, "lookat"),
    introPosition: getXYZArray(apiCameraObject, "introposition"),
    introTarget: getXYZArray(apiCameraObject, "introtarget"),
    fov: apiCameraObject.fov,
    near: apiCameraObject.near,
    far: apiCameraObject.far,
  };
}

function getLightsArray(apiLights) {
  return apiLights.map(light => {
    return getLight(light);
  });
}

function getLight(apiLight) {
  if (!apiLight) {
    return null;
  }

  return {
    id: apiLight.id,
    name: apiLight.name,
    type: apiLight.type,
    position: getXYZArray(apiLight, "position"),
    target: getXYZArray(apiLight, "target"),
    intensity: apiLight.intensity,
    castShadow: apiLight.castshadow,
    shadowCamFar: apiLight.shadowcamfar,
    shadowCamTop: apiLight.shadowcamtop,
    shadowCamRight: apiLight.shadowcamright,
    shadowCamBottom: apiLight.shadowcambottom,
    shadowCamLeft: apiLight.shadowcamleft,
    decay: apiLight.decay,
    distance: apiLight.distance,
    angle: apiLight.angle,
    penumbra: apiLight.penumbra,
    width: apiLight.width,
    height: apiLight.height,
    instanceOfCamera: apiLight.instanceofcamera,
  };
}

function getControlsObject(apiControlsObject) {
  const controlLevelOne = apiControlsObject.find(c => c.level === 1);

  if (!controlLevelOne) {
    return null;
  }

  const controlLevelTwo = apiControlsObject.find(c => c.level === 2);

  return {
    id: controlLevelOne.id,
    name: controlLevelOne.name,
    maxPolarAngle: controlLevelOne.maxpolarangle,
    minPolarAngle: controlLevelOne.minpolarangle,
    maxAzimuthAngle: controlLevelOne.maxazimuthangle,
    minAzimuthAngle: controlLevelOne.minazimuthangle,
    maxDistance: controlLevelOne.maxdistance,
    minDistance: controlLevelOne.mindistance,
    enablePan: controlLevelOne.ispanenabled,
    rotationSpeed: controlLevelOne.rotationspeed,
    secondLevel: controlLevelTwo
      ? {
          id: controlLevelTwo.id,
          name: controlLevelTwo.name,
          maxPolarAngle: controlLevelTwo.maxpolarangle,
          minPolarAngle: controlLevelTwo.minpolarangle,
          maxAzimuthAngle: controlLevelTwo.maxazimuthangle,
          minAzimuthAngle: controlLevelTwo.minazimuthangle,
          maxDistance: controlLevelTwo.maxdistance,
          minDistance: controlLevelTwo.mindistance,
          enablePan: controlLevelTwo.ispanenabled,
          rotationSpeed: controlLevelTwo.rotationspeed,
        }
      : null,
  };
}

function getExhibitArray(apiExhibitplaces) {
  const epObject = apiExhibitplaces.map(e => e.exhibitplaces_id);
  const exhibitPlaces = [];

  epObject.forEach(exhibitPlace => {
    exhibitPlaces.push(getExhibit(exhibitPlace));
  });

  return exhibitPlaces;
}

function getExhibit(apiExhibit) {
  if (!apiExhibit) {
    return null;
  }

  return {
    position: getXYZArray(apiExhibit, "position"),
    rotationY: apiExhibit.rotationy,
    name: apiExhibit.placename,
    platformName: apiExhibit.platformname,
    sign: apiExhibit.sign,
    signImage: apiExhibit.signimage,
    headline: apiExhibit.headlinelabel,
    sliderCalcMove: apiExhibit.slidercalcmove,
    sort: apiExhibit.sort,
    secondLevel: {
      camPosition: getXYZArray(apiExhibit, "camposition"),
      controlTarget: getXYZArray(apiExhibit, "controltarget"),
      sidebarTarget: getXYZArray(apiExhibit, "sidebartarget"),
      minAzimuthAngle: apiExhibit.minazimuthangle,
      maxAzimuthAngle: apiExhibit.maxazimuthangle,
    },
  };
}
