import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import Api from "../../api/api-helper";
import Experience from "../../experience/Experience";

import shared from "../../shared/styles.scss";
import custom from "./experience-container.scss";

import "@shs/ui-marcom/src/js/button";
import "../contact-plugin/clac-contact-plugin";

import "../loading-animation/clac-3d-loading-animation";

class ExperienceContainer extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      project: { type: Object, attribute: false },
      experience: { type: Object, attribute: false },
      isEditMode: { type: Boolean, attribute: "edit" },
      isLoading: { type: Boolean },
      popupType: { type: String, attribute: false },
      popupIsOpen: { type: Boolean },
      overlayIsOpen: { type: Boolean },
      swipeEnable: {type: Boolean},
      cameraPositionSetActive: {type: Boolean},
    };
  }

  constructor() {
    super();
    this.api = Api.getInstance();
    this.isEditMode = false;
    this.isLoading = true;
    this.overlayIsOpen = false;
    this.swipeEnable = true
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showOverlay", "getExperienceControls", "popupIsOpen", "popupType", "showAlert"];
  }

  get isPlatform() {
    return this.project.boothTemplate.type === "platform";
  }

  get isStandalone() {
    return this.project.boothTemplate.type === "standalone";
  }

  get isContact() {
    return this.experience?.get("project").get("config").contactEnabled;
  }

  get controls() {
    return this.getExperienceControls();
  }

  get background() {
    if (this.isStandalone) {
      return "background: radial-gradient(closest-side, rgba(220,220,220,1) 0%, rgba(210,210,210,1) 100%);";
    }

    return this.api.getBackgroundByName(this.project.get("config")?.background)
      ?.background;
  }

  get currentZoomLevel() {
    return this.experience?.get("exhibition")?.get("zoomLevel");
  }

  get cameraPositionSetActive() {
    return this.experience?.get("camera").introAniEditMode;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.experience.dispose();
  }

  async firstUpdated() {
    const container = this.shadowRoot.querySelector(".experience");
    const canvas = this.shadowRoot.querySelector(".experience__canvas");

    this.experience = new Experience(
      canvas,
      container,
      this.project,
      this.isEditMode
    );
    this.experience.isTouchEnabled = this.isTouchDevice;
    this.project.set("experience", this.experience);
    this.project.trigger("init");

    this.initListener();
  }

  get isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  updated(changedProperties) {
    if (changedProperties.has("popupType")) {
      if (this.popupType === "OVERLAY" && this.popupIsOpen) {
        this.overlayIsOpen = true;
      }
    }

    if (this.overlayIsOpen && !this.popupIsOpen) {
      this.setCamTargetToInit();
    }
  }

  setCamTargetToInit() {
    this.overlayIsOpen = false;
    const currentTarget = [];
    currentTarget[0] = this.experience.camera.controls.target.x;
    currentTarget[1] = this.experience.camera.controls.target.y;
    currentTarget[2] = this.experience.camera.controls.target.z;

    this.experience.project.exhibits.forEach(exhibit => {
      if (
        exhibit.secondLevel.sidebarTarget.every(element => {
          return currentTarget.includes(element);
        })
      ) {
        this.experience.camera.moveCameraTarget(
          exhibit.secondLevel.controlTarget
        );
      }
    });
  }

  initListener() {
    this.project.on("updated", () => {
      this.requestUpdate();
    });

    this.experience.get("exhibition").on("updated", () => {
      this.requestUpdate();
    });

    this.experience.get("exhibition").on("loaded", () => {
      if(!this.isPlatform && !this.isEditMode && this.isLoading) {
        this.project
        .get("experience")
        .get("camera")
        .startIntroAnimation();
      }
      this.isLoading = false;
    });

    this.experience.get("exhibition").on("hotspot-clicked", hotspot => {
      if (hotspot.contentType !== "tooltip") {
        this.showContent(hotspot);
      }
    });

    this.experience.get("exhibition").on('coverflow-is-finish', () => {
      this.swipeEnable = true;
    })
  }

  showContent(hotspot) {
    const overlay = html`
      <iframe
        class="experience__content"
        src="${hotspot.get("content")}"
      ></iframe>
    `;

    let overlayType = null;

    switch (hotspot.get("contentType")) {
      case "small":
        overlayType = "large";
        break;
      case "large":
        overlayType = "sidebar";
        break;

      default:
        break;
    }

    if (!this.isEditMode && overlayType) {
      this.showOverlay(overlay, overlayType);
    }
  }

  zoomOut() {
    this.experience.get("exhibition").switchLevel();
    this.project.trigger("updated");
  }

  saveCamPosition() {
    this.project
      .get("experience")
      .get("camera")
      .saveCurrentCameraPosition();
    this.showAlert("Position saved", `Intro camera position has been saved`, "success");
    this.project.trigger("updated");
  }

  render() {
    return html`
      <div class="experience" style="${this.background}">
        <canvas class="experience__canvas"></canvas>
        ${this.isLoading
          ? html`
              <clac-3d-loading-animation
                class="experience__loading"
              ></clac-3d-loading-animation>
            `
          : null}
      </div>

      ${this.isContact && !this.isEditMode
        ? html`
            <clac-contact-plugin
              base-path="${this.basePath}"
              .contactContent=${this.experience.project.contact}
            ></clac-contact-plugin>
          `
        : null}
      ${!this.isPlatform && this.cameraPositionSetActive
      ? html`
          <div class="control control--top-right">
            <uimc-button
              icon="check"
              .onClick=${() => this.saveCamPosition()}
              >Choose camera position</uimc-button
            >
          </div>
        `
      : null}
      ${!this.isPlatform && this.currentZoomLevel === 1
        ? html`
            <div class="control control--bottom-right">
              <uimc-button
                icon="reload"
                .onClick=${() =>
                  this.project
                    .get("experience")
                    .get("camera")
                    .resetCameraPosition()}
                >Reset view</uimc-button
              >
            </div>
          `
        : null}
      ${this.isPlatform && !this.isEditMode && this.currentZoomLevel === 1
        ? html`
            <div class="control control--middle-left">
              <uimc-button
                primary
                type="control"
                icon="arrow-left-small"
                theme="dark" 
                ?disabled=${!this.swipeEnable}
                .onClick=${() => {
                  this.swipeEnable = false
                  this.experience
                    .get("exhibition")
                    .get("floor")
                    .coverFlow("right");
                }}
              ></uimc-button>
            </div>
            <div class="control control--middle-right">
              <uimc-button
                primary
                type="control"
                icon="arrow-right-small"
                theme="dark"
                ?disabled=${!this.swipeEnable}
                .onClick=${() => {
                  this.swipeEnable = false
                  this.experience
                    .get("exhibition")
                    .get("floor")
                    .coverFlow("left");
                }}
              ></uimc-button>
            </div>
          `
        : null}
      ${this.currentZoomLevel === 2
        ? html`
            <div class="control control--top-left">
              <uimc-button
                .onClick=${() => this.zoomOut()}
                icon="arrow-left-small"
                >Back to overview</uimc-button
              >
            </div>
          `
        : null}
      ${this.controls?.bottomLeft
        ? html`
            <div class="control control--bottom-left">
              ${this.controls?.bottomLeft}
            </div>
          `
        : null}
      ${this.controls?.center
        ? html`
            <div class="control control--center">${this.controls?.center}</div>
          `
        : null}
    `;
  }
}

customElements.define("experience-container", ExperienceContainer);
