import axios from "axios";
import jwt_decode from "jwt-decode";

import {
  getCurrentUser,
  getUsers,
  getSharedWithList,
  shareWithUser,
  unshareUser,
} from "./user.js";
import { getBoothTemplates, getBoothtemplateById } from "./boothtemplate.js";
import { getModels, getModel } from "./model.js";
import {
  getProjects,
  getProject,
  createProject,
  updateProject,
  getProjectConfig,
  getProjectBuild,
  deleteBuild,
  addBackwallVisual,
  copyProject,
  archiveProject,
  dearchiveProject,
  setProjectPrivate,
  setProjectPublic,
  updateControl,
  updateCamera,
} from "./project.js";
import {
  updateExhibit,
  addKeyVisual,
  deleteExhibitsHotspot,
} from "./exhibit.js";
import { getBusinessLines, getBusinessLineById } from "./businesslines.js";
import {
  createHotspot,
  createContentPage,
  addHotspotToExhibit,
  updateHotspot,
  deleteHotspot,
  deleteHotspots,
} from "./hotspot.js";
import { updatePlatform } from "./platforms.js";
import { deleteKeyVisual } from "./keyvisual.js";
import { getContactTemplate, updateContactById } from "./contact.js";
import { expireCookie, getCookie, setCookie } from "../services/cookie.js";

const backgroundColors = [
  {
    name: "light",
    label: "Light gray background",
    img: "assets/images/light.png",
    background:
      "background: radial-gradient(circle, rgba(230, 230, 230, 1) 0%, rgba(140, 140, 140, 1) 100%);",
  },
  {
    name: "middle",
    label: "Mid gray background",
    img: "assets/images/mid.png",
    background:
      "background: radial-gradient(circle, rgba(130, 130, 130, 1) 0%, rgba(91, 91, 91, 1) 100%);",
  },
  {
    name: "dark",
    label: "Dark gray background",
    img: "assets/images/dark.png",
    background:
      "background: radial-gradient(circle, rgba(40, 40, 40, 1) 0%, rgba(20, 20, 20, 1) 100%);",
  },
];

let options = () => {};

const Singleton = (() => {
  let instance = null;

  const init = async user => {
    const directusUrl = SIMPLEX.API_URL;

    let token = getCookie("token");
    let refreshToken = getCookie("refresh-token");

    if (token === null && user) {
      try {
        ({ token, refreshToken } = await login(directusUrl, user));

        if (token !== null) {
          setCookie("token", token);
        }
        if (refreshToken !== null) {
          setCookie("refresh-token", refreshToken);
        }
      } catch (error) {
        throw new Error(error);
      }
    }

    options = () => {
      return {
        headers: {
          Authorization: `Bearer ${getCookie("token")}`,
        },
      };
    };

    const logout = async () => handleLogout(directusUrl);
    const resetPassword = async email =>
      handleResetPassword(directusUrl, email);
    const submitNewPassword = async ({ password, token }) =>
      handleSubmitNewPassword(directusUrl, { password, token });
    const updatePassword = async ({ user, password }) =>
      handleUpdatePassword(directusUrl, { user, password }, options());

    const getCurrentUserFromToken = async () =>
      await getCurrentUser(directusUrl, options());
    const getUserList = async () => getUsers(directusUrl, options());
    const getSharedWithUsersList = async id =>
      getSharedWithList(id, directusUrl, options());
    const shareProjectWithUser = async (projectId, userIdentifier, type) =>
      shareWithUser(projectId, userIdentifier, type, directusUrl, options());
    const unshareProjectWithUser = async shareWith =>
      unshareUser(shareWith, directusUrl, options());

    const models = async () => await getModels(directusUrl, options());
    const projects = async (filterOptions, sortOptions, paginationLimit) =>
      getProjects(
        directusUrl,
        options(),
        filterOptions,
        sortOptions,
        paginationLimit
      );
    const getProjectById = async id => getProject(id, directusUrl, options());

    const copyProjectFromId = async id =>
      copyProject(id, directusUrl, options());

    const getContact = async language =>
      getContactTemplate(language, directusUrl, options());
    const updateContact = async (id, data) =>
      updateContactById(id, data, directusUrl, options());

    const getFileSource = fileHash =>
      getSource(fileHash, directusUrl, getCookie("token"));

    const boothTemplates = async () =>
      getBoothTemplates(directusUrl, options());
    const boothTemplateById = async id =>
      getBoothtemplateById(id, directusUrl, options());

    const businessLines = async () => getBusinessLines(directusUrl, options());
    const businessLineById = async id =>
      getBusinessLineById(id, directusUrl, options());

    const createNewProject = async projectObject =>
      createProject(projectObject, directusUrl, options());
    const archiveProjectById = async projectId =>
      archiveProject(projectId, directusUrl, options());
    const dearchiveProjectById = async projectId =>
      dearchiveProject(projectId, directusUrl, options());
    const setProjectToPrivate = async projectId =>
      setProjectPrivate(projectId, directusUrl, options());
    const setProjectToPublic = async projectId =>
      setProjectPublic(projectId, directusUrl, options());

    const updateProjectById = async (id, fields) =>
      updateProject(id, fields, directusUrl, options());
    const updateExhibitById = async (id, fields) =>
      updateExhibit(id, fields, directusUrl, options());
    const updateHotspotById = async (id, fields) =>
      updateHotspot(id, fields, directusUrl, options());
    const updatePlatformById = async (id, fields) =>
      updatePlatform(id, fields, directusUrl, options());
    const updateControlById = async (id, fields) =>
      updateControl(id, fields, directusUrl, options());
    const updateCameraById = async (id, fields) =>
      updateCamera(id, fields, directusUrl, options());

    const addBackwallVisualByProjectId = async (projectId, keyVisualData) =>
      addBackwallVisual(projectId, keyVisualData, directusUrl, options());
    const addKeyVisualByExhibitId = async (exhibitId, keyVisualData) =>
      addKeyVisual(exhibitId, keyVisualData, directusUrl, options());

    const deleteKeyVisualById = async id =>
      deleteKeyVisual(id, directusUrl, options());
    // const deleteHotspot = async (hotspot) =>
    //    deleteExhibitsHotspot(hotspot, directusUrl, options());

    const deleteHotspotById = async id =>
      deleteHotspot(id, directusUrl, options());
    const deleteHotspotsByIds = async ids =>
      deleteHotspots(ids, directusUrl, options());
    const createNewHotspot = async hotspot =>
      createHotspot(hotspot, directusUrl, options());
    const createNewHotspotContentPage = async (hotspotId, projectId) =>
      createContentPage(hotspotId, projectId, directusUrl, options());
    const addNewHotspotToExhibit = async (hotspotId, exhibitId) =>
      addHotspotToExhibit(hotspotId, exhibitId, directusUrl, options());
    const getProjectConfigById = async projectId =>
      getProjectConfig(projectId, directusUrl, options());

    const createProjectBuild = async projectId =>
      getProjectBuild(projectId, directusUrl, options());

    const deleteBuildByHash = async fileHash =>
      deleteBuild(fileHash, directusUrl, options());
    const uploadKeyVisualFiles = async formData =>
      uploadFiles(formData, directusUrl, options());

    const getBackgroundByName = name => {
      return backgroundColors.find(bg => bg.name == name);
    };
    const getBackgroundColors = () => {
      return backgroundColors;
    };

    const checkTokenExpiration = async token => {
      const decode = jwt_decode(token);
      console.log("decode :>> ", decode);

      if (decode.exp < Date.now() / 1000) {
        return true;
      }

      const res = await requestToken(directusUrl, refreshToken);
      ({ token } = res);
      setCookie("token", token);

      return true;
    };

    const requestToken = async (url, refreshToken) => {
      try {
        const res = await axios.post(new URL("/auth/refresh", url), {
          refresh_token: refreshToken,
        });

        return {
          token: res.data.data.access_token,
          refreshToken: res.data.data.refresh_token,
        };
      } catch (error) {
        throw new Error(`Refresh failed/n${error}`);
      }
    };
    // could be refactored to functions and moved to requesting component

    return {
      options,
      init,
      logout,
      resetPassword,
      submitNewPassword,
      updatePassword,

      getCurrentUserFromToken,
      getUserList,
      getSharedWithUsersList,
      shareProjectWithUser,
      unshareProjectWithUser,

      boothTemplateById,
      models,

      projects,
      getProjectById,

      getContact,
      updateContact,

      updateProjectById,
      updateExhibitById,
      updateHotspotById,
      updatePlatformById,
      updateControlById,
      updateCameraById,

      addBackwallVisualByProjectId,
      addKeyVisualByExhibitId,
      deleteKeyVisualById,

      uploadKeyVisualFiles,

      createNewHotspot,
      createNewHotspotContentPage,
      addNewHotspotToExhibit,
      deleteHotspotById,
      deleteHotspotsByIds,

      getFileSource,
      checkTokenExpiration,

      boothTemplates,
      businessLines,
      businessLineById,
      createNewProject,
      copyProjectFromId,
      archiveProjectById,
      dearchiveProjectById,
      setProjectToPrivate,
      setProjectToPublic,

      getProjectConfigById,
      createProjectBuild,
      deleteBuildByHash,

      getBackgroundByName,
      getBackgroundColors,
      getModel,
    };
  };

  return {
    init: async user => {
      if (!instance) {
        instance = await init(user);
      }
      return instance;
    },
    getInstance: () => instance,
    getFileSource: fileHash => instance.getFileSource(fileHash),
  };
})();

export default Singleton;

async function login(url, user) {
  if (!user) {
    return null;
  }

  try {
    const res = await axios.post(new URL("/auth/login", url), {
      email: user.email,
      password: user.password,
    });

    return {
      token: res.data.data.access_token,
      refreshToken: res.data.data.refresh_token,
    };
  } catch (error) {
    throw new Error(`Login failed\n${error}`);
  }
}

async function handleLogout(url) {
  const res = await axios.post(new URL("/auth/logout", url), {
    refresh_token: getCookie("refresh-token"),
  });

  expireCookie("token");
  expireCookie("refresh-token");

  return res.status;
}

async function handleResetPassword(url, email) {
  const res = await axios.post(new URL("/auth/password/request", url), {
    reset_url: SIMPLEX.RESET_PASSWORD_URL,
    email,
  });
  return res.status;
}

async function handleSubmitNewPassword(url, { password, token }) {
  const res = await axios.post(new URL("/auth/password/reset", url), {
    password,
    token,
  });
  return res.status;
}

async function handleUpdatePassword(url, { user, password }, options) {
  const res = await axios.patch(
    new URL(`/users/${user.id}`, url),
    {
      password,
    },
    options
  );
  return res.status;
}

function getSource(fileHash, url, token) {
  if (!fileHash) {
    return null;
  }
  return `${url}/assets/${fileHash}?access_token=${token}`;
}

async function uploadFiles(formData, url, options) {
  try {
    const res = await axios.post(new URL("/files", url), formData, options);

    return res;
  } catch (error) {
    throw new Error(`File Upload failed/n${error}`);
  }
}
