import { LitElement, html, css } from "lit";

import "@shs/ui-marcom/src/js/collection";
import "@shs/ui-marcom/src/js/collection-item";

import "../platform-list-item/platform-list-item";

import shared from "../../../../shared/styles.scss";
import custom from "./platform-list.scss";

class PlatformList extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object, attribute: false },
      selectedPlatform: { type: Object, attribute: false },
      activeTab: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  get sortedPlatforms() {
    return this.project
      .get("experience")
      .get("exhibition")
      .get("floor")
      .platforms.sort((a, b) => a.platformData.sort - b.platformData.sort);
  }

  render() {
    return html`
      <div class="platform-list">
        <uimc-collecion>
          ${this.sortedPlatforms.map(platform => {
            return html`
              <platform-list-item
                base-path="${this.basePath}"
                .platform=${platform}
                .project=${this.project}
                .activeTab=${this.activeTab}
              ></platform-list-item>
            `;
          })}
        </uimc-collecion>
      </div>
    `;
  }
}

customElements.define("platform-list", PlatformList);
