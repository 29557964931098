import { html, LitElement, css } from "lit";
import { classMap } from "lit/directives/class-map";
import styles from "./custom-value-slider.scss";

/**
 * A component allowing users to view and select from a range of value along a slidable bar.
 * It works well for adjustments such as positioning an interactive object or setting brightness of a background.
 *
 * @element value-slider
 *
 * @attr {Boolean} input-field - Sets inputfield for values(appearance on top of slider)
 * @attr {Boolean} range-icon - Sets icon into range thumb
 * @attr {Number} min-value - Sets min-value for slider
 * @attr {Number} max-value - Sets max-value for slider
 * @attr {Number} value - Sets initial start value
 * @attr {String} type - Sets slider type  | Defaul: none | Options: progress, progress-double
 * @attr {Number} second-value - Sets second initial start value in case of type: double-slider
 * @attr {Number} steps - Sets steps for step-slider
 * @attr {Boolean} scale - adds visual breackpoints according to steps value
 * @attr {String} label - Sets label on top of slider
 * @attr {String} theme - Sets theme of the slider  | Default: none | Options: dark, gray
 *
 * @fires onChange - Callback function that gets triggered if the state of the component changes
 *
 * @example <uimc-value-slider min-value=0 max-value=100 value=20 label="Slider Label">
 *          </uimc-value-slider>
 */
class CustomValueSlider extends LitElement {
  static get properties() {
    return {
      minValue: {
        type: Number,
        attribute: "min-value",
      },
      maxValue: {
        type: Number,
        attribute: "max-value",
      },
      value: {
        type: Number,
        attribute: true,
      },
      secondValue: {
        type: Number,
        attribute: "second-value",
      },
      label: {
        type: String,
        attribute: true,
      },
      rangeIcon: {
        type: Boolean,
        attribute: "range-icon",
      },
      type: {
        type: String,
        attribute: true,
      },
      theme: {
        type: String,
        attribute: true,
      },
      steps: {
        type: Number,
        attribute: true,
      },
      inputField: {
        type: Boolean,
        attribute: "input-field",
      },
      scale: {
        type: Boolean,
        attribute: true,
      },
      scaleValue: {
        type: Array,
        attribute: false,
      },
      onChange: {
        type: Object,
      },
      formatValue: {
        type: Object,
      },
      hideTooltip: {
        attribute: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [css(styles)];
  }

  constructor() {
    super();
    this.minValue = 0;
    this.maxValue = 100;
    this.value = null;
    this.secondValue = null;
    this.steps = null;
    this.scaleValue = [];
    this.scale = false;
    this.label = null;
    this.rangeIcon = false;
    this.inputField = false;
    this.type = null;
    this.theme = null;
    this.onChange = () => {};
    this.formatValue = value => value;
    this.hideTooltip = false;
  }

  firstUpdated() {
    this.tooltip = this.shadowRoot.querySelector(".value-slider__tooltip");

    this.tooltipSecond = this.shadowRoot.querySelector(
      "div[name='second-tooltip']"
    );
    this.slider = this.shadowRoot.querySelector(".value-slider__slider");
    this.secondSlider = this.shadowRoot.querySelector(
      ".value-slider__slider--double"
    );
    if (this.inputField) {
      this.firstInput = this.shadowRoot.querySelector(
        "input[name='first-input']"
      );
      this.secondInput = this.shadowRoot.querySelector(
        "input[name='second-input']"
      );
    }
    this.calcStyleValues();
    if (this.steps) {
      this.calcSteps(this.steps);
    }
  }

  calcStyleValues() {
    if (this.hideTooltip) {
      return;
    }

    if (!this.inputField) {
      this.tooltip.style.left = `${Number(
        ((this.slider.value - this.slider.min) * 80) /
          (this.slider.max - this.slider.min) +
          3
      )}%`;
    }

    if (this.type === "progress") {
      const progressValue =
        ((this.slider.value - this.slider.min) * 100) /
        (this.slider.max - this.slider.min);
      this.slider.style.background = `linear-gradient(to right, #ED6802 ${progressValue}%, transparent ${progressValue}%)`;
    }
    if (this.type === "progress-double") {
      const staticValue =
        ((this.value - this.minValue) * 100) / (this.maxValue - this.minValue);
      const secondStaticValue =
        ((this.secondValue - this.minValue) * 100) /
        (this.maxValue - this.minValue);
      const progressValue =
        ((this.secondSlider.value - this.secondSlider.min) * 100) /
        (this.secondSlider.max - this.secondSlider.min);
      if (!this.inputField) {
        this.tooltipSecond.style.left = `${Number(
          ((this.secondSlider.value - this.secondSlider.min) * 80) /
            (this.secondSlider.max - this.secondSlider.min) +
            3
        )}%`;
      }
      this.slider.style.background = `linear-gradient(to right, transparent ${staticValue}%, #ED6802 ${-progressValue}%, #ED6802 ${progressValue}%, transparent ${secondStaticValue}%)`;
    }
  }

  calcSteps(steps) {
    const scale = (this.maxValue - this.minValue) / steps;
    this.scaleValue = Array(scale).fill("");
    this.scaleValuePercentage = `${steps}%`;
  }

  _updateFirstValue(currentValue) {
    if (this.secondValue && currentValue > this.secondSlider.value - 0.1) {
      this.slider.value = this.secondSlider.value - 0.1;
      this.value = this.secondSlider.value;
    } else {
      this.slider.value = currentValue;
      this.value = currentValue;
    }

    if (this.inputField) {
      this.firstInput.value = this.value;
    }
    this.calcStyleValues();
  }

  _updateSecondValue(currentValue) {
    if (currentValue < this.slider.value && currentValue < this.maxValue) {
      this.secondSlider.value = this.slider.value;
      this.secondValue = this.slider.value;
    } else {
      this.secondSlider.value = currentValue;
      this.secondValue = currentValue;
    }

    if (this.inputField) {
      this.secondInput = this.secondValue;
    }

    this.calcStyleValues();
  }

  render() {
    return html`
      <div
        class=${classMap({
          "value-slider": true,
          dark: this.theme === "dark",
          gray: this.theme === "gray",
          "value-slider--elevated":
            this.scale && this.type === "progress-double",
        })}
      >
        <div class="row">
          <div class="col-12">
            <div class="value-slider__label">
              ${this.label
                ? html`
                    ${this.label}
                  `
                : html`
                    <p
                      class="value-slider__label value-slider__label--spare"
                    ></p>
                  `}
              ${this.inputField
                ? html`
                    <div class="value-slider__input">
                      <input
                        name="first-input"
                        type="number"
                        step="${this.steps}"
                        value="${this.value}"
                        min="${this.minValue}"
                        max="${this.maxValue}"
                        @input="${e => {
                          this._updateFirstValue(e.target.value);
                          this.onChange(e);
                        }}"
                      />

                      ${this.type === "progress-double"
                        ? html`
                            <span class="value-slider__input-space"> - </span>
                            <input
                              name="second-input"
                              type="number"
                              step="${this.steps}"
                              value="${this.secondValue}"
                              min="${this.minValue}"
                              max="${this.maxValue}"
                              @input="${e => {
                                this._updateSecondValue(e.target.value);
                                this.onChange(e);
                              }}"
                            />
                          `
                        : null}
                    </div>
                  `
                : html`
                    ${!this.hideTooltip
                      ? html`
                          <div class="value-slider__tooltip">
                            ${this.formatValue(this.value)}
                          </div>
                        `
                      : null}
                  `}
              ${this.inputField
                ? null
                : html`
                    ${this.type === "progress-double" && !this.hideTooltip
                      ? html`
                          <div
                            name="second-tooltip"
                            class="value-slider__tooltip"
                          >
                            ${this.formatValue(this.secondValue)}
                          </div>
                        `
                      : null}
                  `}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input
              class=${classMap({
                "value-slider__slider": true,
                "value-slider__slider--overhead": true,
                "value-slider__slider--range-icon": this.rangeIcon,
              })}
              type="range"
              step="${this.steps}"
              min="${this.minValue}"
              max="${this.maxValue}"
              value="${this.value}"
              @input="${e => {
                this._updateFirstValue(e.target.value);
                this.onChange(e);
              }}"
            />

            ${this.type === null
              ? html`
                  <input
                    class=${classMap({
                      "value-slider__slider": true,
                      "value-slider__slider--below": true,
                    })}
                    type="range"
                  />
                `
              : null}
            ${this.type === "progress"
              ? html`
                  <input
                    class=${classMap({
                      "value-slider__slider": true,
                      "value-slider__slider--below": true,
                      double: true,
                    })}
                    type="range"
                  />
                `
              : null}
            ${this.type === "progress-double"
              ? html`
                  <input
                    class=${classMap({
                      "value-slider__slider": true,
                      "value-slider__slider--double": true,
                      "value-slider__slider--range-icon": this.rangeIcon,
                    })}
                    type="range"
                    step="${this.steps}"
                    min="${this.minValue}"
                    max="${this.maxValue}"
                    value="${this.secondValue}"
                    @input="${e => {
                      this._updateSecondValue(e.target.value);
                      this.onChange(e);
                    }}"
                  />
                `
              : null}
            ${this.scale
              ? html`
                  <div class="value-slider__scale-wrapper">
                    ${this.scaleValue.map(
                      (breackpoint, i) => html`
                        <div
                          style="width:${this.scaleValuePercentage};"
                          class="value-slider__scale-breackpoint"
                        ></div>
                      `
                    )}
                  </div>
                `
              : null}
          </div>
        </div>
      </div>
    `;
  }
}

window.customElements.define("custom-value-slider", CustomValueSlider);

export default CustomValueSlider;
