import * as THREE from "three";
import * as TWEEN from "@tweenjs/tween.js/dist/tween.umd";
import FloorMirror from "./FloorMirror";
import PlatformLabel from "./PlatformLabel";
import ApiModel from "../Models/ApiModel";

let project = null;
let floor = null;

export default class Platform extends ApiModel {
  constructor(platformData, exhibition) {
    super();

    ({ project } = exhibition.experience);
    ({ floor } = exhibition.experience.exhibition);

    this.exhibition = exhibition;
    this.isClicked = false;
    this.isFocused = false;
    this.isZoomed = false;

    this.platformData = platformData;
    this.platformGroup = new THREE.Group();

    this.floorMirror = new FloorMirror({ exhibition });

    setTimeout(() => {
      // ERROR: three.module.js:6799 THREE.Object3D.add: object not an instance of THREE.Object3D. undefined
      this.init();
    }, 1000);
  }

  init() {
    this.platform = floor.model.clone();

    this.platformGroup.add(this.platform);
    this.platformGroup.position.set(...this.platformData.position);
    this.platformGroup.rotation.y = 0.5;
    this.platformGroup.scale.x = this.platformData.firstLevelScale;
    this.platformGroup.scale.z = this.platformData.firstLevelScale;
    this.platformGroup.name = this.platformData.name;

    if (!this.platformData.isActive) {
      this.platformGroup.visible = false;
    }

    this.createSweepMaterials();

    this.createLabel();
    this.setExhibits();
  }

  createLabel() {
    this.buttonLabel = new PlatformLabel({ exhibition: this.exhibition }, this);
  }

  setExhibits() {
    const cExhibit = this.exhibition.experience.project.exhibits.filter(
      e =>
        e.platformName === this.platformData.name &&
        e.placeName.includes("central")
    );
    this.centralExhibit = cExhibit[0];
    this.secondLevelExhibits = [];
    this.exhibition.experience.project.exhibits.map(e => {
      if (
        e.platformName === this.platformData.name &&
        !e.placeName.includes("central")
      ) {
        this.secondLevelExhibits.push(e);
      }
    });
  }

  createSweepMaterials() {
    this.sweepDefault = this.platformGroup.getObjectByName(
      "leuchtstreifen_petrol_1"
    );
    this.sweepHover = this.platformGroup.getObjectByName(
      "leuchtstreifen_orange_1"
    );
    this.sweepHover.visible = false;
  }

  setSweepMaterial(sweep) {
    if (sweep === "petrol") {
      this.sweepDefault.visible = true;
      this.sweepHover.visible = false;
    } else {
      this.sweepDefault.visible = false;
      this.sweepHover.visible = true;
    }
  }

  setPosition() {
    this.platformGroup.position.set(...this.platformData.position);
  }

  setPlatformActive() {
    floor.platforms.forEach(platform => {
      if (platform.isFocused) {
        platform.setPlatformUnActive();
      }
    });

    this.isFocused = true;
    this.setSweepMaterial("orange");
    this.buttonLabel.setPrimary();
  }

  setPlatformVisibility(isVisible) {
    this.platformGroup.visible = isVisible;
  }

  setPlatformUnActive() {
    this.setSweepMaterial("petrol");
    this.buttonLabel.unsetPrimary();
    this.isFocused = false;
  }

  zoomToPlatform() {
    this.setSweepMaterial("orange");
    this.isClicked = true;
    this.platformGroup.add(this.floorMirror.mirrorGroup);
    floor.rotateToPlatform(this);
  }

  manageZoom() {
    this.isZoomed = true;
    this.handleZoomedPlatform();
    this.handleZoomedExhibits();
  }

  handleZoomedExhibits() {
    this.secondLevelExhibits.map(e => {
      if (e.exhibitGroup) {
        e.scaleVertical(1000);
        e.exhibitGroup.visible = true;
        e.restartVideo();
      }
      if (this.exhibition.editMode && !e.resource) {
        e.sign.setVisiblitity(true);
        e.sign.scaleUp(1000);
      }
    });

    this.exhibition.experience
      .get("exhibition")
      .switchLevel(this.centralExhibit, this.platformData.name);

    project.exhibits.forEach(exhibit => {
      if (exhibit.platformName !== this.platformData.name) {
        this.handleInactiveExhibits(exhibit);
      }
    });
  }

  handleInactiveExhibits(exhibit) {
    exhibit.moveUp(exhibit.exhibitGroup);
    if (this.exhibition.editMode) {
      exhibit.sign.scaleDown();
    }
  }

  handleZoomedPlatform() {
    this.scalePatform(this.platformData.secondLevelScale);
    floor.platforms.forEach(p => {
      p.buttonLabel.scaleDown();
      if (p.platformData.name !== this.platformData.name) {
        this.moveUp(p.platformGroup);
      }
    });
  }

  reset() {
    // for all platforms
    this.moveDown(this.platformGroup, this.platformData.position[1]);
    if (this.exhibition.editMode && !this.centralExhibit.resource) {
      this.centralExhibit.sign.setVisiblitity(true);
      this.centralExhibit.sign.scaleUp(0);
    }

    // for platform in focus
    if (this.isClicked) {
      this.setPlatformUnActive();

      this.platformGroup.remove(this.floorMirror.mirrorGroup);
      this.scalePatform(this.platformData.firstLevelScale);
      this.secondLevelExhibits.map(e => {
        if (e.exhibitGroup) {
          e.exhibitGroup.visible = false;
        }
        if (e.sign.group) {
          e.sign.scaleDown();
        }
      });

      setTimeout(() => {
        this.isZoomed = false;
        this.isClicked = false;
        floor.resetAllPlatformGroup();
      }, 2000);
    }
  }

  moveUp(object) {
    if (object) {
      new TWEEN.Tween(object.position)
        .to({ y: 7000 }, 2000)
        .delay(1000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
    }
  }

  moveDown(object, y) {
    if (object) {
      new TWEEN.Tween(object.position)
        .to({ y: y }, 2000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
    }
  }

  scaleUp(object, scaling) {
    if (object) {
      new TWEEN.Tween(object.scale)
        .to({ x: scaling, y: scaling, z: scaling }, 1000)
        .delay(2000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
    }
  }

  scaleDown(object) {
    if (object) {
      new TWEEN.Tween(object.scale)
        .to({ x: 0, y: 0, z: 0 }, 1000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
    }
  }

  scalePatform(scaling) {
    new TWEEN.Tween(this.platformGroup.scale)
      .to({ x: scaling, z: scaling }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  }

  async setScale(scaling) {
    // large 1 | medium 0.8 | small 0.6
    new TWEEN.Tween(this.platformGroup.scale)
      .to({ x: scaling, z: scaling }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();

    this.platformData.secondLevelScale = scaling;
    const res = await this.api.updatePlatformById(this.platformData.id, {
      secondlevelscale: this.platformData.secondLevelScale,
    });
    return res.status;
  }
}
