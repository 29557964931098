import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../../../shared/styles.scss";
import custom from "./platform-list-detail.scss";

import "@shs/ui-marcom/src/js/button";

import "../../../../custom-uimc/custom-textfield/custom-textfield";
import "../../../../custom-uimc/custom-select/custom-select";

import "../exhibit-list/exhibit-list";
import "../../../edit-box/edit-box";
import "../../../../custom-uimc/custom-tooltip-button/custom-tooltip-button";

class PlatformListDetail extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object, attribute: false },
      selectedPlatform: { type: Object, attribute: false },
      selectedPlatformObject: { type: Object, attribute: false },
      platformLabel: { type: String, attribute: false },
      isEditBoxOpen: { type: Boolean, attribute: false },
    };
  }

  constructor() {
    super();
    this.platformLabel = "";
    this.isEditBoxOpen = false;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  firstUpdated() {
    this.project.on("updated", () => {
      if (
        this.project
          .get("experience")
          .get("exhibition")
          .get("zoomLevel") !== 2
      ) {
        this.handleBackToOverviewClick();
      }
    });

    this.project
      .get("experience")
      .get("exhibition")
      .get("floor")
      .platforms.forEach(platform => {
        if (platform.platformData.name === this.selectedPlatform) {
          this.selectedPlatformObject = platform;
        }
      });

    this.platformLabel = this.selectedPlatformObject.platformData.hotspot.labelValue;
  }

  handleBackToMainLevelClick() {
    this.project
      .get("experience")
      .get("exhibition")
      .switchLevel();
    this.bubbleResetSelectedPlatformEvent();
  }

  handleBackToOverviewClick() {
    this.bubbleResetSelectedPlatformEvent();
  }

  bubbleResetSelectedPlatformEvent() {
    const resetSelectedPlatformEvent = new CustomEvent(
      "reset-selected-platform-event",
      {}
    );

    this.dispatchEvent(resetSelectedPlatformEvent);
  }

  static get inject() {
    return ["showAlert", "showOverlay", "setLoading"];
  }

  async setLabel(label) {
    this.selectedPlatformObject.platformData.hotspot.labelValue = label;
    const status = await this.selectedPlatformObject.buttonLabel.saveLabel();
    this.project.trigger("updated");
  }

  async setPlatformSize(platformSize) {
    this.setLoading(true);

    const status = await this.selectedPlatformObject.setScale(platformSize);

    if (status === 200) {
      this.showAlert("Saved", `Platform size has been saved.`, "success");
    }

    if (status >= 400) {
      this.showAlert(
        "Saving failed",
        `Saving platform size has failed.`,
        "error"
      );
    }

    this.setLoading(false);
  }

  async save() {
    this.setLoading(true);

    const status = await this.project.persist();

    if (status === 200) {
      this.showAlert("Saved", `Platform label has been saved.`, "success");
    }

    if (status >= 400) {
      this.showAlert(
        "Saving failed",
        `Saving platform label has failed.`,
        "error"
      );
    }

    this.isEditBoxOpen = null;

    this.setLoading(false);
  }

  render() {
    return html`
      ${this.selectedPlatformObject
        ? html`
            <div class="platform-list-detail">
              <uimc-button
                size="small"
                icon="arrow-left-small"
                @click=${() => {
                  this.handleBackToMainLevelClick();
                }}
                >Back to overview</uimc-button
              >

              <div
                class="display-flex align-items-center justify-content-between margin-bottom-1"
              >
                <h5>
                  ${this.platformLabel
                    ? html`
                        ${this.platformLabel}
                      `
                    : html`
                        No platform label available
                      `}
                </h5>

                <custom-tooltip-button
                  tooltip="Edit platform label"
                  type="control"
                  icon="edit"
                  ?disabled=${this.isEditBoxOpen}
                  .onClick=${() => {
                    this.isEditBoxOpen = true;
                  }}
                >
                  Edit platform label
                </custom-tooltip-button>
              </div>

              ${this.isEditBoxOpen
                ? html`
                    <edit-box
                      class="margin-bottom-1"
                      description="Type in the platform label and press save button to store the setting."
                      .onSubmit=${() => {
                        this.setLabel(this.platformLabel);
                        this.save();
                      }}
                    >
                      <custom-textfield
                        theme="gray"
                        .onChange=${label => {
                          this.platformLabel = label;
                        }}
                        label="Platform label"
                        value=${this.selectedPlatformObject.platformData.hotspot
                          .labelValue}
                      ></custom-textfield>
                    </edit-box>
                  `
                : null}

              <custom-select
                label="Size of the platform"
                .items=${[
                  {
                    name: "Large",
                    value: 1,
                    isSelected:
                      this.selectedPlatformObject.platformData
                        .secondLevelScale === 1,
                  },
                  {
                    name: "Medium",
                    value: 0.8,
                    isSelected:
                      this.selectedPlatformObject.platformData
                        .secondLevelScale === 0.8,
                  },
                  {
                    name: "Small",
                    value: 0.6,
                    isSelected:
                      this.selectedPlatformObject.platformData
                        .secondLevelScale === 0.6,
                  },
                ]}
                .isChosen=${true}
                .onChange=${platformSize => {
                  this.setPlatformSize(platformSize);
                }}
              >
              </custom-select>

              <div class="margin-bottom-1">
                You can add up to 4 exhibits to this platform. Use the button
                next to the symbol of placeholder to which you want to add the
                exhibit.
              </div>
              <exhibit-list
                base-path="${this.basePath}"
                .project=${this.project}
                .selectedPlatform=${this.selectedPlatform}
              ></exhibit-list>
            </div>
          `
        : null}
    `;
  }
}

customElements.define("platform-list-detail", PlatformListDetail);
