import { html, unsafeCSS } from "lit";
import ApplicationBar from "@shs/ui-marcom/src/js/application-bar";

import custom from "./custom-application-bar.scss";

export default class CustomApplicationBar extends ApplicationBar {
  static get styles() {
    return [super.styles, unsafeCSS(custom)];
  }

  render() {
    return html`
      <div class="application-bar application-bar--compact">
        <div class="application-bar__container">
          <div class="application-bar__logo">
            <a href="#project-list" data-navigo>
              <uimc-logo size="small"></uimc-logo>
            </a>
          </div>
          <div class="application-bar__app-identifier">
            ${this.appIdentifier}
          </div>
          <div class="application-bar__additional-functions">
            <div class="meta-menu">
              <div class="meta-menu__list">
                <slot
                  name="additional-functions"
                  id="additional-functions"
                ></slot>
              </div>
            </div>
          </div>
          <div class="application-bar__meta-menu">
            <div class="meta-menu">
              <div class="meta-menu__list">
                <slot name="meta-menu" id="meta-menu"></slot>
              </div>
            </div>
          </div>
          <div class="application-bar__user-identifier">
            <slot name="user-identifier" id="user-identifier"></slot>
          </div>
        </div>
      </div>
    `;
  }
}

window.customElements.define("custom-application-bar", CustomApplicationBar);
