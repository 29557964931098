import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import Api from "../../api/api-helper";

import "../../custom-uimc/custom-dropdown/custom-dropdown";

import "@shs/ui-marcom/src/js/menu";
import "@shs/ui-marcom/src/js/menu-item";

import shared from "../../shared/styles.scss";
import custom from "./project-list-item-dropdown.scss";

class ProjectListItemDropdown extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      projects: { type: Object, attribute: false },
      project: { type: Object, attribute: false },
      currentUser: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.api = Api.getInstance();
    this.project = [];
    this.projects = [];
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["setLoading", "showAlert", "showModal", "currentUser"];
  }

  get sharedUsers() {
    const sharedUsers = [];

    this.project.get("sharedWith").forEach(sharedUser => {
      sharedUsers.push(sharedUser?.directus_users_id);
    });

    return sharedUsers;
  }

  get userProjectPermissions() {
    return (
      this.sharedUsers.includes(this.currentUser.id) ||
      this.project.get("userCreated").id === this.currentUser.id ||
      ["SiemensAdmin", "Admin"].includes(this.currentUser.role?.name)
    );
  }

  bubbleProjectActionEvent(eventType, project) {
    const projectActionClickEvent = new CustomEvent("project-action-event", {
      detail: {
        eventType,
        project,
      },
    });
    this.dispatchEvent(projectActionClickEvent);
  }

  openUrlInNewTab(url) {
    const newTabUrl = `${this.router.root}/#/${url}`;
    window.open(newTabUrl, "_blank");
  }

  render() {
    return html`
      <div class="project-list-item-dropdown">
        <custom-dropdown isArrowHidden="true" label="..." right-aligned>
          <uimc-menu>
            <uimc-menu-item
              @click=${() => {
                this.openUrlInNewTab(
                  `preview-project/${this.project.get("id")}`
                );
              }}
              >Open preview</uimc-menu-item
            >
            ${this.userProjectPermissions
              ? html`
                  <uimc-menu-item
                    @click=${() => {
                      this.router.navigate(
                        `/edit-project/${this.project.get("id")}`
                      );
                    }}
                    >Edit</uimc-menu-item
                  >
                  <uimc-menu-item
                    @click=${() => {
                      this.bubbleProjectActionEvent("privacy", this.project);
                    }}
                    >${this.project.get("privacy") === "public"
                      ? "Set private"
                      : "Set public"}</uimc-menu-item
                  >
                  <uimc-menu-item
                    @click=${() => {
                      this.bubbleProjectActionEvent("copy", this.project);
                    }}
                    >Copy</uimc-menu-item
                  >
                  <uimc-menu-item
                    @click=${() => {
                      this.bubbleProjectActionEvent("archive", this.project);
                    }}
                    >${this.project.get("status") === "published"
                      ? "Archive"
                      : "Dearchive"}</uimc-menu-item
                  >
                  <uimc-menu-item
                    @click=${() => {
                      this.showModal(
                        "Delete project",
                        `You are about to delete the project "${this.project.get(
                          "name"
                        )}". Do you really want to continue?`,
                        "warning",
                        "Delete project",
                        "Cancel",
                        "small",
                        () => {
                          this.bubbleProjectActionEvent("delete", this.project);
                        },
                        () => {}
                      );
                    }}
                    >Delete</uimc-menu-item
                  >
                  <uimc-menu-item
                    @click=${() => {
                      this.router.navigate(
                        `/edit-project/${this.project.get("id")}`
                      );
                    }}
                    >Share</uimc-menu-item
                  >
                `
              : null}
          </uimc-menu>
        </custom-dropdown>
      </div>
    `;
  }
}

customElements.define("project-list-item-dropdown", ProjectListItemDropdown);
