import { LitElement, html, css } from "lit";
import { classMap } from "lit-html/directives/class-map.js";

import shared from "../../../../shared/styles.scss";
import custom from "./exhibit-list.scss";

import Api from "../../../../api/api-helper.js";

import "@shs/ui-marcom/src/js/collection";
import "@shs/ui-marcom/src/js/collection-item";

class ExhibitList extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object },
      activeEditBox: { type: String, attribute: false },
      boothTemplateType: { type: Object, attribute: false },
      selectedPlatform: { type: Object, attribute: false },
      listType: { type: String, attribute: "list-type" },
      selectedExhibit: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  constructor() {
    super();
    this.activeEditBox = "";
    this.boothTemplateType = "";
    this.listType = "normal";
    this.selectedExhibit = {};
    this.centerStage = {
      id: 0,
      position: [1020, 177, -4062],
    }

    this.api = Api.getInstance();
  }

  firstUpdated() {
    this.project.on("updated", () => {
      this.requestUpdate();
    });
  }

  setActiveEditBox(activeEditBox, index) {
    if (index >= 0) {
      this.activeEditBox = `${activeEditBox}_${index}`;
      return;
    }

    this.activeEditBox = activeEditBox;
  }

  setExhibitImage(exhibit) {
    if (exhibit.get("model")?.previewImage) {
      return this.api.getFileSource(
        exhibit.get("model")?.previewImage
      );
    } else {
      const exhibitplace = this.project
      .get("boothTemplate")
      .exhibits.find(aExhibit => aExhibit.name === exhibit.placeName);

      return this.api.getFileSource(exhibitplace.signImage);
    }
  }

  select(exhibit) {
    if(exhibit === 0) {
      this.selectedExhibit = this.centerStage;
    } else {
      this.selectedExhibit = exhibit;
    }
    this.project
      .get("experience")
      .get("camera")
      .setActiveExhibitCameraTarget(this.selectedExhibit);
  }

  render() {
    return html`
      <div class="exhibit-list">
        ${this.listType === "positions"
          ? html`
            <div class="row">
              <div class="col-12">
                <p>
                  Select a target for the camera to focus on. 
                  This target will serve a dual purpose as the central point of rotation for the overall experience. 
                  By designating this target, we ensure that the camera's perspective aligns precisely with the desired focal point, 
                  enhancing the viewer's immersion and guiding their attention throughout the experience.
                </p>
              </div>
            </div>
            <div class="row">
              ${this.project.get("exhibits").map((exhibit, index) => {
                return html`
                  <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <uimc-card
                      class="display-block margin-bottom-1 cursor-pointer"
                      @click=${() => {
                        this.select(exhibit);
                      }}
                    >
                      <uimc-aspect-ratio slot="card-image" formats='{"sm": "4x3"}'>
                        <img
                          src=${this.setExhibitImage(exhibit)}
                          alt=${`${exhibit?.name} preview image`}
                        />
                      </uimc-aspect-ratio>

                      <div
                        class="display-flex align-items-center justify-content-between"
                      >
                        
                        ${exhibit.get("model")?.name
                          ? html`
                            <p class="margin-bottom-0">
                              <strong>${exhibit.get("model")?.name}</strong>
                            </p>
                          `
                          : html`
                            <p class="margin-bottom-0">
                              <strong>${exhibit.get("headline")}</strong>
                            </p>
                          `}
                        <span
                          class="${classMap({
                            invisible: this.selectedExhibit?.id !== exhibit?.id,
                          })}"
                        >
                          <i class="icon-check icon--success"></i>
                        </span>
                      </div>
                    </uimc-card>
                  </div>
                `;
              })}
              ${this.project.boothTemplate.type !== "standalone" && this.project.boothTemplate.type !== "environment"
                ? html`
                  <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <uimc-card
                      class="display-block margin-bottom-1 cursor-pointer"
                      @click=${() => {
                        this.select(0);
                      }}
                    >
                      <uimc-aspect-ratio slot="card-image" formats='{"sm": "4x3"}'>
                        <img
                          src="${this.basePath}assets/images/center-stage.jpg"
                          alt="Center Stage preview image"
                        />
                      </uimc-aspect-ratio>

                      <div class="display-flex align-items-center justify-content-between">
                        <p class="margin-bottom-0">
                          <strong>Center Stage</strong>
                        </p>
                        <span
                          class="${classMap({
                            invisible: this.selectedExhibit?.id !== 0,
                          })}"
                        >
                          <i class="icon-check icon--success"></i>
                        </span>
                      </div>
                    </uimc-card>
                  </div>
                `
                : null}
            </div>
          `
          : html`
            <uimc-collection>
              ${this.project.boothTemplate.type === "platform"
                ? html`
                    ${this.project.get("exhibits").map((exhibit, index) => {
                      if (exhibit.platformName === this.selectedPlatform) {
                        return html`
                          <uimc-collection-item>
                            <exhibit-list-item
                              base-path=${this.basePath}
                              .project=${this.project}
                              .exhibit=${exhibit}
                              index=${index}
                              activeEditBox=${this.activeEditBox}
                              .setActiveEditBox=${this.setActiveEditBox.bind(this)}
                            ></exhibit-list-item>
                          </uimc-collection-item>
                        `;
                      }
                      return null;
                    })}
                  `
                : html`
                    ${this.project.get("exhibits").map((exhibit, index) => {
                      return html`
                        <uimc-collection-item>
                          <exhibit-list-item
                            base-path=${this.basePath}
                            .project=${this.project}
                            .exhibit=${exhibit}
                            index=${index}
                            activeEditBox=${this.activeEditBox}
                            .setActiveEditBox=${this.setActiveEditBox.bind(this)}
                          ></exhibit-list-item>
                        </uimc-collection-item>
                      `;
                    })}
                  `}
            </uimc-collection>
          `}
      </div>
    `;
  }
}

customElements.define("exhibit-list", ExhibitList);
