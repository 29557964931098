import { LitElement, html, css } from "lit";

import shared from "../../shared/styles.scss";
import custom from "./edit-box.scss";

import "@shs/ui-marcom/src/js/button";

class EditBox extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      description: { type: String, attribute: true },
      onSubmit: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();

    this.description = "";
    this.onSubmit = () => {};
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  render() {
    return html`
      <div class="edit-box bg-color-black-7 padding-1">
        <div class="display-flex margin-bottom-1">
          <span class="margin-right-1">${this.description}</span>
          <uimc-button
            class="margin-left-auto"
            primary
            type="control"
            icon="check"
            @click=${() => this.onSubmit()}
          >
            Submit
          </uimc-button>
        </div>
        <slot></slot>
      </div>
    `;
  }
}

customElements.define("edit-box", EditBox);
