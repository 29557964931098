import { html, unsafeCSS } from "lit";

import { classMap } from "lit/directives/class-map";
import Button from "@shs/ui-marcom/src/js/button";

import custom from "./custom-button.scss";

export default class CustomButton extends Button {
  static get properties() {
    return {
      ...super.properties,
      isDisabled: {
        type: Boolean,
        attribute: "disabled",
        reflect: true,
      },
    };
  }

  static get styles() {
    return [super.styles, unsafeCSS(custom)];
  }

  render() {
    return html`
      <button
        class=${classMap({
          button: true,
          "button--primary": this.isPrimary,
          "button--control": this.type === "control",
          "button--link": this.type === "link",
          "button--small": this.size === "small",
          [`icon-${this.icon}`]: this.icon,
        })}
        ?disabled=${this.isDisabled}
        @click=${event => this.onClick(event)}
      >
        <slot></slot>
      </button>
    `;
  }
}

window.customElements.define("custom-button", CustomButton);
