import { LitElement, html, css } from "lit";
import { ConsumerMixin } from "lit-element-context";

import Project from "../../experience/Models/Project";

import shared from "../../shared/styles.scss";
import custom from "./preview-project.scss";

class PreviewProject extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      project: { type: Object, attribute: false },
      projectId: { attribute: "project-id", type: Number },
    };
  }

  constructor() {
    super();

    this.project = null;
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showModal", "showAlert", "setLoading"];
  }

  async firstUpdated() {
    this.setLoading(true);

    this.project = await Project.fetch(this.projectId);

    this.project.on("updated", () => {
      this.requestUpdate();
    });

    this.setLoading(false);
  }

  render() {
    return html`
      <div class="preview-project">
        ${this.project
          ? html`
              <experience-container
                base-path="${this.basePath}"
                .project=${this.project}
              ></experience-container>
            `
          : null}
      </div>
    `;
  }
}

customElements.define("preview-project", PreviewProject);
