import { html } from "lit";
import { classMap } from "lit/directives/class-map";
import ToggleSwitch from "@shs/ui-marcom/src/js/toggle-switch";

export default class CustomToggleSwitch extends ToggleSwitch {
  static get properties() {
    return {
      ...super.properties,
      isDisabled: {
        type: Boolean,
        attribute: "disabled",
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.isDisabled = false;
  }

  render() {
    return html`
      <div
        class=${classMap({
          "toggle-switch": true,
          "toggle-switch--large": this.size === "large",
        })}
      >
        <input
          id=${this.id}
          type="checkbox"
          class="toggle-switch__checkbox"
          ?disabled=${this.isDisabled}
          ?checked=${this.isChecked}
        />
        <label for=${this.id} class="toggle-switch__label">
          <slot></slot>
        </label>
      </div>
    `;
  }
}

window.customElements.define("custom-toggle-switch", CustomToggleSwitch);
