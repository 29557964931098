import { LitElement, html, css } from "lit-element";
import { ConsumerMixin } from "lit-element-context";

import shared from "../../../../shared/styles.scss";
import custom from "./platform-list-item.scss";

import Api from "../../../../api/api-helper";

import "@shs/ui-marcom/src/js/button";
import "@shs/ui-marcom/src/js/toggle-switch";

class PlatformListItem extends ConsumerMixin(LitElement) {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      project: { type: Object, attribute: false },
      platform: { type: Object, attribute: false },
      activeTab: { type: Object, attribute: false },
      onTogglePlatform: { type: Object },
    };
  }

  constructor() {
    super();
    this.platform = "";
    this.activeTab = null;

    this.api = Api.getInstance();
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  static get inject() {
    return ["showAlert", "setLoading"];
  }

  handleOpenPlatformDetailClick() {
    if (this.platform.platformData.isActive) {
      this.platform.zoomToPlatform();
    }
  }

  get platforms() {
    return this.project
      .get("experience")
      .get("exhibition")
      .get("floor").platforms;
  }

  get centralExhibits() {
    return this.project.exhibits.filter(exhibit =>
      exhibit.name.includes("central")
    );
  }

  async togglePlatformActivation(toggleStatus) {
    this.setLoading(true);
    this.platform.platformData.isActive = !toggleStatus;
    this.platform.setPlatformVisibility(!toggleStatus);

    this.project
      .get("experience")
      .get("exhibition")
      .updatePlatformExhibits(this.platform);

    // save new control target
    const controlsResponses = await this.api.updateControlById(
      this.project.controls.id,
      {
        targetx: this.project.experience.camera.controls.target.x,
      }
    );

    const platformResponses = await Promise.all(
      this.platforms.map(async platform => {
        return this.api.updatePlatformById(platform.platformData.id, {
          isactive: platform.platformData.isActive,
          positionx: platform.platformData.position[0],
          positionz: platform.platformData.position[2],
        });
      })
    );

    const exhibitResponses = await Promise.all(
      this.centralExhibits.map(async exhibit => {
        return this.api.updateExhibitById(exhibit.id, {
          positionx: exhibit.position[0],
          positionz: exhibit.position[2],
        });
      })
    );

    this.setLoading(false);
    const status = new Set([
      ...platformResponses.map(p => p.status),
      ...exhibitResponses.map(e => e.status),
    ]);

    if (status.has(200) && status.size === 1) {
      this.showAlert("Saved", `Platform position saved.`, "success");
    } else {
      this.showAlert("Saving failed", `Saving platforms failed.`, "error");
    }

    this.requestUpdate();
  }

  enterHoverPlatform() {
    this.platform.setPlatformActive();
  }

  leaveHoverPlatform() {
    this.platform.setPlatformUnActive();
  }

  render() {
    return html`
      <uimc-collection-item
        @mouseenter=${() => {
          this.enterHoverPlatform();
        }}
        @mouseleave=${() => this.leaveHoverPlatform()}
      >
        <div class="platform-list-item">
          <div class="platform-list-item__name">
            ${this.platform.platformData.hotspot.labelValue}
          </div>
          ${this.activeTab.label === "Hotspots"
            ? null
            : html`
                <div class="platform-list-item__toggle padding-right-1">
                  <uimc-toggle-switch
                    ?checked=${this.platform.platformData.isActive}
                    .onChange=${() => {
                      // SET PLATFORM DISPLAY STATUS HERE
                      this.togglePlatformActivation(
                        this.platform.platformData.isActive
                      );
                    }}
                  >
                    Activate platform
                  </uimc-toggle-switch>
                </div>
              `}
        </div>
        <div slot="additional-content">
          <custom-button
            type="control"
            icon="arrow-right"
            ?disabled=${!this.platform.platformData.isActive}
            @click=${() => {
              this.handleOpenPlatformDetailClick();
            }}
          ></custom-button>
        </div>
      </uimc-collection-item>
    `;
  }
}

customElements.define("platform-list-item", PlatformListItem);
