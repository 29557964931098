import { getCookie } from "../services/cookie";

function getXYZArray(parentObject, fieldName) {
  return [
    parentObject[`${fieldName}x`],
    parentObject[`${fieldName}y`],
    parentObject[`${fieldName}z`],
  ];
}

function handleInvalidRequest(router, showAlert) {
  if (!router) {
    return;
  }

  const currentRoute = router.lastRouteResolved().url;
  if (currentRoute.includes("user-login")) {
    return;
  }

  if (showAlert) {
    showAlert(
      "Not authenticated",
      `You are not authorized or have been logged out automatically. Please log in again.`,
      "error"
    );
  }

  router.navigate("user-login");
}

function hasAuthToken() {
  return getCookie("token") !== null;
}

export { getXYZArray, hasAuthToken, handleInvalidRequest };
