import { html } from "lit";

import RadioButton from "@shs/ui-marcom/src/js/radio-button";

class CustomRadioButton extends RadioButton {
  _onCheck() {
    this.isChecked = true;
    super._onCheck();
  }

  render() {
    // have to set .checked as property -> https://github.com/Polymer/lit-html/issues/732
    return html`
      <input
        id=${this.id}
        name=${this.name}
        value=${this.value}
        type="radio"
        class="radio"
        .checked=${this.isChecked}
        ?disabled=${this.isDisabled}
        @click=${() => {
          this._onCheck();
        }}
      />
      <label for=${this.id}>
        ${this.label}
      </label>
    `;
  }
}

customElements.define("custom-radio-button", CustomRadioButton);
