import { LitElement, html, css } from "lit";

import "../../custom-uimc/custom-dropdown/custom-dropdown";
import "@shs/ui-marcom/src/js/menu";
import "@shs/ui-marcom/src/js/menu-item";

import shared from "../../shared/styles.scss";
import custom from "./project-list-sort.scss";

class ProjectListSort extends LitElement {
  static get properties() {
    return {
      basePath: { attribute: "base-path" },
      router: { type: Object, attribute: false },
      sortType: { type: Object, attribute: false },
    };
  }

  constructor() {
    super();
    this.sortType = "last-changed";
  }

  static get styles() {
    return [css(shared), css(custom)];
  }

  bubbleSortTypeEvent(sortType) {
    const sortTypeEvent = new CustomEvent("sort-type-event", {
      detail: sortType,
    });
    this.dispatchEvent(sortTypeEvent);
  }

  render() {
    return html`
      <custom-dropdown label="Sort by" right-aligned>
        <uimc-menu>
          <uimc-menu-item
            @click=${() => {
              this.bubbleSortTypeEvent("last-changed");
            }}
            ?selected="${this.sortType === "last-changed"}"
            >Last changed</uimc-menu-item
          >
          <uimc-menu-item
            @click=${() => {
              this.bubbleSortTypeEvent("project-title");
            }}
            ?selected="${this.sortType === "project-title"}"
            >Project title</uimc-menu-item
          >
          <uimc-menu-item
            @click=${() => {
              this.bubbleSortTypeEvent("project-owner");
            }}
            ?selected="${this.sortType === "project-owner"}"
            >Project owner</uimc-menu-item
          >
          <uimc-menu-item
            @click=${() => {
              this.bubbleSortTypeEvent("business-line");
            }}
            ?selected="${this.sortType === "business-line"}"
            >Business line</uimc-menu-item
          >
          <uimc-menu-item
            @click=${() => {
              this.bubbleSortTypeEvent("booth-template");
            }}
            ?selected="${this.sortType === "booth-template"}"
            >Exhibition template</uimc-menu-item
          >
        </uimc-menu>
      </custom-dropdown>
    `;
  }
}

customElements.define("project-list-sort", ProjectListSort);
